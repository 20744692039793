import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, checkDecimal, COMMERCIAL_AND_INDUSTRIAL_OPTIONS, CONNECTION_TYPE_OPTIONS, ENUM_CUSTOMER_TYPE, EXTRACT_NUMBERS_REGEX, FormField, NUMBER_REGEX, optionTypes, RESIDENTIAL_AND_SOCIETY_OPTIONS } from 'src/helpers'
import { getProjectCalculationApiRequest } from 'src/services/requests/Projects'
import { IProjectCalculationResponse } from 'src/services/requests/Projects/module'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { Attachments, ContactDetails, createNewProjectvalidationSchema, initialAttachments, initialContactDetails, initialLocationDetails, initialPowerConsumptionData, initialProjectData, initialProjectFinanceData, initialSiteData, LocationDetails, PowerConsumptionData, PROJECT_FORM_FIELDS, PROJECT_FORM_INITIAL_VALUES, ProjectData, ProjectFinanceData, SiteData } from './modules'
import { toast } from 'src/shadcn/components/ui/use-toast'


type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
}

const ProjectForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm }: Props) => {


    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingProjectCalculation, setisLoadingProjectCalculation] = useState(false)
    const [calculateResponse, setcalculateResponse] = useState<any>({})


    const formik = useFormik<ProjectData & SiteData & PowerConsumptionData & ContactDetails & LocationDetails & ProjectFinanceData & Attachments>({
        initialValues: { ...initial_values, agreement: null },
        validationSchema: createNewProjectvalidationSchema,
        onSubmit(values, formikHelpers) {
            // handleSubmit()
            handleSubmit()
        },
    })

    useEffect(() => {
        if (initial_values) {
            Object.keys(PROJECT_FORM_INITIAL_VALUES).map((key: any) => {

                if (key == "unitRequiredPerMonth") {
                    let monthlyUnits = (Number(initial_values["annualConsumption"]) / 12).toFixed(0)
                    monthlyUnits = checkDecimal(monthlyUnits).toString()
                    formik.setFieldValue(key, monthlyUnits || 0)
                    let dailyConsumption = (parseFloat(monthlyUnits) / 30).toFixed(0)
                    formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
                    // handleProjectCalculation(monthlyUnits)
                }
                else if (key == "termPlan" && initial_values[key]) {
                    if (initial_values[key as any]) {
                        let termPlan = initial_values[key as any].replace(NUMBER_REGEX, "")
                        if (termPlan > 10) {
                            formik.setFieldValue(key, Number(termPlan) / 12)
                        }
                        else initial_values[key] && formik.setFieldValue(key, (initial_values[key])?.toString().replace(NUMBER_REGEX, "") || null)
                    }
                }
                else if (key == "connectionType" && initial_values[key]) {
                    formik.setFieldValue(key, initial_values[key] ? CONNECTION_TYPE_OPTIONS.find((d: optionTypes) => d.value.toLowerCase() == initial_values[key]?.toLowerCase())?.value : null)
                }
                else formik.setFieldValue(key, Object.keys(initial_values).includes(key) ? initial_values[key] : 0 || null)

                // if (key == "termPlan") {
                //     initial_values[key] && formik.setFieldValue(key, (initial_values[key])?.toString()?.replace(NUMBER_REGEX, "") || null)
                // }
                // else formik.setFieldValue(key, initial_values[key] || null)

            })
            // formik.setValues(initial_values)
        }
        return () => { }
    }, [initial_values])

    // useEffect(() => {
    //     if (formik.errors) {
    //          let errors =  Object.entries(formik.touched).map(([key,value]) =>  formik.errors[key as keyof object] )
    //         if( errors && errors.length > 0) {
    //             toast({
    //                 variant: "destructive",
    //                 title: "Kindly Fill Required Information",
    //                 // description: Object.entries(formik.touched).map(([key,value]) =>  formik.errors[key as keyof object] ).join(", ")
    //             })
    //         }  
    //     }

    //     return () => { }
    // }, [formik.errors])



    const handleSubmit = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            formik.setValues(result[0])
            onSubmit(result[0])
        }
        setisLoading(false)

    })

    const handleProjectCalculation = catchAsync(async (unitRequiredPerMonth: string) => {
        setisLoadingProjectCalculation(true)
        return await getProjectCalculationApiRequest({ unitRequiredPerMonth })
    }, (result: IProjectCalculationResponse) => {
        console.log({ result });

        if (result && result?.data) {
            console.log("here");

            result && result?.data && Object.entries(result?.data).map(([key, value]) => {
                if (key == "termPlan") {
                    value = value && parseInt(value?.toString()) / 12
                    console.log({ key, value });
                    formik.setFieldValue(key, value)

                }
                else formik.setFieldValue(key, value)
            })

            // const dailyUnits = formik.values.unitRequiredPerMonth ? parseFloat(formik.values.unitRequiredPerMonth) / 30 : 0
            // console.log({dailyUnits});

            // formik.setFieldValue("unitConsumptionPerDay", dailyUnits)
            formik.setFieldValue("tokenValidity", result.data.termPlan)
            formik.setFieldValue("totalProjectCost", result.data.ProjectTotalprice)
            formik.setFieldValue("totalSupply", result.data.totalSupply)
            formik.setFieldValue("returnPerYearRate", result.data.intrestRate.replace(/[^0-9.]+/g, ""))
            formik.setFieldValue("interestRate", result.data.intrestRate.replace(/[^0-9.]+/g, ""))
            formik.setFieldValue("requiredInvestment", result.data.ProjectTotalprice)
            formik.setFieldValue("returnPerYearAmount", result.data.interestAmount)
            formik.setFieldValue("pricePerKwh", result.data.pricePerKWP)

            formik.setFieldValue("country", "India")
            setcalculateResponse(result.data)


        }
        setisLoadingProjectCalculation(false)
    }, () => {
        setisLoadingProjectCalculation(false)
    })



    const handleFormSubmit = () => {
        if (formik.isValid) {
            formik.handleSubmit()
        }
        else {
            Object.entries(formik.errors).map(([key, value]) => {
                formik.setFieldError(key, value)
                formik.setFieldTouched(key, true)
            })
            toast({
                variant: "destructive",
                title: "Form Validation Failed",
                description: "Please enter valid data"
            })
        }
    }


    console.log({ error: formik.errors, values: formik.values, touched: formik.touched });

    // useEffect(() => {
    //     formik.values.downpaymentByOfftaker && formik.setFieldValue("requiredInvestment", (Number(formik.values.totalProjectCost) - Number(formik.values.downpaymentByOfftaker) || 0))
    //     return () => { }
    // }, [formik.values.downpaymentByOfftaker])



    return (
        <div>
            <form onSubmit={(e: any) => {
                e.preventDefault()
                handleFormSubmit()
            }}>
                <ScrollArea className='h-[80vh] px-4 bg-gray-50'>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Power Consumption Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-4 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialPowerConsumptionData).map((k: any) => {
                                let formfield: any = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined

                                if (k == "projectCategoryType") {
                                    if (formfield && formik.values.customerType && formik.values.customerType == ENUM_CUSTOMER_TYPE.COMMERCIAL_AND_INDUSTRIA) {
                                        formfield = { ...formfield, componentProps: { ...formfield?.componentProps, options: COMMERCIAL_AND_INDUSTRIAL_OPTIONS } }
                                    }
                                    else if (formfield && formik.values.customerType && formik.values.customerType == ENUM_CUSTOMER_TYPE.RESIDENTIAL_AND_SOCIETY) {
                                        formfield = { ...formfield, componentProps: { ...formfield?.componentProps, options: RESIDENTIAL_AND_SOCIETY_OPTIONS } }
                                    }
                                }

                                if (k == "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                else if (formfield?.dataKey == "unitRequiredPerMonth") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)}
                                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                            // console.log({ unitRequiredPerMonth: e.target.value });
                                            // let dailyConsumption =( Number(e.target.value.replace(NUMBER_REGEX,"")) / 30).toFixed(2)
                                            // formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)

                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e && e.key === 'Enter') {
                                                if (e.target.value) {
                                                    // let monthlyConsumption = (Number(e.target.value.replace(NUMBER_REGEX, "")) / 12).toFixed(2)
                                                    // console.log({ monthlyConsumption });
                                                    // formik.setFieldValue("unitRequiredPerMonth", monthlyConsumption)
                                                    // let dailyConsumption =( parseFloat(monthlyConsumption) / 30).toFixed(2)
                                                    // formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
                                                    // e.target.value && handleProjectCalculation(monthlyConsumption.replace(NUMBER_REGEX, ""))
                                                }
                                            }
                                        }}
                                    />
                                }
                                else if (formfield?.dataKey == "annualConsumption") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)}
                                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                            console.log({ annualConsumption: e.target.value });
                                            if (e.target.value) {
                                                let monthlyConsumption = (Number(e.target.value.replace(NUMBER_REGEX, "")) / 12).toFixed(0)
                                                console.log({ monthlyConsumption });
                                                formik.setFieldValue("unitRequiredPerMonth", monthlyConsumption)
                                                e.target.value && handleProjectCalculation(monthlyConsumption.replace(NUMBER_REGEX, ""))
                                                let dailyConsumption = (parseFloat(monthlyConsumption) / 30).toFixed(0)
                                                formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)

                                            }
                                        }}
                                    />
                                }

                                else return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                    onChange={(e: any) => {
                                        if (formfield.dataKey == "pricePerKwh") {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            let pricePerKwh = e || 0
                                            let projectCost = Number((pricePerKwh).toString().replace(NUMBER_REGEX, "") || 0) * (calculateResponse?.projectSize || 0)
                                            formik.setFieldValue("projectCost", projectCost)
                                        }

                                        else if (k == "customerType") {
                                            formik.setFieldValue("projectCategoryType","")
                                            formik.setFieldValue(formfield.dataKey, e)
                                        }

                                        else formik.setFieldValue(formfield.dataKey, e)

                                    }} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialProjectData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Contact Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialContactDetails).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Location Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialLocationDetails).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Documents</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialAttachments).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                if (k == "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} label={"Upload" + formfield.label} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} label={"Upload " + formfield.label} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Site Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialSiteData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                if (k == "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>

                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Finance Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialProjectFinanceData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                if (formfield?.dataKey == "downpaymentByOfftaker") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            // aconsole.log({ e });
                                            console.log({ e, projectCost: formik.values.totalProjectCost });
                                            if (e) {
                                                if (formik.values.totalProjectCost) {
                                                    let calculateOwnerShipPercentage = parseFloat(((e / parseFloat(formik.values.totalProjectCost)) * 100).toFixed(2))
                                                    calculateOwnerShipPercentage = Math.min(Math.max(calculateOwnerShipPercentage || 0, 0), 100)

                                                    let calculateRequiredInvestments = Number(formik.values.totalProjectCost) - Number(e)
                                                    calculateRequiredInvestments = Math.max(calculateRequiredInvestments || 0, 0)
                                                    console.log({ calculateOwnerShipPercentage, calculateRequiredInvestments });

                                                    formik.setFieldValue("offtakerOwnershipPercentage", calculateOwnerShipPercentage || 0)
                                                    formik.setFieldValue("requiredInvestment", calculateRequiredInvestments || 0)
                                                }
                                            }
                                            else {
                                                formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                                formik.setFieldValue("requiredInvestment", calculateResponse?.ProjectTotalprice)
                                            }
                                            // formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                        }}
                                    />
                                }
                                else if (formfield?.dataKey == "termPlan") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            formik.setFieldValue("payBack", Number(e) * 12)
                                            formik.setFieldValue("tokenValidity", Number(e) * 12)
                                        }}
                                    />
                                }

                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => {
                                    if (formfield.dataKey == "projectCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = e ? Number(e.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("projectCost", projectCost)
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }

                                    else if (formfield.dataKey == "electicalCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)

                                    }
                                    else if (formfield.dataKey == "structureCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }
                                    else if (formfield.dataKey == "otherCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }
                                    else formik.setFieldValue(formfield.dataKey, e)
                                }} />
                            })
                        }
                    </div>

                </ScrollArea>
                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='submit' disabled={isLoading || isLoadingForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isLoadingProjectCalculation} message='Calculating project cost' />
            <LoadingDialog isOpen={isLoading} message='Uploading Documents' />
        </div>
    )
}

export default ProjectForm