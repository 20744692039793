import { Plus } from 'lucide-react'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { columns } from 'src/modules/ManufacturerProducts/AllManufacturerPropducts/Table/components/columns'
import { DataTable } from 'src/modules/ManufacturerProducts/AllManufacturerPropducts/Table/components/data-table'
import { MANUFACTURER_ROUTES } from 'src/navigations/modules'

import { Button } from 'src/shadcn/components/ui'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'

type Props = {}



const Main = () => {

    const navigation = useNavigate()
    const isLoading = useQueryResponseLoading()
    const apiResp = useQueryResponseData()

    const tableData = useMemo(() => {
        return apiResp && Array.isArray(apiResp) && apiResp.map((d: any, indx: Number) => {
            return {
                ...d,
                srNo: Number(indx) + 1
            }
        })
    }, [apiResp])

    return (
        <div className='p-4 space-y-4'>
            <div className=' flex justify-end'>
                <Button onClick={() => navigation("/" + MANUFACTURER_ROUTES.products.subRoutes.create.fullRoute)} className='space-x-1'><Plus size={16} /><span>Create </span></Button>
            </div>
            <DataTable data={tableData || []} columns={columns} isLoading={isLoading} />
        </div>
    )
}

const ManufacturerAllProducts = (props: Props) => {

    return <QueryRequestProvider>
        <QueryResponseProvider>
            <Main />
        </QueryResponseProvider>
    </QueryRequestProvider>

}

export default ManufacturerAllProducts