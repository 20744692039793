import { ADMIN_BASE_URL, BASE_URL } from "../api_const"

export const PROJECTS_BASE_URL = ADMIN_BASE_URL + "/project"

export const CREATE_PROJECT = PROJECTS_BASE_URL + "/create"
export const GET_PROJECTS = PROJECTS_BASE_URL + "/all"
export const GET_PROJECT_DETAILS = PROJECTS_BASE_URL 
export const UPDATE_PROJECT_DETAILS = PROJECTS_BASE_URL + "/update"

export const GET_PROJECT_CALCULATIONS = PROJECTS_BASE_URL + "/calculation"
export const APPROVE_PROJECT_EVALUATOR = PROJECTS_BASE_URL + "/evaluate"
export const APPROVE_PROJECT_ADMIN = PROJECTS_BASE_URL + "/update/status"
export const APPROVE_PROJECT_STAKEHOLDER = PROJECTS_BASE_URL + "/voteOnProject"
export const GENERATE_AGREEMENT = BASE_URL + "/generate-pdf"
