import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
import { Badge, Button } from "src/shadcn/components/ui";
import Actions from "./Actions";


export const dashboard_columns: ColumnDef<Task>[] = [

  {
    accessorKey: "project_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project ID (#)" />
    ),
  }, {
    accessorKey: "projectName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Name" />
    ),
  },
  {
    accessorKey: "projectSize",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Size" />
    ),
  },

  {
    accessorKey: "systemType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="System Type" />
    ),
  },
  {
    accessorKey: "interestRate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="IRR (%)" />
    ),
  },

  {
    accessorKey: "termPlan",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Term Plan (Yrs)" />
    ),
  },
  {
    accessorKey: "requiredInvestment",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment (₹)" />
    ),
  },

];
