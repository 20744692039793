// Step 1: Define the interface
import { ADDRESS_REGEX, AVAILABLE_SPACE_UNITS_OPTIONS, CONNECTION_TYPE_OPTIONS, EMAIL_REGEX, FormField, NAME_REGEX, NUMBER_REGEX, optionTypes, PROPERTY_TYPE_OPTIONS, ROOF_MATERIAL_OPTIONS, ROOF_ORIENTATION, ROOF_TYPE_OPTIONS, ROOFTOP_MATERIAL_OPTIONS, ROOFTOP_PROPERTY_TYPE_OPTIONS, SHADING_OPTIONS, SOLAR_SYSTEM_TYPE_OPTIONS } from 'src/helpers';
import * as Yup from 'yup';

export interface IRooftop {
  firstName: string;
  email: string;
  phone: string;
  alternatePhone?: string; // Optional field
  address: string;
  city: string;
  state: string;
  pinCode: string;
  propertyType: string;
  roofType: string;
  roofMaterial: string;
  availableRoofArea: string; // in sq.ft.
  roofOrientation: string;
  shadingOnRoof: string;
  connectionType: string;
  averageLoad: string; // in kW
  solarSystemType: string;
  imgUrl: string
}

// Step 2: Initialize a constant
export const rooftop_init: IRooftop = {
  firstName: "",
  email: "",
  phone: "",
  alternatePhone: "",
  address: "",
  city: "",
  state: "",
  pinCode: "",
  propertyType: "",
  roofType: "",
  roofMaterial: "",
  availableRoofArea: "",
  roofOrientation: "",
  shadingOnRoof: "",
  connectionType: "",
  averageLoad: "",
  solarSystemType: "",
  imgUrl: ""
};

export const rooftopFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Full name is required'),
  email: Yup.string().email('Invalid email format').required('Email address is required'),
  phone: Yup.string().trim().required('Phone number is required'),
  alternatePhone: Yup.string().trim().optional(),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pinCode: Yup.string().trim().required('Pin code is required'),
  propertyType: Yup.string().required('Property type is required'),
  roofType: Yup.string().required('Roof type is required'),
  roofMaterial: Yup.string().required('Roof material is required'),
  availableRoofArea: Yup.string().test('availableRoofAreaUnits', 'Select Units (e.g. sq.ft, ft, m)',
    (value: any) => {
      if (value) {
        const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
        return units.some((u: any) => value.includes(u))
      }
    }).required('Available roof area is required'),
  roofOrientation: Yup.string().required('Roof orientation is required'),
  shadingOnRoof: Yup.string().required('Shading on roof is required'),
  connectionType: Yup.string().required('Connection type is required'),
  averageLoad: Yup.string().required('Average load is required'),
  solarSystemType: Yup.string().required('Solar system type is required'),
});

export const ROOFTOP_FORM_FIELDS: FormField[] = [
  {
    label: 'Full Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Full Name',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Email Address',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email Address',
      maxLength: 150,
      required: true,
      regex: EMAIL_REGEX, // Assuming EMAIL_REGEX is defined elsewhere
    },
  },
  {
    label: 'Phone Number',
    dataKey: 'phone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Phone Number',
      maxLength: 15,
      required: true,
      regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
    },
  },
  {
    label: 'Alternate Phone Number',
    dataKey: 'alternatePhone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Alternate Phone Number',
      maxLength: 15,
      required: false,
      regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
    },
  },
  {
    label: 'Address',
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Address',
      maxLength: 250,
      required: true,
      regex: ADDRESS_REGEX, // Assuming ADDRESS_REGEX is defined elsewhere
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'City',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX, // Assuming NAME_REGEX is defined elsewhere
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'State',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX, // Assuming NAME_REGEX is defined elsewhere
    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Pin Code',
      maxLength: 6,
      required: true,
      regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
    },
  },
  {
    label: 'Property Type',
    dataKey: 'propertyType',
    componentType: 'options',
    componentProps: {
      options: ROOFTOP_PROPERTY_TYPE_OPTIONS, // Assuming PROPERTY_TYPE_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Roof Type',
    dataKey: 'roofType',
    componentType: 'options',
    componentProps: {
      options: ROOF_TYPE_OPTIONS, // Assuming ROOF_TYPE_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Roof Material',
    dataKey: 'roofMaterial',
    componentType: 'options',
    componentProps: {
      options: ROOFTOP_MATERIAL_OPTIONS, // Assuming ROOF_MATERIAL_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Available Roof Area (sq.ft.)',
    dataKey: 'availableRoofArea',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Available Roof Area',
      numberFormatted: true,
      options: AVAILABLE_SPACE_UNITS_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Roof Orientation',
    dataKey: 'roofOrientation',
    componentType: 'options',
    componentProps: {
      options: ROOF_ORIENTATION, // Assuming ROOF_MATERIAL_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Shading on Roof',
    dataKey: 'shadingOnRoof',
    componentType: 'options',
    componentProps: {
      options: SHADING_OPTIONS, // Assuming ROOF_MATERIAL_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
      options: CONNECTION_TYPE_OPTIONS, // Assuming CONNECTION_TYPE_OPTIONS is defined elsewhere
      required: true,
    },
  },
  {
    label: 'Average Load (kW)',
    dataKey: 'averageLoad',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Average Load (kW)',
      required: true,
    },
  },
  {
    label: 'Solar System Type',
    dataKey: 'solarSystemType',
    componentType: 'options',
    componentProps: {
      options: SOLAR_SYSTEM_TYPE_OPTIONS, // Assuming SOLAR_SYSTEM_TYPE_OPTIONS is defined elsewhere
      required: true,
    },
  },
];