import { IndianRupee } from "lucide-react";
import { FormField, NUMBER_REGEX } from "src/helpers";
import { IINVESTORS_DATA } from "src/services/requests/investors/modules";
import * as yup from "yup"



export const INVESTOR_FORM_FIELDS: FormField[] = [
  {
    label: 'First Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'First Name',
      maxLength: 150,
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Last Name',
      maxLength: 150,
    },
  },
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email',
      maxLength: 150,
    },
  },

  {
    label: 'Role',
    dataKey: 'role',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Role',
      maxLength: 150,
    },
  },
  
  {
    label: 'About Me',
    dataKey: 'aboutMe',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'About Me',
    },
  },
  {
    label: 'Mobile Number',
    dataKey: 'mobileNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: NUMBER_REGEX,
      placeholder: 'Mobile Number',
      maxLength: 10,
    },
  },
];

export const INIT_investor: IINVESTORS_DATA = {
  _id: "",
  email: "",
  password: "",
  role: "",
  firstName: "",
  lastName: "",
  abouteMe: "",
  mobileNumber: "",
};


export const investorValidationSchema = yup.object().shape({
  investorName: yup.string().required("Investor name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .length(10)
    .required("Phone number is required"),
  totalInvestment: yup.string().required("Total investment is required"),
  countOfProjects: yup.string().required("Count of projects is required"),
});