
import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/EPC/sales_representative";
import { createEPCContractorsRequestBody } from "./modules";


export function createEpcSalesRepresentativeApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.post(API.URL_CREATE_SALES_REPRESENTATIVE, formData).then((d: AxiosResponse) => d.data);
}


export function allEpcSalesRepresentativeByIDApiRequest(): Promise<any> {
    return axiosInstance.get(`${API.URL_ALL_SALES_REPRESENTATIVE}`).then((d: AxiosResponse) => d.data);
}


export function ViewEpcSalesRepresentativeByIDApiRequest(id: string): Promise<any> {
    return axiosInstance.get(`${API.URL_SALES_REPRESENTATIVE_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}


export function updateEpcSalesRepresentativeByIDApiRequest(id: string, formData: any): Promise<any> {
    return axiosInstance.put(`${API.URL_UPDATE_SALES_REPRESENTATIVE_BY_ID}/${id}`, { ...formData }).then((d: AxiosResponse) => d.data);
}
