import React, { useEffect, useState } from 'react'
import { PRODUCTS_ANALYSIS_DATA } from './modules'
import { catchAsync, IS_SUCCESS_RESPONSE, ROLES, stringifyObjectToRequestQuery } from 'src/helpers'
import { getEvaluatorDashboardSummaryRequestApi } from 'src/services/requests/Dashboard'
import AnalysisTiles from '../Dashboard/AnalysisTiles'
import { useAuth } from '../Auth/Core'
import { allProductsRequest, getProductSummaryApiRequest } from 'src/services/requests/Products'
import ProductAnalysis from './ProductAnalysis'

type Props = {}

const ManufacturerDashboard = (props: Props) => {
    const { currentUser } = useAuth()
    const [data, setdata] = useState<any>(PRODUCTS_ANALYSIS_DATA)
    const [analysisData, setAnalysisData] = useState<any>({})

    useEffect(() => {
        handleFetchSummaryDetails()
        return () => { }
    }, [])

    const handleFetchSummaryDetails = catchAsync(async () => {
        return await allProductsRequest("")
    }, (result: any) => {

        if (IS_SUCCESS_RESPONSE(result)) {
            if (result?.data && Array.isArray(result.data) && result.data?.length > 0) {
                let products = result?.data?.map((d: any) => ({
                    "_id": d?._id,
                    "category": d?.category,
                    "productType": d?.productType,
                    "current_quantity": d?.current_quantity,
                }))

                const categoryCount = products.reduce((acc: any, product: any) => {
                    acc[product.category] = (acc[product.category] || 0) + 1;
                    return acc;
                }, {});

                const lowStockProducts = products.filter((product: any) => parseInt(product.current_quantity, 5) < 5);

                const productTypeCount = products.reduce((acc: any, product: any) => {
                    acc[product.productType] = (acc[product.productType] || 0) + 1;
                    return acc;
                }, {});

                let prepareResult: any = {
                    totalProducts: products?.length,
                    uniqueProductTypes: Object.keys(productTypeCount).length,
                    productTypeCounts: productTypeCount,
                    uniqueCategories: Object.keys(categoryCount).length,
                    categoryCounts: categoryCount,
                    lowStockProducts: lowStockProducts?.length,
                }
                setAnalysisData(prepareResult)
                setdata(data.map((d: any) => ({ ...d, value: prepareResult[d?.dataKey] })))
            }
        }
    }, () => { })

    return (
        <div className="grid gap-4 md:grid-cols-4 lg:grid-cols-4">
            {
                data.map((d: any) => {
                    return <AnalysisTiles {...d} />
                })
            }
        </div>
    )
}

export default ManufacturerDashboard