import React from 'react'

type Props = {}

const LandOnboadingCreatePage = (props: Props) => {
  return (
    <div>LandOnboadingCreatePage</div>
  )
}

export default LandOnboadingCreatePage