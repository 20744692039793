import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/SellExistingProjects";


export function createSellExitingProjectsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`${API.SELL_EXITING_PROJECTS_BASE_URL}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function allSellExitingProjectsRequestsApiRequest(query:string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_SELL_EXITING_PROJECTS_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export function updateSellExitingProjectsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.UPDATE_SELL_EXITING_PROJECTS_REQUEST}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function getSellExitingProjectsDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.SELL_EXITING_PROJECTS_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


