import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import ProjectRightsReviewForm from 'src/modules/Services/ProjectRights/ProjectRightsReviewForm'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import RooftopReviewForm from 'src/modules/Services/Rooftop/RooftopReviewForm'
import { INIT_SELL_EXISTING_PROJECT } from 'src/modules/Services/SellExistingProjects/modules'
import SellExistingProjectForm from 'src/modules/Services/SellExistingProjects/SellExistingProjectForm'
import SellExistingProjectReviewForm from 'src/modules/Services/SellExistingProjects/SellExistingProjectReviewForm'
import { ApiResponse } from 'src/services/consts/api_const'
import { getLandRegistrationDetailApiRequest } from 'src/services/requests/LandRegistration'
import { getRooftopDetailByIDApiRequest } from 'src/services/requests/Rooftop'
import { getSellExitingProjectsDetailApiRequest } from 'src/services/requests/SellExistingProjects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const SellExistingProjectsViewPage = (props: Props) => {
  const [formData, setformData] = useState<any>(INIT_SELL_EXISTING_PROJECT)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchDetailsById()
    return () => { }
  }, [id])

  const handleFetchDetailsById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await getSellExitingProjectsDetailApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SellExistingProjectReviewForm data={formData} />
      <div className='py-2 flex justify-center'>
        <RoundedButton variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
      </div>
      <LoadingDialog isOpen={isLoading} message='Fetching Details..' />
    </div>
  )
}

export default SellExistingProjectsViewPage