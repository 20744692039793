import { ScrollArea } from '@radix-ui/react-scroll-area'
import React from 'react'
import { Link } from 'react-router-dom'
import { componentType, convertToCamelCase, FormField } from 'src/helpers'
import { Label, Separator, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/shadcn/components/ui'
import { EPC_CONTRACTOR_PROFILE_FORM_FIELDS, EPC_SERVICES_FORM_FIELDS, IEPC_CONTRACTOR_PROFILE, IEPC_SERVICES, INIT_EPC_CONTRACTOR_PROFILE, INIT_EPC_SERVICES } from './modules'
import { ICreateProjectResponse } from 'src/services/requests/Projects/module'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'

type Props = {
    data: IEPC_CONTRACTOR_PROFILE
}

const RenderViewComponent = ({ label, value, type }: { label: string, type: componentType, value: string | number | undefined }) => {
    return <div className='flex flex-col border-b'>
        <Label className='mb-1 uppercase text-xs  text-gray-500'>{label}</Label>
        <div className='font-semibold mb-1  '>
            {
                value ? <>
                    {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{value}</div>}
                    {type == "file_picker" && <DocumentViwer url={value as any} contentType='image' title='View' /> }
                </> : "-"
            }
        </div>
    </div>
}

const EPCProfileReview = ({ data }: Props) => {
    return (
        <div className='p-4'> 
           
                {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>EPC Contractor Details</div> */}
                {/* <Separator className='my-2' /> */}
                <div className='grid grid-cols-4 gap-x-4 gap-y-2 my-2 '>
                    {
                        data && Object.keys(INIT_EPC_CONTRACTOR_PROFILE).map((k: any) => {
                            if (k == "services") {
                                return <div className='col-span-4'>
                                    <div className='flex justify-between items-center'>
                                        <Label className='text-xs mb-2'>Services</Label>
                                    </div>
                                    <Table className='border mt-2 mb-4'>
                                        <TableHeader >
                                            <TableRow className='bg-gray-100' >
                                                {Object.keys(INIT_EPC_SERVICES).filter((k: any) => k != "formId").map((m: any) => <TableHead  className='w-1/3' key={`service-list-${m}`} >{convertToCamelCase(m)}</TableHead>)}
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {
                                                data.services.map((d: any) => {
                                                    return <TableRow>
                                                        {Object.keys(INIT_EPC_SERVICES).filter((k: any) => k != "formId")
                                                            .map((m: any) => <TableCell  className='w-1/3' key={`service-list-${m}`} >
                                                                { d && d[m] && d[m].includes("http") ?  <>
                                                                    <DocumentViwer url={d[m] || ""} contentType='image' title='View' />
                                                                </> : <>{d[m] || ""}</> }

                                                            </TableCell>)}
                                                    </TableRow>
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </div>
                            }
                            else {
                                const formfield: FormField | undefined = EPC_CONTRACTOR_PROFILE_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey as keyof object]} type={formfield?.componentType} />
                            }
                        })
                    }
                </div>
           
        </div>
    )
}

export default EPCProfileReview