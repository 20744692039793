import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import ProjectName from "./ProjectName";



export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "projectName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Name" />
    ),
    cell({ row: { original } }) {
      return <ProjectName row={original} />
    },
  },

  {
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location" />
    ),
  },
  {
    accessorKey: "investment",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Investment" />
    ),
  },
  {
    accessorKey: "systemType",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="System Type" />
    ),
  },
  {
    accessorKey: "projectStartDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Start Date" />
    ),
  },
  {
    accessorKey: "expectedROI",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Expected ROI" />
    ),
  },
  {
    accessorKey: "completionDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Completion Date" />
    ),
  },
  {
    accessorKey: "projectStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Status" />
    ),
  },
];
