
import { ADDRESS_REGEX, CONNECTION_TYPE_OPTIONS, EMAIL_REGEX, FormField, NAME_REGEX, PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA, PROJECT_CONNECTION_TYPE_OPTIONS, PROJECT_OWNERSHIP_TYPE_OPTIONS, PROJECT_PLANT_STATUS_OPTIONS, PROJECT_PROJECT_OWNERSHIP_TYPE_OPTIONS, PROJECT_SOLAR_MODULES_TYPE_OPTIONS, PROJECT_SOLAR_TECHNOLOGY_OPTIONS, TYPE_OF_SOLAR_PLANT_OPTIONS, YEARS_RANGE_OPTIONS, YES_NO_OPTIONS } from 'src/helpers';
import CustomTextIcon from 'src/shadcn/components/custom/CustomeSymbols/CustomTextIcon';
import * as Yup from 'yup';

// Interface for Personal Details
export interface PersonalDetails {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternatePhone?: string; // Optional
    address: string;
    city: string;
    state: string;
    pinCode: string;
}

// Interface for Project Information
export interface ProjectInformation {
    projectLocation: string;
    projectCity: string;
    projectState: string;
    projectCountry?: string;
    totalInstalledCapacity: string;
    typeOfSolarPlant: string;
    connectionType: string;
    yearOfInstallation: string;
    plantStatus: string;
    solarTechnologyUsed: string;
    projectOwnershipType: string;
    imgUrl: string
}

// Interface for Financial and Ownership
export interface FinancialAndOwnership {
    totalInvestmentInProject: string;
    expectedSellingPriceForProjectRights: string;
    currentOwnerOfTheProject: string;
    percentageOfOwnershipToBeSold: string;
}

// Interface for Additional Project Information
export interface AdditionalProjectInformation {
    operationalSinceYears: string;
    annualEnergyProduction: string;
    outstandingLiabilities: string;
    powerPurchaseAgreement: string;
    subsidiesReceived: string;
    subsidyAmount: string,
    typeOfSolarModulesUsed: string;
    invertorModel: string;
    invertorBrand: string;
    invertorCapacity: string;
    batteryModel: string;
    batteryBrand: string;
    batteryCapacity: string;
    availabilityMonitoringSystem: string;
    expectedTimeframeForSale: string;
}


export interface Documents {
    powerPurchaseDoc: string,
    ownershipDoc: string,
    legalClearanceDoc: string,
    anyOtherRelevantDoc: string[]
}


export type IProjectRightsForm = PersonalDetails & ProjectInformation & FinancialAndOwnership & AdditionalProjectInformation & Documents

// Initial empty constants for each interface
export const initialPersonalDetails: PersonalDetails = {
    firstName: '',
    lastName: "",
    email: '',
    phone: '',
    alternatePhone: '',
    address: '',
    city: '',
    state: '',
    pinCode: ''
};

export const initialProjectInformation: ProjectInformation = {
    projectLocation: '',
    projectCity: '',
    projectState: '',
    projectCountry: 'India',
    totalInstalledCapacity: "",
    typeOfSolarPlant: '',
    connectionType: '',
    yearOfInstallation: "", // Default to current year
    plantStatus: '',
    solarTechnologyUsed: '',
    projectOwnershipType: '',
    imgUrl: ""
};

export const initialFinancialAndOwnership: FinancialAndOwnership = {
    totalInvestmentInProject: "",
    expectedSellingPriceForProjectRights: "",
    currentOwnerOfTheProject: '',
    percentageOfOwnershipToBeSold: ""
};

export const initialAdditionalProjectInformation: AdditionalProjectInformation = {
    operationalSinceYears: "",
    annualEnergyProduction: "",
    outstandingLiabilities: "",
    powerPurchaseAgreement: '',
    subsidiesReceived: "",
    subsidyAmount: "",
    typeOfSolarModulesUsed: '',
    invertorModel: '',
    invertorBrand: '',
    invertorCapacity: "",
    batteryModel: '',
    batteryBrand: '',
    batteryCapacity: "",
    availabilityMonitoringSystem: '',
    expectedTimeframeForSale: ''
};

export const initialDocuments = {
    powerPurchaseDoc: "",
    ownershipDoc: "",
    legalClearanceDoc: "",
    anyOtherRelevantDoc: [""]
}

export const INIT_PROJECT_RIGHTS = {
    ...initialPersonalDetails,
    ...initialProjectInformation,
    ...initialFinancialAndOwnership,
    ...initialAdditionalProjectInformation,
    ...initialDocuments
}

export const PROJECT_FORM_STRUCTURE = {
    "Personal Details": initialPersonalDetails,
    "Project Details": initialProjectInformation,
    "Financial And Ownership Details": initialFinancialAndOwnership,
    "Additional Project Information": initialAdditionalProjectInformation,
    "Documents": initialDocuments
}


// Validation schema for Personal Details
export const PROJECT_RIGHTS_VALIDATION_SCHEMA = Yup.object().shape({
    firstName: Yup.string()
        .required('Full Name is required'),
    lastName: Yup.string()
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email Address is required'),
    phone: Yup.string().trim().length(10, 'Phone Number must be 10 digits').required('Phone Number is required'),
    alternatePhone: Yup.string().trim()
        .length(10, 'Alternate Phone Number must be 10 digits')
        .notRequired(),
    address: Yup.string()
        .required('Address is required'),
    city: Yup.string()
        .required('City is required'),
    state: Yup.string()
        .required('State is required'),
    pinCode: Yup.string().trim()
        .required('Pin Code is required')
        .length(6, 'Pin Code must be 6 digits'),
    projectLocation: Yup.string()
        .required('Project Location is required'),
    projectCity: Yup.string()
        .required('Project City is required'),
    projectState: Yup.string()
        .required('Project State is required'),
    projectCountry: Yup.string(),
    totalInstalledCapacity: Yup.string()
        .required('Total Installed Capacity is required'),
    typeOfSolarPlant: Yup.string()
        .required('Type of Solar Plant is required'),
    connectionType: Yup.string()
        .required('Connection Type is required'),
    yearOfInstallation: Yup.string()
        .required('Year of Installation is required'),
    plantStatus: Yup.string()
        .required('Plant Status is required'),
    solarTechnologyUsed: Yup.string()
        .required('Solar Technology Used is required'),
    projectOwnershipType: Yup.string()
        .required('Project Ownership Type is required'),
    imgUrl: Yup.string()
        .required('Project Image is required'),
    totalInvestmentInProject: Yup.string()
        .required('Total Investment in Project is required'),
    expectedSellingPriceForProjectRights: Yup.string()
        .required('Expected Selling Price for Project Rights is required'),
    currentOwnerOfTheProject: Yup.string()
        .required('Current Owner of the Project is required'),
    percentageOfOwnershipToBeSold: Yup.string()
        .required('Percentage of Ownership to be Sold is required'),
    operationalSinceYears: Yup.string()
        .required('Operational Since Years is required'),
    annualEnergyProduction: Yup.string()
        .required('Annual Energy Production is required'),
    outstandingLiabilities: Yup.string()
        .required('Outstanding Liabilities is required'),
    powerPurchaseAgreement: Yup.string()
        .required('Power Purchase Agreement is required'),
    subsidiesReceived: Yup.string()
        .required('Subsidies Received is required'),
    subsidyAmount: Yup.string().when('subsidiesReceived', (subsidiesReceived: any, schema) => {
        return subsidiesReceived === 'Yes' ? schema.required('Subsidy Amount is required') : schema;
    }),
    typeOfSolarModulesUsed: Yup.string()
        .required('Type of Solar Modules Used is required'),
    invertorModel: Yup.string()
        .required('Invertor Model is required'),
    invertorBrand: Yup.string()
        .required('Invertor Brand is required'),
    invertorCapacity: Yup.string()
        .required('Invertor Capacity is required'),
    batteryModel: Yup.string()
        .required('Battery Model is required'),
    batteryBrand: Yup.string()
        .required('Battery Brand is required'),
    batteryCapacity: Yup.string()
        .required('Battery Capacity is required'),
    availabilityMonitoringSystem: Yup.string()
        .required('Availability Monitoring System is required'),
    expectedTimeframeForSale: Yup.string()
        .required('Expected Timeframe for Sale is required'),
    powerPurchaseDoc: Yup.mixed().nullable()
        .when('powerPurchaseAgreement', (value: any, schema: any) => {
            if (value == "yes") {
                return schema.PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required('Power purchase document required')

            } else {
                return schema.optional();
            }
        }),
    ownershipDoc: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Ownership doc required"),
    legalClearanceDoc: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Legal Clearance doc required"),
    anyOtherRelevantDoc: Yup.array().of(PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA).notRequired(),
});

// Form Field Constants for Personal Details
export const PROJECT_RIGHTS_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your full name',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your full name',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Email Address',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            regex: EMAIL_REGEX,
            placeholder: 'Enter your email address',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter your phone number',
            maxLength: 10,
            required: true
        },
    },
    {
        label: 'Alternate Phone Number',
        dataKey: 'alternatePhone',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter alternate phone number (optional)',
            maxLength: 10,
            required: false
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: ADDRESS_REGEX,
            placeholder: 'Enter your address',
            maxLength: 250,
            required: true
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your city',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your state',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Pin Code',
        dataKey: 'pinCode',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter your pin code',
            maxLength: 6,
            required: true
        },
    },
    {
        label: 'Project Location',
        dataKey: 'projectLocation',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project location',
            maxLength: 100,
            required: true
        },
    },
    {
        label: 'Project City',
        dataKey: 'projectCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project city',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Project State',
        dataKey: 'projectState',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project state',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Project Country',
        dataKey: 'projectCountry',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project country (default India)',
            maxLength: 50,
            required: false
        },
    },
    {
        label: 'Total Installed Capacity',
        dataKey: 'totalInstalledCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter total installed capacity',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />,
            required: true
        },
    },
    {
        label: 'Type of Solar Plant',
        dataKey: 'typeOfSolarPlant',
        componentType: 'options',
        componentProps: {
            options: TYPE_OF_SOLAR_PLANT_OPTIONS,
            required: true
        },
    },
    {
        label: 'Connection Type',
        dataKey: 'connectionType',
        componentType: 'options',
        componentProps: {
            options: PROJECT_CONNECTION_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Year of Installation',
        dataKey: 'yearOfInstallation',
        componentType: 'options',
        componentProps: {
            options: YEARS_RANGE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Plant Status',
        dataKey: 'plantStatus',
        componentType: 'options',
        componentProps: {
            options: PROJECT_PLANT_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Solar Technology Used',
        dataKey: 'solarTechnologyUsed',
        componentType: 'options',
        componentProps: {
            options: PROJECT_SOLAR_TECHNOLOGY_OPTIONS,
            required: true
        },
    },
    {
        label: 'Project Ownership Type',
        dataKey: 'projectOwnershipType',
        componentType: 'options',
        componentProps: {
            options: PROJECT_PROJECT_OWNERSHIP_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Total Investment in Project',
        dataKey: 'totalInvestmentInProject',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter total investment',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Expected Selling Price for Project Rights',
        dataKey: 'expectedSellingPriceForProjectRights',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter expected selling price',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Current Owner of the Project',
        dataKey: 'currentOwnerOfTheProject',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter current owner',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Percentage of Ownership to be Sold',
        dataKey: 'percentageOfOwnershipToBeSold',
        componentType: 'input',
        componentProps: {
            type: "number",
            maxLength: 3,
            inputSuffixIcon: <CustomTextIcon value='%' />,
            placeholder: 'Enter percentage of ownership',
            required: true
        },
    },
    {
        label: 'Operational Since Years',
        dataKey: 'operationalSinceYears',
        componentType: 'options',
        componentProps: {
            options: YEARS_RANGE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Annual Energy Production',
        dataKey: 'annualEnergyProduction',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='MW' />,
            placeholder: 'Enter annual energy production',
            required: true
        },
    },
    {
        label: 'Outstanding Liabilities',
        dataKey: 'outstandingLiabilities',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter outstanding liabilities',
            numberFormatted: true,
            inputPrefixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Power Purchase Agreement',
        dataKey: 'powerPurchaseAgreement',
        componentType: 'options',
        componentProps: {
            options: YES_NO_OPTIONS,
            required: true
        },
    },

    {
        label: 'Subsidies Received',
        dataKey: 'subsidiesReceived',
        componentType: 'options',
        componentProps: {
            options: YES_NO_OPTIONS,
            required: true
        },
    }, {

        label: 'Subsidies Amount',
        dataKey: 'subsidyAmount',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter subsidies received',
            numberFormatted: true,
            inputPrefixIcon: <CustomTextIcon value='₹' />,
            required: true,
            conditionalHide: {
                expectedValue: "Yes",
                formKey: "subsidiesReceived"
            }
        },
    },
    {
        label: 'Type of Solar Modules Used',
        dataKey: 'typeOfSolarModulesUsed',
        componentType: 'options',
        componentProps: {
            options: PROJECT_SOLAR_MODULES_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Invertor Model',
        dataKey: 'invertorModel',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter invertor model',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Invertor Brand',
        dataKey: 'invertorBrand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter invertor brand',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Invertor Capacity',
        dataKey: 'invertorCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter invertor capacity',
            required: true,
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />
        },
    },
    {
        label: 'Battery Model',
        dataKey: 'batteryModel',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter battery model',
            maxLength: 50,
            required: true,
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />
        },
    },
    {
        label: 'Battery Brand',
        dataKey: 'batteryBrand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter battery brand',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Battery Capacity',
        dataKey: 'batteryCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter battery capacity',
            required: true
        },
    },
    {
        label: 'Availability Monitoring System',
        dataKey: 'availabilityMonitoringSystem',
        componentType: 'options',
        componentProps: {
            options: YES_NO_OPTIONS,
            required: true
        },
    },
    {
        label: 'Expected Timeframe for Sale',
        dataKey: 'expectedTimeframeForSale',
        componentType: 'options',
        componentProps: {
            options: YEARS_RANGE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Power Purchase Document',
        dataKey: 'powerPurchaseDoc',
        componentType: 'file_picker',
        componentProps: {
            conditionalHide: {
                expectedValue: "Yes",
                formKey: "powerPurchaseAgreement"
            },
            required: true
        },
    },
    {
        label: 'OwnerShip Document',
        dataKey: 'ownershipDoc',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Legal Clearance Document',
        dataKey: 'legalClearanceDoc',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Any Other Relevant Document',
        dataKey: 'anyOtherRelevantDoc',
        componentType: 'file_picker',
        componentProps: {
            fileSelectionType: "multi",
            required: true
        },
    },
];

