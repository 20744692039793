
import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/EPC/projects";



export function epcAllProjectsApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_EPC_CONTRACTOR_ALL_PROJECTS_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}


export function epcProjectDetailsByIDApiRequest(id:string): Promise<any> {
    return axiosInstance.get(`${API.URL_EPC_CONTRACTOR_PROJECTS_DETAILS_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}

