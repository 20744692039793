// Yup Schema for Product
import * as yup from 'yup';
import { convertArrayToOptionType, FormField, NUMBER_REGEX, optionTypes } from 'src/helpers';


// Product Interface
export interface IManufacturerProducts {
    _id?: string;
    productImage: string;
    category: string;
    productType?: string;
    batteryType?: string;
    name: string;
    description: string;
    price: string;
    brand?: string;
    total_quantity: string;
    wattage?: string;
    efficiency?: string;
    power?: string;
    technology?: string;
    capacity?: string;
    voltage?: string;
    type?: string;
    material?: string;
    kitType?: string;
    systemSize?: string;
    productWarranty?: string;
    maxWeight?: string;
    components?: IManufacturerProducts[];
    manufacturer_id: string;
    current_quantity: string;
}


export const SOLAR_PANELS_PRODUCT_TYPE = ["Monocrystalline", "Polycrystalline", "Thin film"]
export const MOUNTING_SYSTEM_PRODUCT_TYPE = ["Ground Mount", "Pitched Roof", "Flat Roof", "Floating Mount", "Carport Mount", "Farming Mount", "BIPV"]
export const SOLAR_INVERTERS_PRODUCT_TYPE = ["On-Grid", "Off-Grid", "Hybrid", "MIcro"]
export const BATTERIES_PRODUCT_TYPE = ["Lithium Battery"]
export const MANUFACTURER_PRODUCT_CATEGORY = ["Solar Panels", "Solar Inverters", "Mounting System", "Batteries", "Installation Accessories"]


export const ENUM_SOLAR_PANELS_PRODUCT_TYPE = {
    "Monocrystalline": "Monocrystalline",
    "Polycrystalline": "Polycrystalline",
    "Thin film": "Thin film"
};

export const ENUM_MOUNTING_SYSTEM_PRODUCT_TYPE = {
    "Ground Mount": "Ground Mount",
    "Pitched Roof": "Pitched Roof",
    "Flat Roof": "Flat Roof",
    "Floating Mount": "Floating Mount",
    "Carport Mount": "Carport Mount",
    "Farming Mount": "Farming Mount",
    "BIPV": "BIPV"
};

export const ENUM_SOLAR_INVERTERS_PRODUCT_TYPE = {
    "On-Grid": "On-Grid",
    "Off-Grid": "Off-Grid",
    "Hybrid": "Hybrid",
    "MIcro": "MIcro"
};

export const ENUM_BATTERIES_PRODUCT_TYPE = {
    "Lithium Battery": "Lithium Battery"
};

export const ENUM_MANUFACTURER_PRODUCT_CATEGORY = {
    "Solar_Panels": "Solar Panels",
    "Solar_Inverters": "Solar Inverters",
    "Mounting_System": "Mounting System",
    "Batteries": "Batteries"

};

export const SOLAR_INSTALLATION_ACCESSORIES = [
    "Monitors",
    "Trackers",
    "Cables",
    "Connectors",
    "Fuses",
    "Mounting Rails",
    "Roof Attachments",
    "Module Clamps",
    "Ground Screws",
    "Power Optimizers"
]

export const MATERIAL_TYPE = ["Aluminium",
    "Stainless Steel",
    "Galvanized Steel",
    "Polyethylene"
]

export const SOLAR_PANEL_TECHNOLOGY = [
    "BIPV",
    "Flexible",
    "HJT",
    "Back Contact",
    "TOPCon",
    "PERC",
    "Bifacial"
]

export const BATTERY_TECHNOLOGIES = [
    "Lithium-ion",
    "LFP (LiFePO4)",
    "NMC",
    "Lead-Acid",
    "Flooded (FLA)",
    "Sealed (SLA)",
    "AGM",
    "GEL",
    "Lead Carbon",
    "Other",
    "Flow",
    "Ni-Cd",
    "Ni-MH",
    "Sodium-ion (Na-ion)"
]

export const PRODUCT_WARRANTY = [
    "Below 5 Years",
    "5 Years",
    "10 Years",
    "More Than 10 Years",
];

export const SOLAR_PANELS_PRODUCT_TYPE_OPTIONS = convertArrayToOptionType(SOLAR_PANELS_PRODUCT_TYPE)
export const SOLAR_INVERTERS_PRODUCT_TYPE_OPTIONS = convertArrayToOptionType(SOLAR_INVERTERS_PRODUCT_TYPE)
export const MOUNTING_SYSTEM_PRODUCT_TYPE_OPTIONS = convertArrayToOptionType(MOUNTING_SYSTEM_PRODUCT_TYPE)
export const BATTERIES_PRODUCT_TYPE_OPTIONS = convertArrayToOptionType(BATTERIES_PRODUCT_TYPE)
export const MANUFACTURER_PRODUCT_CATEGORY_OPTIONS = convertArrayToOptionType(MANUFACTURER_PRODUCT_CATEGORY)
export const SOLAR_INSTALLATION_ACCESSORIES_OPTIONS = convertArrayToOptionType(SOLAR_INSTALLATION_ACCESSORIES)
export const MATERIAL_TYPE_OPTIONS = convertArrayToOptionType(MATERIAL_TYPE)
export const SOLAR_PANEL_TECHNOLOGY_OPTIONS = convertArrayToOptionType(SOLAR_PANEL_TECHNOLOGY)
export const BATTERY_TECHNOLOGIES_OPTIONS = convertArrayToOptionType(BATTERY_TECHNOLOGIES)
export const PRODUCT_WARRANTY_OPTIONS = convertArrayToOptionType(PRODUCT_WARRANTY)

export const PRODUCT_TYPE_OPTIONS_MAPPING = {
    "Solar Panels": SOLAR_PANELS_PRODUCT_TYPE_OPTIONS,
    "Solar Inverters": SOLAR_INVERTERS_PRODUCT_TYPE_OPTIONS,
    "Mounting System": MOUNTING_SYSTEM_PRODUCT_TYPE_OPTIONS,
    "Batteries": BATTERIES_PRODUCT_TYPE_OPTIONS,
    "Installation Accessories": SOLAR_INSTALLATION_ACCESSORIES_OPTIONS
}

export const TECHNOLOGY_TYPE_OPTIONS_MAPPING = {
    "Solar Panels": SOLAR_PANEL_TECHNOLOGY_OPTIONS,
    "Batteries": BATTERY_TECHNOLOGIES_OPTIONS,
}

export const productSchema = yup.object().shape({
    productImage: yup.string().required('Product Image is required'),
    category: yup.string().required('Category is required'),
    productType: yup.string().required('Product is required'),
    name: yup.string().required('Product Name is required'),
    description: yup.string().required('Description is required'),
    price: yup.number().required('Price is required'),
    brand: yup.string(),
    wattage: yup.number(),
    efficiency: yup.number(),
    power: yup.number(),
    technology: yup.string(),
    capacity: yup.number(),
    voltage: yup.number(),
    type: yup.string(),
    material: yup.string(),
    kitType: yup.string(),
    systemSize: yup.number(),
    productWarranty: yup.string().required('Product Warranty is required'),
    batteryType: yup.string(),
    maxWeight: yup.string(),
    // components: yup.array().of(yup.object().shape({

    // })),
    // manufacturer_id: yup.string().required('Manufacturer ID is required'),
    total_quantity: yup.number().required('Total Quantity is required'),
    // current_quantity: yup.number().required('Current Quantity is required'),
});

// Form Fields for Product
export const PRODUCT_FORM_FIELDS: FormField[] = [
    {
        label: 'Product Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Name',
            required: true,
        },
    },
    {
        label: 'Product Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Description',
            required: true,
        },
    },
    {
        label: 'Category',
        dataKey: 'category',
        componentType: 'options',
        componentProps: {
            options: MANUFACTURER_PRODUCT_CATEGORY_OPTIONS,
            required: true,
        },
    },
    // {
    //     label: 'Battery Type',
    //     dataKey: 'batteryType',
    //     componentType: 'options',
    //     componentProps: {
    //         options: BATTERIES_PRODUCT_TYPE_OPTIONS,
    //         required: true,
    //         conditionalHide: {
    //             expectedValue: ENUM_MANUFACTURER_PRODUCT_CATEGORY.Batteries,
    //             formKey: "category"
    //         }
    //     },
    // },
    {
        label: 'Price',
        dataKey: 'price',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Price',
            required: true,
        },
    },
    {
        label: 'Brand',
        dataKey: 'brand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Brand',
        },
    },
    {
        label: 'Wattage',
        dataKey: 'wattage',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Wattage',
        },
    },
    {
        label: 'Efficiency (%)',
        dataKey: 'efficiency',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Efficiency',
        },
    },
    {
        label: 'Power Rating',
        dataKey: 'power',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Power Rating',
        },
    },
    {
        label: 'Technology',
        dataKey: 'technology',
        componentType: 'options',
        componentProps: {
            options: [],
            conditionalHide: {
                expectedValue: [ENUM_MANUFACTURER_PRODUCT_CATEGORY.Solar_Panels, ENUM_MANUFACTURER_PRODUCT_CATEGORY.Batteries],
                formKey: "category"
            }
        },
    },
    {
        label: 'Capacity',
        dataKey: 'capacity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Capacity',
        },
    },
    {
        label: 'Voltage',
        dataKey: 'voltage',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Voltage',
        },
    },
    {
        label: 'Product Type',
        dataKey: 'productType',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Material Type',
        dataKey: 'material',
        componentType: 'options',
        componentProps: {
            options: MATERIAL_TYPE_OPTIONS,
            conditionalHide: {
                expectedValue: ENUM_MANUFACTURER_PRODUCT_CATEGORY.Mounting_System,
                formKey: "category"
            }
        },
    },
    {
        label: 'Max Weight',
        dataKey: 'maxWeight',
        componentType: 'options',
        componentProps: {
            type: "text",
            placeholder: 'maxWeight',
        },
    },
    {
        label: 'Kit Type',
        dataKey: 'kitType',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Kit Type',
        },
    },
    {
        label: 'System Size',
        dataKey: 'systemSize',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'System Size',
        },
    },

    {
        label: 'Product Warranty',
        dataKey: 'productWarranty',
        componentType: 'options',
        componentProps: {
            options:PRODUCT_WARRANTY_OPTIONS,
            required: true
        },
    },
    {
        label: 'Total Quantity',
        dataKey: 'total_quantity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            numberFormatted: true,
            placeholder: 'Total Quantity',
            required: true,
        },
    },
];

// Initialize the form fields with initial values
export const INITIAL_PRODUCT_VALUES: IManufacturerProducts = {
    productImage: '',
    category: '',
    productType: '',
    name: '',
    description: '',
    price: "",
    total_quantity: "",
    productWarranty: '',
    brand: '',
    wattage: "",
    efficiency: "",
    power: "",
    technology: '',
    capacity: "",
    voltage: "",
    type: '',
    material: '',
    kitType: '',
    systemSize: "",
    batteryType: '',
    maxWeight: '',
    components: [],
    manufacturer_id: '',
    current_quantity: "",
};