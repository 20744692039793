import React, { ChangeEvent } from 'react'
import { Label } from '../../ui';
import { cn } from 'src/helpers';
import PasswordInputWithIcon from './PasswordInputWithIcon';
import { ClassNameValue } from 'tailwind-merge';

type Props = {
    formik: any;
    label: string;
    nameKey: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
    labelStyle?: ClassNameValue
}

const FormikPasswordFiled = ({
    formik,
    placeholder,
    nameKey,
    label,
    value,
    disabled,
    required,
    onChange,
    onBlur,
    labelStyle
}: Props) => {
    return (
        <div className="flex flex-col  mt-2">
            <Label className={cn(" text-xs", labelStyle)}>
                {label}  {required ? <span className="text-red-600 font-semibold">*</span> : null} 
            </Label>
            <div className="mt-2">
                <PasswordInputWithIcon
                    name={nameKey}
                    onBlur={onBlur && onBlur}
                    disabled={disabled || false}
                    maxLength={16}
                    placeholder={placeholder || label}
                    value={formik.values[nameKey] || value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
                />
            </div>
            {formik.touched[nameKey] && formik.errors[nameKey] && (
                <div className={cn("text-xs font-medium text-destructive")}>
                    {formik.errors[nameKey]}
                </div>
            )}
        </div>
    )
}

export default FormikPasswordFiled