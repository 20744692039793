import React, { useMemo } from 'react'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { DataTable } from 'src/modules/Services/Rooftop/Table/components/data-table'
import { columns } from 'src/modules/Services/Rooftop/Table/components/columns'
import { Button } from 'src/shadcn/components/ui'
import { useNavigate, useNavigation } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import { Plus } from 'lucide-react'

type Props = {}

const Main = () => {

  const apiResp = useQueryResponseData()
  const tableData = useMemo(() => {
    if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
      return apiResp.map((d: any, indx: number) => ({ ...d, srNo: (indx + 1) }))
    }
    return []
  }, [apiResp])

  const isLoading = useQueryResponseLoading()

  return <DataTable isLoading={isLoading} data={tableData || []} columns={columns} />
}

const AllRooftopRequestsMainPage = (props: Props) => {

  const navigate = useNavigate()

  return (
    <div className='p-4 space-y-4'>

      {/* <div className='flex justify-between'>
        <span className='text-primary'>All Rooftop</span>
        <Button onClick={() => navigate("/" + APP_ROUTES.rooftop.subRoutes.create.fullRoute)} className='space-x-1'><span><Plus size={16} /></span> <span>Create</span> </Button>
      </div> */}

      <QueryRequestProvider>
        <QueryResponseProvider>
          <Main />
        </QueryResponseProvider>
      </QueryRequestProvider>
    </div>
  )
}

export default AllRooftopRequestsMainPage