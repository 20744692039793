import { Download, RefreshCcw } from 'lucide-react'
import React, { useMemo, useState } from 'react'

import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Button } from 'src/shadcn/components/ui'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { DataTable } from 'src/modules/investors/AllProjectsByInvestors/Table/components/data-table'
import { columns } from 'src/modules/investors/AllProjectsByInvestors/Table/components/columns'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import ToolTip from 'src/shadcn/components/custom/ToolTip'

type Props = {
    investorID: undefined | string
}


const Main = () => {

    const apiData = useQueryResponseData()
    const { refetch } = useQueryResponse()
    const isLoading = useQueryResponseLoading()
    const tabelData = useMemo(() => apiData && apiData?.map((d: any, indx: number) => {
        return { ...d, srNo: (indx + 1) }
    }), [apiData])


    return (
        <div className='space-y-4'>
            <div className='flex justify-between'>
                <div className='text-primary text-lg uppercase '>Projects</div>
                <div className='flex items-center'>
                    <span>Total Projects : </span> <span>{tabelData.length || 0}</span>
                    <ToolTip tooltipContent='Re-load'>
                        <Button onClick={() => refetch()} variant={'primaryIconBtn'}><RefreshCcw size={16} /></Button>
                    </ToolTip>
                </div>
            </div>
            <DataTable isLoading={isLoading} data={tabelData || []} columns={columns} />
            <LoadingDialog isOpen={isLoading} message='Feting Investors Projects...' />
        </div>
    )
}

const AllInvestorsProjectsMain = ({ investorID }: Props) => {
    return (
        <QueryRequestProvider >
            <QueryResponseProvider props={{ investorID }}>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllInvestorsProjectsMain