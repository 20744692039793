import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllInvestorsMain from './AllInvestors'
import InvestorDetailsMain from './InvestorDetails/InvestorDetailsMain'


type Props = {}

const InvestorsRoute = (props: Props) => {

    const PROJECTS_ROUTES = APP_ROUTES.investors.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllInvestorsMain />} />
                <Route path={PROJECTS_ROUTES.all.route} element={<AllInvestorsMain />} />
                <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<InvestorDetailsMain />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.investors.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default InvestorsRoute