import { FileCheck2, FileClock, FileCog, FileMinus2, Files, FileSearch, Siren } from "lucide-react";

export const ADMIN_ANALYSIS_DATA = [
  {
    title: "Total Pending For Approval",
    value: "0",
    dataKey: "totalPendingApproval",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Financial Eligibility",
    value: "0",
    dataKey: "totalPendingEligibility",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Executed",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Rejected ",
    value: "0",
    dataKey: "totalRejected",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Enquiry Raised By Offtaker",
    value: "0",
    dataKey: "totalResuestsByOfftaker",
    prefix: "#",
    icon: <FileSearch size={24} className='text-gray-500' />
  },
  {
    title: "Total Enquiry",
    value: "0",
    dataKey: "totalEnquiries",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
]


export const EPC_CONTRACTOR_ANALYSIS_DATA = [
  {
    title: "MTD-Project Requests",
    value: "0",
    dataKey: "monthlyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Request of Day",
    value: "0",
    dataKey: "dailyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Approved Projects",
    value: "0",
    dataKey: "approvedCount",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Approval",
    value: "0",
    dataKey: "totalPendingApproval",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Pending For Financial Eligibility",
    value: "0",
    dataKey: "totalPendingEligibility",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Executed",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Rejected ",
    value: "0",
    dataKey: "totalRejected",
    prefix: "#",
    icon: <Siren size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Size (kW)",
    value: "0",
    dataKey: "projectSize",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
]


export const PROJECT_DATA_ANALYSIS = [
  {
    title: "Total Project Size (kW)",
    value: "0",
    dataKey: "projectSize",
    prefix: "#",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Cost (INR)",
    value: "0",
    dataKey: "projectCount",
    prefix: "₹",
    icon: <FileCog size={24} className='text-gray-500' />
  },
  {
    title: "Total Amount Invested",
    value: "0",
    dataKey: "investmentCount",
    prefix: "₹",
    icon: <FileCog size={24} className='text-gray-500' />
  },
]


export const EVALUATOR_ANALYSIS_DATA = [
  {
    title: "Total Projects",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Verified",
    value: "0",
    dataKey: "totalVerifiedProjects",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Un-Verified",
    value: "0",
    dataKey: "totalUnVerifiedProjects",
    prefix: "#",
    icon: <FileMinus2 size={24} className='text-gray-500' />
  },
  {
    title: "Pending",
    value: "0",
    dataKey: "totalPendingProjects",
    prefix: "#",
    icon: <FileClock size={24} className='text-gray-500' />
  },
]

export const SURVEY_ANALYSIS_DATA = [
  {
    title: "MTD-Project Requests",
    value: "0",
    dataKey: "monthlyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Project Request of Day",
    value: "0",
    dataKey: "dailyCount",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Approved Projects",
    value: "0",
    dataKey: "approvedCount",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },

]

export const STAKEHOLDER_ANALYSIS_DATA = [
  {
    title: "Total Projects",
    value: "0",
    dataKey: "totalprojects",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Approved Projects",
    value: "0",
    dataKey: "totalApprovedProjects",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Reject Projects",
    value: "0",
    dataKey: "totalRejectedProjects",
    prefix: "#",
    icon: <FileMinus2 size={24} className='text-gray-500' />
  },
  {
    title: "Pending Projects",
    value: "0",
    dataKey: "totalPendingProjects",
    prefix: "#",
    icon: <FileClock size={24} className='text-gray-500' />
  },
]


export const ORDER_ANALYSIS_DATA = [
  {
    title: "Total Orders",
    value: "0",
    dataKey: "totalOrders",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Pending Orders",
    value: "0",
    dataKey: "orderStatusWiseCount.PENDING",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Canceled Orders",
    value: "0",
    dataKey: "orderStatusWiseCount.CANCELLED",
    prefix: "#",
    icon: <FileMinus2 size={24} className='text-gray-500' />
  },
]

export const QUOTATION_REQUESTS_ANALYSIS_DATA = [
  {
    title: "Total Requests",
    value: "0",
    dataKey: "totalRequests",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Interested Users",
    value: "0",
    dataKey: "totalInterestedUsers",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  }
]


export const PRODUCTS_ANALYSIS_DATA = [
  {
    title: "Total Products",
    value: "0",
    dataKey: "totalProducts",
    prefix: "#",
    icon: <Files size={24} className='text-gray-500' />
  },
  {
    title: "Total Products",
    value: "0",
    dataKey: "uniqueProductTypes",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Total Categories",
    value: "0",
    dataKey: "uniqueCategories",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },
  {
    title: "Total Low Stock Products",
    value: "0",
    dataKey: "lowStockProducts",
    prefix: "#",
    icon: <FileCheck2 size={24} className='text-gray-500' />
  },

]