import { FormField } from 'src/helpers';
import * as Yup from 'yup';

export interface ISalesExecutive {
    _id?: string,
    name: string;
    phone: string;
    email: string;
    state: string;
    city: string;
    pincode: string
    reportingManagerName: string
}

export const initSalesExecutive: ISalesExecutive = {
    name: '',
    phone: '',
    email: '',
    state: "",
    city: "",
    pincode: "",
    reportingManagerName: ""
};

export const SalesExecutiveFormFields: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Name',
            maxLength: 150
        },
    },
    {
        label: 'Phone',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone Number',
            regex: /[^0-9]+/g,
            maxLength: 10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'State',
            maxLength: 50
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'City',
            maxLength: 50
        },
    },
    {
        label: 'Pincode',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Pincode',
            regex: /[^0-9]+/g,
            maxLength: 6
        },
    },
    {
        label: 'Reporting Manager Name',
        dataKey: 'reportingManagerName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Name'
        },
    },

];


export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number').required('Phone is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    pincode: Yup.string().matches(/^[0-9]{6}$/, 'Invalid pin code').required('Pin Code is required'),
    reportingManagerName: Yup.string().required('Reporting Manager Name is required'),
});