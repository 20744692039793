import { AxiosResponse } from "axios";
import { getAuth } from "src/modules/Auth/Core";
import { IChangePassword, IProfile } from "src/modules/Profile/modules";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Profile";


export function getUserProfileRequestApi(): Promise<any> {
    const auth = getAuth()
    return axiosInstance.get(`${API.URL_FETCH_USER_PROFILE_DETAILS}/${auth?.user_id}`).then((d: AxiosResponse) => d.data);
}

export function changePasswordRequestApi({ email, password }: IChangePassword): Promise<any> {
    return axiosInstance.post(API.URL_CHANGE_PASSWORD, { email, password }).then((d: AxiosResponse) => d.data);
}

export function updateUserProfileRequestApi(data: IProfile): Promise<any> {
    const auth = getAuth()
    return axiosInstance.post(API.URL_UPDATE_USER_PROFILE_DETAILS, { ...data, _id: auth?.user_id }).then((d: AxiosResponse) => d.data);
}