import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No." />
    ),
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sales Executive Name" />
    ),
  },
  {
    accessorKey: "totalProjects",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Projects (#)" />
    ),
  },
];
