import { optionTypes } from "src/helpers";

export interface ContextProps {
    selectedValues: optionTypes | undefined;
    toggleValue: (e: any) => void,
}

export const initialContext = {
    selectedValues: undefined,
    toggleValue: () => { }
}
