import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import CreateManufacturerProductForm from 'src/modules/ManufacturerProducts/CreateManufacturerProductForm'
import { INITIAL_PRODUCT_VALUES } from 'src/modules/ManufacturerProducts/module'
import { createProductApiRequest, getProductDetailByIDApiRequest, updateProductByIDApiRequest } from 'src/services/requests/Products'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const UpdateProductDetailsPage = (props: Props) => {


  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [updating, setupdating] = useState<boolean>(false)
  const [productData, setproductData] = useState(INITIAL_PRODUCT_VALUES)
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      handleFetchPoductDeatilsById()
    }
    return () => { }
  }, [id])


  const handleFetchPoductDeatilsById = catchAsync(async () => {
    setisLoading(true)
    return id && await getProductDetailByIDApiRequest(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setproductData(result.data)
    }
    setisLoading(false)
  }, () => { setisLoading(false) })

  const handleUpdatePoduct = catchAsync(async (formdata: any) => {
    setupdating(true)
    let postData = {
      ...formdata,
      manufacturer_id: currentUser?.id,
      current_quantity: formdata?.total_quantity
    }
    return await updateProductByIDApiRequest(id, postData)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      navigate(-1)
    }
    setupdating(false)
  }, () => { setupdating(false) })


  return (
    <div className='p-4'>
      <CreateManufacturerProductForm initValues={productData} onSubmit={(e: any) => handleUpdatePoduct(e)} />
      <LoadingDialog isOpen={isLoading} message='Fetching Product' />
      <LoadingDialog isOpen={updating} message='Updating Product' />
    </div>
  )
}

export default UpdateProductDetailsPage