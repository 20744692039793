import { AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/investors";
import { IGET_ALL_INVESTORS_API_RESPONSE, IGET_ALL_PROJECTS_BY_INVESTORS, IGET_INVESTOR_DETAILS_API_RESPONSE } from "./modules";

export function getAllInvestorsListRequestApi(query?: string): Promise<IGET_ALL_INVESTORS_API_RESPONSE> {
    return axiosInstance.get(`${API.URL_ALL_INVESTORS}${query ? "?" + query : ""}`).then((d: AxiosResponse) => d.data);
}

export function getInvestorsDetailsByIDRequestApi(id?: string): Promise<IGET_INVESTOR_DETAILS_API_RESPONSE> {
    return axiosInstance.get(`${API.URL_INVESTORS_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

export function getAllInvestorsProjectsListRequestApi(id:string,query?: string): Promise<IGET_ALL_PROJECTS_BY_INVESTORS> {
    return axiosInstance.get(`${API.URL_INVESTORS_DETAILS}/${id}/projects${query ? "?" + query : ""}`).then((d: AxiosResponse) => d.data);
}
