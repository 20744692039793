import React from 'react'
import SignUp from 'src/modules/Auth/SignUp'

type Props = {}

const SignupMain = (props: Props) => {
  return (
    <div>
      <SignUp />
    </div>
  )
}

export default SignupMain