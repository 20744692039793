import { ADDRESS_REGEX, AUTHORIZATION_PARTNER_OPTIONS, EMAIL_REGEX, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, NAME_REGEX, NUMBER_REGEX, SPECIALIZATION_OPTIONS, YES_NO_OPTIONS } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as Yup from "yup"

export interface IEPC_CONTRACTOR_PROFILE {
    contractorName: string,
    contractorNumber: string,
    contractorEmail: string,
    location: string,
    address: string,
    pincode: string,
    capacityInstalled: string,
    noOfYearsExperiance: string,
    noOfReview: string,
    city:string,
    // freeAreaAvailableOnGround: string,
    services: IEPC_SERVICES[],
    projectDescription: string,
    companyLogo: string | File | undefined

    authorization_partner:string,
    specialization:string,
    is_otc_verified:string
}

export interface IEPC_SERVICES {
    formId?: number | undefined,
    productImage: string | File,
    productName: string,
    productDescription: string
}



export const INIT_EPC_SERVICES = {
    formId: 1,
    productImage: "",
    productName: "",
    productDescription: ""
}

export const INIT_EPC_CONTRACTOR_PROFILE = {
    contractorName: "",
    contractorNumber: "",
    contractorEmail: "",
    location: "",
    address: "",
    city:"",
    pincode: "",
    capacityInstalled: "",
    noOfYearsExperiance: "",
    noOfReview: "",
    // freeAreaAvailableOnGround: "",
    projectDescription: "",
    companyLogo: "",
    authorization_partner:"",
    specialization:"",
    is_otc_verified:"",
    services: [INIT_EPC_SERVICES],
}


export const EPC_CONTRACTOR_PROFILE_FORM_FIELDS: FormField[] = [
    {
        label: 'Contractor Name',
        dataKey: 'contractorName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Contractor Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Contractor Phone Number',
        dataKey: 'contractorNumber',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Contractor Number',
            regex: NUMBER_REGEX,
            maxLength: 10,
            required: true
        },
    },
    {
        label: 'Contractor Email',
        dataKey: 'contractorEmail',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Contractor Email',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX,
            // readonly:true
        },
    },
    {
        label: 'Location',
        dataKey: 'location',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Location',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Address',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'city',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'Pincode',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NUMBER_REGEX,
            placeholder: 'Pincode',
            maxLength: 6,
            required: true
        },
    },
    {
        label: 'Capacity Installed',
        dataKey: 'capacityInstalled',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            placeholder: 'Capacity Installed',
            maxLength: 50,
            numberFormatted: true,
            required: true,
            inputSuffixIcon: <CustomTextIcon value="kW" />
        },
    },
    {
        label: 'No. of Years Experience',
        dataKey: 'noOfYearsExperiance',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            placeholder: 'No. of Years Experience',
            maxLength: 50,
            numberFormatted: true,
            required: true
        },
    },
    {
        label: 'No. of Reviews',
        dataKey: 'noOfReview',
        componentType: 'options',
        componentProps: {
            options: [...new Array(5)].map((_, indx: number) => {
                return {
                    label: (indx + 1).toString(),
                    value: (indx + 1).toString()
                }
            })
        },
    },
    // {
    //     label: 'Free Area Available on Ground',
    //     dataKey: 'freeAreaAvailableOnGround',
    //     componentType: 'input',
    //     componentProps: {
    //         type: "text",
    //         regex: /[^0-9]+/g,
    //         placeholder: 'Free Area Available on Ground',
    //         maxLength: 50,
    //         numberFormatted: true,
    //         required: true
    //     },
    // },
    {
        label: 'About us',
        dataKey: 'projectDescription',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Write about yourself',
            required: true
        },
    },
    {
        label: 'Company Logo',
        dataKey: 'companyLogo',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select files',
            required: true
        },
    },
    {
        label: 'Authorization Partner',
        dataKey: 'authorization_partner',
        componentType: 'options',
        componentProps: {
            options:AUTHORIZATION_PARTNER_OPTIONS
        },
    },
    {
        label: 'OCT-Verified',
        dataKey: 'is_otc_verified',
        componentType: 'options',
        componentProps: {
            options:YES_NO_OPTIONS
        },
    },
    {
        label: 'Specialization',
        dataKey: 'specialization',
        componentType: 'options',
        componentProps: {
            options:SPECIALIZATION_OPTIONS,
            optionsSearchable:true,
            required: true
        },
    },
];


export const EPC_SERVICES_FORM_FIELDS: FormField[] = [
    {
        label: 'Service Image',
        dataKey: 'productImage',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select image',
            required: true
        },
    },
    {
        label: 'Service Name',
        dataKey: 'productName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Product Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Service Description',
        dataKey: 'productDescription',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Product Description',
            required: true,

        },
    },
];


export const epcContractorProfileSchema = Yup.object().shape({
    contractorName: Yup.string().required('Contractor name is required'),
    contractorNumber: Yup.string().length(10,"should be 10 characters").required('Contractor Phone is required'),
    contractorEmail: Yup.string().email('Invalid email').required('Contractor email is required'),
    location: Yup.string().required('Location is required'),
    address: Yup.string().required('Address is required'),
    pincode: Yup.string().length(6,"should be 6 characters").required('Pincode is required'),
    city: Yup.string().required('city is required'),
    capacityInstalled: Yup.string().required('Capacity installed is required'),
    noOfYearsExperiance: Yup.string().required('Number of years of experience is required'),
    noOfReview: Yup.string().required('Number of reviews is required'),
    // freeAreaAvailableOnGround: Yup.string().required('Free area available on ground is required'),
    // services: Yup.array().required().test(
    //     'min-length',
    //     'At least 1 services are required',
    //     (services: any) => {
    //         console.log({ services});
    //         let reocrds = Object.keys(services[services.length - 1]).filter((d: any) => d !== "")
    //         console.log({reocrds});
    //         return services.length > 0 && reocrds.length == 4

    //     }
    // ),
    projectDescription: Yup.string().required('Project description is required'),
    companyLogo: IMAGE_DOCUMENT_VALIDATION_SCHEMA,
    authorization_partner: Yup.string().required('Authorization partner is required'),
    specialization: Yup.string().required('Specialization is required'),
    is_otc_verified: Yup.string().required('OTC verification is required'),
});


export const epcContractorServicesSchema = Yup.array().of(
    Yup.object().shape({
        productImage: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Product image required"),
        productName: Yup.string().required('Product name is required'),
        productDescription: Yup.string().required('Product description is required'),
    })
)

export const epcContractorSingleServicesSchema =
    Yup.object().shape({
        productImage: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Product image required"),
        productName: Yup.string().required('Product name is required'),
        productDescription: Yup.string().required('Product description is required'),
    })


