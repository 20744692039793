import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';


interface tempDataReducerState {
    email_for_validate_otp: string
    transaction_id: string,
    session: string,
    showLoginModal: boolean
    quotation_request_data: any
}

const initialState: tempDataReducerState = {
    email_for_validate_otp: "",
    transaction_id: "",
    session: "",
    showLoginModal: false,
    quotation_request_data: null
};


const tempDataSlice = createSlice({
    name: 'tempDataSlice',
    initialState,
    reducers: {
        setEmailForValidatOtp: (state, action: PayloadAction<any>) => {
            state.email_for_validate_otp = action.payload;
        },
        resetEmailForValidatOtp: (state) => {
            state.email_for_validate_otp = "";
        },
        setTransactionID: (state, action: PayloadAction<any>) => {
            state.transaction_id = action.payload;
        },
        resetTransactionID: (state) => {
            state.transaction_id = "";
        },
        setSession: (state, action: PayloadAction<any>) => {
            state.session = action.payload;
        },
        resetSession: (state) => {
            state.session = "";
        },
        setShowLoginModule: (state, action: PayloadAction<any>) => {
            state.showLoginModal = action.payload;
        },
        resetShowLoginModule: (state) => {
            state.showLoginModal = false;
        },
        setQuotationDetails: (state, action: PayloadAction<any>) => {
            state.quotation_request_data = action.payload;
        },
        resetQuotationDetails: (state) => {
            state.quotation_request_data = null;
        }
    },
    extraReducers: (builder) => {
    },

});



export const { setEmailForValidatOtp, resetEmailForValidatOtp, setTransactionID, resetTransactionID, setSession, resetSession, setShowLoginModule, resetShowLoginModule, setQuotationDetails, resetQuotationDetails } = tempDataSlice.actions;
export const getTempDataRootState = (state: RootState) => state.tempDataReducer;
export default tempDataSlice.reducer;