import { Download, RefreshCcw } from 'lucide-react'
import React, { useMemo } from 'react'
import { columns } from 'src/modules/investors/AllInvestors/Table/components/columns'
import { DataTable } from 'src/modules/investors/AllInvestors/Table/components/data-table'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Button } from 'src/shadcn/components/ui'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import ToolTip from 'src/shadcn/components/custom/ToolTip'


type Props = {}


const Main = () => {

    const apiData = useQueryResponseData()
    const { refetch } = useQueryResponse()
    const isLoading = useQueryResponseLoading()
    const tabelData = useMemo(() => apiData && apiData?.map((d: any, indx: number) => {
        return { ...d, srNo: (indx + 1) }
    }), [apiData])


    return (
        <div className='p-4 space-y-4'>
            <div className='flex justify-end'>
                <div className='flex space-x-2'>
                    <Button className='space-x-2 rounded-lg' ><span>Download</span> <Download className='text-white' size={16} /></Button>
                    <ToolTip tooltipContent='Re-load'>
                        <Button className='space-x-2 rounded-lg' onClick={() => refetch()} variant={"primaryIconBtn"} ><RefreshCcw size={16} /></Button>
                    </ToolTip>
                </div>
            </div>
            <DataTable isLoading={isLoading} data={tabelData || []} columns={columns} />
        </div>
    )
}

const AllInvestorsMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllInvestorsMain