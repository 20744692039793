export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ADMIN_BASE_URL = process.env.REACT_APP_BASE_URL + "/admin"





export interface ApiResponse {
    code: number;
    success: boolean;
    data: any
    status?:number
}

