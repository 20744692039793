import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Products";


export function allProductsRequest(query: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_PRODUCTS}`).then((d: AxiosResponse) => d.data)
}


export function allProductsByManufacturerIdRequest(id: string, query: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_PRODUCTS_BY_MANUFACTURER_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


export async function createProductApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.CREATE_PRODUCTS}`, formData).then((d: AxiosResponse) => d.data);
}

export async function updateProductByIDApiRequest(product_id: any, formData: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.UPDATE_PRODUCTS}/${product_id}`, formData).then((d: AxiosResponse) => d.data);
}


export async function getProductDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_DETAILS_PRODUCTS_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}


export async function getProductSummaryApiRequest(query: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.PRODUCTS_SUMMARY}?${query}`).then((d: AxiosResponse) => d.data);
}

