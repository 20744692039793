import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchAsync } from 'src/helpers';
import { dashboard_columns } from 'src/modules/Projects/AllProjects/Table/components/dashboard_columns';
import { DataTable } from 'src/modules/Projects/AllProjects/Table/components/data-table';
import { APP_ROUTES } from 'src/navigations/modules';
import { allProjectsApiRequest } from 'src/services/requests/Projects';
type Props = {}

const AdminProjectHistory = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const [tableData, settableData] = useState([])

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return allProjectsApiRequest()
        },
        (result: AxiosResponse) => {
            settableData(result.data)
        }
    );

    return (
        <div>
            <div className='flex justify-between my-2 mx-4'>
                <div className='text-primary uppercase font-bold text-sm'>Project History</div>
                <div className='text-gray-500 text-sm cursor-pointer hover:text-primary'><button onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)} >View more</button></div>
            </div>
            <div>
                <DataTable data={tableData || []} columns={dashboard_columns} />
            </div>
        </div>
    )
}

export default AdminProjectHistory