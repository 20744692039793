import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import RooftopReviewForm from 'src/modules/Services/Rooftop/RooftopReviewForm'
import { ApiResponse } from 'src/services/consts/api_const'
import { getLandRegistrationDetailApiRequest } from 'src/services/requests/LandRegistration'
import { getRooftopDetailByIDApiRequest } from 'src/services/requests/Rooftop'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const RooftopViewPage = (props: Props) => {
    const [formData, setformData] = useState(rooftop_init)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchSalesExecutiveById()
    return () => { }
  }, [id])

  const handleFetchSalesExecutiveById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await getRooftopDetailByIDApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <RooftopReviewForm data={formData} />
      <div className='py-2 flex justify-center'>
        <RoundedButton  variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
      </div>
    </div>
  )
}

export default RooftopViewPage