import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/QuotationRequests";


export function allQuotationRequestsRequest(query:string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.GET_ALL_QUOTATION_REQUESTS}`).then((d: AxiosResponse) => d.data)
}

export async function createQuotationRequestApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.CREATE_QUOTATION_REQUEST}`, formData).then((d: AxiosResponse) => d.data);
}

export async function updateQuotationRequestByIDApiRequest(quotationRequest_id:any,formData: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.UPDATE_QUOTATION_REQUEST_DETAILS}/${quotationRequest_id}`, formData).then((d: AxiosResponse) => d.data);
}


export async function getQuotationRequestDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_QUOTATION_REQUEST_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}


export async function getQuotationRequestSummaryApiRequest(query: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_QUOTATION_REQUEST_SUMMARY}?${query}`).then((d: AxiosResponse) => d.data);
}
