import { Eye, Pencil } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROLES, STATUS } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import ToolTip from 'src/shadcn/components/custom/ToolTip'
import { Button } from 'src/shadcn/components/ui'

type Props = {
    row: any
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()

    
    const editPath = useMemo(() => {
        if (currentUser?.role == ROLES.EPC_CONTRACTOR) {
            return "/" + EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes.edit.fullRoute+ "/" + row?._id
        }
        else {
            return "/" + APP_ROUTES.sales_executive.subRoutes.users.subRoutes.edit.fullRoute + "/" + row?._id
        }
    }, [currentUser])


    const viewPath = useMemo(() => {
        if (currentUser?.role == ROLES.EPC_CONTRACTOR) {
            return "/" + EPC_CONTRACTORS_ROUTES.sales_executive.subRoutes.view.fullRoute+ "/" + row?._id
        }
        else {
            return "/" + APP_ROUTES.sales_executive.subRoutes.users.subRoutes.view.fullRoute + "/" + row?._id
        }
    }, [currentUser])


    return (
        <div className='flex space-x-2'>

            {  row.status != STATUS.New && <ToolTip tooltipContent='Edit project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate(editPath)} >
                    <Pencil size="16" />
                </Button>
            </ToolTip>}

            <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate(viewPath)} >
                    <Eye size="16" />
                </Button>
            </ToolTip>
        </div>
    )
}

export default Actions