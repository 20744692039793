
import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/EPC/profile";
import { createEPCContractorsRequestBody } from "./modules";
import { log } from "console";


const delay = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));

export async function epcCreateProfileApiRequest(formData: any): Promise<AxiosResponse> {
    let apiResp = axiosInstance.post(`${API.URL_CREATE_PROFILE}`, formData).then((d: AxiosResponse) => d.data);
    return apiResp
}

export async function epcUpdateProfileApiRequest(formData: any): Promise<AxiosResponse> {
    let apiResp = axiosInstance.put(`${API.URL_UPDATE_PROFILE}`, formData).then((d: AxiosResponse) => d.data);
    return apiResp
}


export async function epcAddServicesApiRequest(formData: any): Promise<AxiosResponse> {
    let apiResp = axiosInstance.put(`${API.URL_ADD_SERVICE}`, formData).then((d: AxiosResponse) => d.data);
    return apiResp
}


export async function epcRemoveServicesApiRequest(formData: any): Promise<AxiosResponse> {
    let apiResp = axiosInstance.put(`${API.URL_REMOVE_SERVICE}`, formData).then((d: AxiosResponse) => d.data);
    return apiResp
}


export function epcAllContractorsApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_ALL_EPC_CONTRACTORS}`).then((d: AxiosResponse) => d.data);
}


export function epcViewProfileByIDApiRequest(id: string): Promise<any> {
    return axiosInstance.get(`${API.URL_VIEW_PROFILE}/${id}`).then((d: AxiosResponse) => d.data);
}
