import { Image, Pen, Plus, Trash2, X } from "lucide-react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { cn, convertToCamelCase } from "src/helpers";
import { Button, Input, Label } from "src/shadcn/components/ui";
import DocumentViwer from "../DocumentViwer";

import { ClassNameValue } from "tailwind-merge";


interface FileObject {
  fileNumber: number
  file: File | null;
  url: string;
}

const INIT_File_Object = [{
  file: null,
  url: "",
  fileNumber: 1
}]

interface MultiFilePickerProps {
  value: FileObject[];
  label: string;
  required?: boolean;
  onChange: (e: any) => void,
  labelStyle?: ClassNameValue
}

const MultiFilePicker: React.FC<MultiFilePickerProps> = ({ value, onChange, label, required, labelStyle }) => {
  const [files, setFiles] = useState<FileObject[]>(INIT_File_Object);

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (typeof value == "string") {
      let prepareObj = [{
        file: null,
        url: value,
        fileNumber: 1
      }]
      setFiles(prepareObj)
    }
    else if (value && Array.isArray(value) && value.length > 0) {
      let prepareObj = value.map((v: any, indx: number) => ({ file: v instanceof File || (typeof v == "object" && v?.name) ? v : null, url: typeof v == "string" ? v : "", fileNumber: indx + 1 }))
      prepareObj = [...prepareObj]
      setFiles(prepareObj)
    }
    return () => { }
  }, [value])


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = [...files];
    newFiles.push({ file: event.target.files ? event.target.files[0] : null, url: '', fileNumber: newFiles.length + 1 });
    setFiles(newFiles);
    onChange(newFiles.filter((file:FileObject) => file.file || file.url).map((file: FileObject) => {
      if (file.file) {
        return file.file
      }
      else if (file.url) {
        return file.url
      }
      
    }))
  };


  const handleRemoveFile = (index: number) => {
    const newFiles = files.filter((file: any, i) => file.fileNumber != index);
    setFiles(newFiles.length == 0 ? INIT_File_Object : newFiles);
    onChange(newFiles.map((file: FileObject) => {
      if (file.file) {
        return file.file
      }
      else if (file.url) {
        return file.url
      }
      else return null
    }))
    // onChange(newFiles.filter((file: FileObject) => file.file)?.map((f: FileObject) => f.file))
  };

  // console.log({ files, value });


  return (
    <div className="space-y-2">
      <Input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        className="border-none"
        accept="image/png, image/gif, image/jpeg, application/pdf,.csv,video/*"
        onChange={(event) => handleFileChange(event)}
      />
      <div className="flex justify-between items-center">
        <Label className={cn(` text-xs `, labelStyle)}>{label} {required ? <span className="text-red-600 font-semibold">*</span> : null} </Label>
        <div className="flex items-center text-xs text-primary font-semibold space-x-2 cursor-pointer"><button type="button" className="flex items-center space-x-2" onClick={() => inputRef.current?.click()}><Plus size={16} /><span>Add File</span></button></div>
      </div>

      {
        files.every(obj => obj.file === null && obj.url === "") ? <div className="w-full p-4 border bg-slate-100 flex justify-center items-center">
          <button  type="button"  onClick={() => inputRef.current?.click()}>
            <div className="flex flex-col items-center justify-center">
              <div className="text-gray-500">No File Selected</div>
              <div className="flex space-x-1 items-center text-xs"><Plus size={16} /><span>Please add files</span></div>
            </div>
          </button>
        </div> : <div className="grid grid-cols-5 gap-2">
          {files.filter(obj => obj.file != null || obj.url != "").map((fileObj, index) => (
            <div key={index} className="flex space-x-2" style={{ marginBottom: '10px' }}>
              {fileObj.url && !fileObj.file ? <div className="w-full bg-gray-200 rounded-lg">
                <div className=" flex w-full items-center bg-gray-200 p-2 rounded-lg cursor-pointer space-x-2">
                  {/* <Image size={24} strokeWidth={"1.25px"} className="text-blue-600" /> */}
                  <div className="w-[80%] flex items-center">
                    <div className="w-full text-nowrap text-ellipsis overflow-hidden"><DocumentViwer url={fileObj.url} title={"Attachment " + (index + 1)} /></div>
                    {/* <div className=" text-nowrap text-ellipsis overflow-hidden">.{fileObj.file.type.split("/")[1].toLowerCase()}</div> */}
                  </div>
                  <X size={24} strokeWidth={"1.5px"} className="" onClick={() => handleRemoveFile(index + 1)} />
                </div>
              </div> : null}

              {fileObj.file && !fileObj.url ? <div className=" flex w-full items-center bg-gray-200 p-2 rounded-lg cursor-pointer space-x-2">
                {/* <Image size={24} strokeWidth={"1.25px"} className="text-blue-600" /> */}
                <div className="w-[80%] flex items-center">
                  <div className="w-full text-nowrap text-ellipsis overflow-hidden">{fileObj.file.name}</div>
                  {/* <div className=" text-nowrap text-ellipsis overflow-hidden">.{fileObj.file.type.split("/")[1].toLowerCase()}</div> */}
                </div>
                <X size={24} strokeWidth={"1.5px"} className="" onClick={() => handleRemoveFile(index + 1)} />

              </div> : null}

              {fileObj.file && fileObj.url ? <div className="w-100 bg-gray-200 p-2 rounded-lg">
                {fileObj.file.name}
              </div> : null}
            </div>
          ))}
        </div>
      }
    </div>

  );
};


export default MultiFilePicker;
