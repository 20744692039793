import { Link } from 'react-router-dom'
import { componentType, FormField } from 'src/helpers'
import { Label } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { LAND_ONBOARD_FORM_FIELDS, landOnboardFormStructure } from './modules'
import { ImageOff } from 'lucide-react'


type Props = {
    data: any
}


const RenderViewComponent = ({ label, value, type }: { label: string, type: componentType, value: string | number | undefined }) => {
    return <div className='flex flex-col border-b'>
        <Label className='mb-1 uppercase text-xs  text-gray-500'>{label}</Label>
        <div className='font-semibold mb-1  '>
            {
                value ? <>
                    {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{value}</div>}
                    {type == "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                </> : "-"
            }
        </div>
    </div>
}

const LandOnboardReviewForm = ({ data }: Props) => {

    return (
        <div className=' '>
            <div className='grid grid-cols-6'>
                <ScrollArea className='col-span-4 h-[80vh] px-4 bg-gray-50'>
                    {
                        Object.entries(landOnboardFormStructure).map(([key, value]) => {
                            return <div key={`landOnboardFormStructure-${key}`} className='mb-2' >
                                <div className='text-primary uppercase'>{key}</div>
                                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                                    {
                                        Object.keys(value).map((k: any) => {
                                            const formfield: FormField | undefined = LAND_ONBOARD_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                            if (formfield?.componentProps?.fileSelectionType == "multi") {
                                                return <div className='col-span-3'>
                                                    {
                                                        data[formfield?.dataKey] && Array.isArray(data[formfield?.dataKey]) && data[formfield?.dataKey].length > 0 ?
                                                            data[formfield?.dataKey].map((d: any) => {
                                                                return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={d} type={formfield?.componentType} />
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            }
                                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </ScrollArea>
                <div className='col-span-2 p-4'>
                    {
                        data.imgUrl ?
                            <img src={data.imgUrl} className='mx-auto cursor-pointer rounded-xl ' style={{ width: "300px", height: "300px" }} />
                            : <div className='space-y-2'>
                                <div className='w-[300px] h-[300px] bg-gray-100 mx-auto rounded-xl'>
                                    <div className='w-full h-full flex justify-center items-center'>
                                        <div className='flex flex-col items-center space-y-2'>
                                            <ImageOff size={32} className='text-gray-400' />
                                            <span className='text-sm text-gray-600'> No Content</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default LandOnboardReviewForm