import { AxiosResponse } from 'axios'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, cn, IS_SUCCESS_RESPONSE, ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { PROJECT_FORM_INITIAL_VALUES } from 'src/modules/Projects/modules'
import ReviewProjectForm from 'src/modules/SalesExecutive/projects/ReviewSiteDetailsForm'
import { APP_ROUTES } from 'src/navigations/modules'
import { approveProjectByAdminApiRequest, getProjectsDetailsByIDApiRequest } from 'src/services/requests/Projects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Label, Textarea } from 'src/shadcn/components/ui'

type Props = {}

const ViewProjectMain = (props: Props) => {

  const [projectDetails, setprojectDetails] = useState(PROJECT_FORM_INITIAL_VALUES)
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { id } = useParams()
  const [fetchingProjectDetails, setfetchingProjectDetails] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [commentField, setCommentField] = useState<{
    comment: string,
    isError: boolean,
    message: string
  }>({
    comment: "",
    isError: false,
    message: ""
  })
  const [projectData, setprojectData] = useState<any>({})

  useEffect(() => {
    if (id) handleFetchProjectDetails()
    else navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)
    return () => { }
  }, [])

  const handleFetchProjectDetails = catchAsync(async () => {
    setfetchingProjectDetails(true)
    return id && await getProjectsDetailsByIDApiRequest(id)
  }, (result: AxiosResponse) => {
    console.log({ getProjectDetailsByID: result });
    // const projectData = result.data.find((d: any) => d._id == id)
    setprojectData(result.data)
    setfetchingProjectDetails(false)
  }, () => {
    setfetchingProjectDetails(false)
  })

  const handleProjectStatusSubmit = catchAsync(async (status: string) => {
    setisLoading(true)
    return currentUser && await approveProjectByAdminApiRequest({
      status: status
    }, projectData?._id)
  }, (result: any) => {
    setisLoading(false)
    if (IS_SUCCESS_RESPONSE(result)) {
      handleFetchProjectDetails()
      navigate(-1)
    }
  }, () => {
    setisLoading(false)
  })

  console.log({ projectData });


  return (
    <div className='m-4 h-full'>

      <div >
        <ReviewProjectForm data={projectData} classNames={!projectData?.status || projectData?.status == "New" ? "h-[65vh]" : "h-[80vh]"} />
      </div>
      {/* {
        !projectData?.status || projectData?.status == "New" ?
          <div className='flex flex-col space-y-2'>
            <Label className='uppercase font-semibold text-sm my-2'>Remark</Label>
            <Textarea placeholder='Enter remark here' rows={2} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setCommentField({
              ...commentField, comment: e.target.value, isError: false, message: ""
            })} />
            <div className='text-destructive'>{commentField.isError ? commentField.message : null}</div>
          </div> : null
      } */}
      <div className='flex space-x-2 justify-center my-4'>
        <RoundedButton className='my-2' onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
        {/* {!projectData?.status || projectData?.status == "New" &&
          <div className='flex space-x-2'>
            <RoundedButton disabled={projectData?.status?.toLowerCase() !== "new"} isLoading={isLoading} className='my-2' variant={'secondary'} onClick={() => {

              if (!commentField.comment) {
                setCommentField({
                  ...commentField,
                  isError: true,
                  message: "Comment is required"
                })
              }
              else handleProjectStatusSubmit("Reject")


            }} >Reject</RoundedButton>
            <RoundedButton disabled={projectData?.status?.toLowerCase() !== "new"} isLoading={isLoading} className='my-2' onClick={() => {
              if (!commentField.comment) {
                setCommentField({
                  ...commentField,
                  isError: true,
                  message: "Comment is required"
                })
              }
              else handleProjectStatusSubmit("Approved")

            }} >Approve</RoundedButton>
          </div>
        } */}
        <LoadingDialog isOpen={fetchingProjectDetails} />
      </div>
      {/* <div className='flex space-x-2 justify-center my-4'>
        <RoundedButton className='my-2' onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
        <LoadingDialog isOpen={fetchingProjectDetails} />
      </div> */}
    </div >
  )
}

export default ViewProjectMain