import React, { useState, createContext, FC, useContext, ReactNode } from 'react';
import { } from 'src/helpers';
import { ILayoutSize, LayoutContextProps, initialLayoutContext } from './modules';

const LayoutMainContext = createContext<LayoutContextProps>(initialLayoutContext)

const LayoutMainContextProvider: FC<{
    children: ReactNode
}> = ({ children }) => {

    const [isCollapsedSidebar, setIsCollapsedSidebar] = useState<boolean>(false)
    const [defaultLayout, setDefaultLayout] = useState<ILayoutSize>(20)

    const setIsCollapsed = (e: boolean) => {
        setIsCollapsedSidebar(e)
    }

    const setLayout = (e: ILayoutSize) => {
        setDefaultLayout(e)
    }

    return (
        <LayoutMainContext.Provider value={{ isCollapsedSidebar, setIsCollapsed, defaultLayout, setLayout }}>
            {children}
        </LayoutMainContext.Provider>
    )
}

const useLayoutMainContext = () => useContext(LayoutMainContext)
export { LayoutMainContextProvider, useLayoutMainContext }