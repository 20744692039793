import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import { APP_ROUTES } from 'src/navigations/modules'
import AllOrdersMainPage from './AllOrders/AllOrdersMainPage'

type Props = {}

const AdminOrderRoutes = (props: Props) => {
    return (
        <Routes>
            <Route element={<div className='p-4'><Outlet /></div>} >
                <Route index element={<AllOrdersMainPage />} />
                <Route path={APP_ROUTES.orders.subRoutes.all.route + "/*"} element={<AllOrdersMainPage />} />
               
                {/* {
              Object.entries(ROUTES).map(([key, value], index) => {
                return <RenderRoutes route={value as any} role={userRole} />
              })
            } */}
            </Route>
        </Routes>
    )
}

export default AdminOrderRoutes