import React, { useEffect, useState } from 'react'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { ORDER_ANALYSIS_DATA, QUOTATION_REQUESTS_ANALYSIS_DATA } from './modules'
import { getOrderSummaryApiRequest } from 'src/services/requests/Orders'
import AnalysisTiles from '../Dashboard/AnalysisTiles'

type Props = {}

const QuotationRequestsAnalysis = (props: Props) => {

    const [data, setdata] = useState<any>({
        "totalRequests": 0,
        "totalInterestedUsers": 0,
    })
    const [analysisData, setAnalysisData] = useState<any>({})

    useEffect(() => {
        handleFetchSummaryDetails()
        return () => { }
    }, [])

    const handleFetchSummaryDetails = catchAsync(async () => {
        return await getOrderSummaryApiRequest("")
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setdata(result.data[0])
        }
    }, () => { })

    return (
        <div className="grid gap-4 md:grid-cols-4 lg:grid-cols-4 mb-2">
            {
                QUOTATION_REQUESTS_ANALYSIS_DATA.map((d: any) => {
                    console.log({d});
                    let newValue = data[d.dataKey] || 0
                    if (d?.dataKey?.includes(".")) {
                        let [key, subKey] = d.dataKey.split(".")
                        newValue = data[key][subKey]
                    }

                    return <AnalysisTiles {...d} value={newValue} />
                    // return <></>
                })

            }
        </div>
    )
}

export default QuotationRequestsAnalysis