import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { catchAsync } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { columns } from 'src/modules/SalesExecutive/projects/AllProjects/Table/components/columns'
import { DataTable } from 'src/modules/SalesExecutive/projects/AllProjects/Table/components/data-table'
import { allSalesExecutiveProjectsApiRequest } from 'src/services/requests/SalesExecutve'


type Props = {}


const Main = () => {


    const [tableData, settableData] = useState<any[]>([])
    const { currentUser } = useAuth()

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return allSalesExecutiveProjectsApiRequest()
        },
        (result: AxiosResponse) => {
            if (result.data && Array.isArray(result.data)) {
                settableData(result.data.map((d: any, indx: number) => {
                    return {
                        srNo: indx + 1,
                        ...d,
                    }
                }))
            }
        }
    );


    return <DataTable data={tableData || []} columns={columns} />
}

const AllProjectsTable = (props: Props) => {
    return (
        <Main />
    )
}

export default AllProjectsTable