import { AtSign, KeyRound } from "lucide-react"
import { FormField } from "src/helpers"
import * as Yup from "yup"

export interface IProfile {
    email: string,
    role: string,
}

export const ProfileInit = {
    email: "",
    role: "",
}

export interface IChangePassword {
    password: string
    confirm_password: string
    email: string,
}

export const changePassword_initialValues: IChangePassword = {
    email: '',
    password: 'Pass121@',
    confirm_password: "Pass121@",
};

export const profileFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required')
        .max(50, 'First name must be 50 characters or less'),
    lastName: Yup.string()
        .required('Last name is required')
        .max(50, 'Last name must be 50 characters or less'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    mobileNumber: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    aboutMe: Yup.string()
        .required('About me is required')
        .max(200, 'About me must be 200 characters or less'),
});


export const changePasswordFormValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    confirm_password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required')
        .test('passwords-match', 'Passwords not match', function (value) {
            return this.parent.password === value;
        }),
});

export const PROFILE_FORM_FIELDS: FormField[] = [
   
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter email',
        }
    },
    {
        label: 'Role',
        dataKey: 'role',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter Role',
            disabled:true
        }
    },
   
];

export const CHANGE_PASSWORD_FORM_FIELDS: FormField[] = [
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            inputPrefixIcon: <AtSign className='text-gray-500' size={16} />
        }
    },
    {
        label: 'Password',
        dataKey: 'password',
        componentType: 'input',
        componentProps: {
            type: 'password',
            inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
        }
    },
    {
        label: 'Confirm Password',
        dataKey: 'confirm_password',
        componentType: 'input',
        componentProps: {
            type: 'password',
            inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
        }
    }
];