import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES, MANUFACTURER_ROUTES } from 'src/navigations/modules'
import AllManufacturerMain from './AllManufacturers/AllManufacturerMain'
import ManufacturerCreateMain from './ManufacturerProfile/ManufacturerCreateMain'
import ManufacturerViewMain from './ManufacturerProfile/ManufacturerViewMain'
import ManufacturerUpdateMain from './ManufacturerProfile/ManufacturerUpdateMain'

type Props = {}

const AdminManufacturerRoutes = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.manufacturers.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<AllManufacturerMain />} />
        <Route path={PROJECTS_ROUTES.create.route} element={<ManufacturerCreateMain />} />
        <Route path={PROJECTS_ROUTES.edit.route + "/:id"} element={<ManufacturerUpdateMain />} />
        <Route path={PROJECTS_ROUTES.view.route + "/:id"} element={<ManufacturerViewMain />} />
        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.all.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default AdminManufacturerRoutes