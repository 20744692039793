
import React, { useCallback, useMemo, useRef } from "react";
import { VariableSizeList as List } from "react-window";
import { Check, CheckIcon } from "lucide-react";
import { useWindowResize } from "./useWindowResize";
import { useMainContext } from "./MainContext";
import { cn, optionTypes, toAbsoluteUrl } from "src/helpers";

type Props = {
    data: optionTypes[];
    onchange: (e: any) => void;
    selectedValues: optionTypes | undefined;
    width?: number;
};

const Row = ({ data, index, setSize, windowWidth, onchange, selectedValues }: {
    data: optionTypes[], index: number, setSize: any, windowWidth: any, onchange: (e: optionTypes) => void, selectedValues: optionTypes | undefined
}) => {

    const rowRef: any = useRef();
    const isEven = index % 2 === 0;
    const { toggleValue } = useMainContext();

    React.useEffect(() => {
        setSize(index, rowRef.current.getBoundingClientRect().height);
    }, [setSize, index, windowWidth]);

    let selected = useMemo(() => selectedValues && selectedValues.value == data[index].value, [selectedValues, data])

    // let IConComponent = useMemo(() => data[index].icons ? data[index].icons : null, [data[index].icons])

    return (
        <div
            key={'multiselect' + index}
            ref={rowRef}
            className={cn(" cursor-pointer", {
            })}
            onClick={() => {
                onchange(data[index])
                toggleValue(data[index])
            }}
        >
            <div className={cn("flex items-center  p-2 hover:bg-gray-50 rounded-md", { 'bg-orange-50 text-primary ': selectedValues && selectedValues && !!selected })}>
                <CheckIcon
                    className={cn(
                        "mr-2 h-4 w-4",
                        selectedValues && selectedValues && !!selected ? "opacity-100" : "opacity-0"
                    )}
                />
                {data[index].icons && (
                    <span className="me-2">
                        {data[index].icons}
                    </span>
                )}
                <span className="text-sm">{data[index].label}</span>
            </div>

        </div>
    );
};

const VirtualListFormMultiSelect = ({ data, onchange, selectedValues, width }: Props) => {
    const listRef = useRef<any>(null);
    const newdata = useMemo(() => data, [data]);
    const sizeMap = useRef<any>({});
    const setSize = useCallback((index: any, size: any) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);

    const getSize = (index: any) => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();


    // Calculate the total height based on all items
    const totalHeight = newdata.reduce((sum, _, index) => sum + getSize(index), 0);

    // Set the actual height for the list, but not exceeding 400px
    const listHeight = Math.min(totalHeight, 200);

    return (
        <List
            ref={listRef}
            height={listHeight}
            width={width || "100%"}
            itemCount={data.length}
            itemSize={getSize}
            itemData={data}
            className=""
        >
            {({ data: item, index, style }) => (
                <div style={style}>
                    <Row
                        data={item}
                        index={index}
                        setSize={setSize}
                        windowWidth={windowWidth}
                        onchange={onchange}
                        selectedValues={selectedValues}
                    />
                </div>
            )}
        </List>
    );
};

export default VirtualListFormMultiSelect;
