import { Navigate, Route, Routes } from 'react-router-dom'
import { IRoutes } from 'src/helpers/routes'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import DashboardMain from 'src/pages/Dashboard/DashboardMain'
import ManufacturerProfileRoutes from 'src/pages/Manufacturer/ManufacturerProfile/ManufacturerProfileRoutes'
import { MANUFACTURER_ROUTES } from './modules'
import ManufacturerProductRoutes from 'src/pages/Manufacturer/Products/ManufacturerProductRoutes'

type Props = {}

type RenderRoutesProps = {
  route: IRoutes,
  role: string
}

const ManufacturerRoutes = (props: Props) => {

  return (
    <Routes>
      <Route element={<AppLayoutMain />} >
        <Route index element={<DashboardMain />} />
        <Route path={MANUFACTURER_ROUTES.dashboard.route} element={<DashboardMain />} />
        <Route path={MANUFACTURER_ROUTES.products.route + "/*"} element={<ManufacturerProductRoutes />} />
        <Route path={MANUFACTURER_ROUTES.profile.route + "/*"} element={<ManufacturerProfileRoutes />} />
        {/* {
          Object.entries(ROUTES).map(([key, value], index) => {
            return <RenderRoutes route={value as any} role={userRole} />
          })
        } */}
      </Route>
    </Routes>
  )
}

export default ManufacturerRoutes