import React from 'react'
import ForgotPassword from 'src/modules/Auth/ForgotPassword'

type Props = {}

const ForgotPasswordMain = (props: Props) => {
  return (
    <div>
      <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordMain