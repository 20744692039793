import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/ProjectRights";


export function createProjectRightsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`${API.PROJECT_RIGHTS_BASE_URL}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function allProjectRightsRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_PROJECT_RIGHTS_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export function updateProjectRightsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.UPDATE_PROJECT_RIGHTS_REQUEST}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function getProjectRightsDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_RIGHTS_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


