import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/LandRegistration";


export function createLandRegistrationApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`${API.LAND_REGISTRATION_BASE_URL}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function allLandRegistrationRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_LAND_REGISTRATION_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export function updateLandRegistrationApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.UPDATE_LAND_REGISTRATION_REQUEST}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function getLandRegistrationDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.LAND_REGISTRATION_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


