import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Projects";
import { IEvaluateProjectRequestBody, IVoteProjectRequestBody } from "./module";


export function createProjectApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.post(API.CREATE_PROJECT, formData).then((d: AxiosResponse) => d.data);
}

export function allProjectsApiRequest(query?: string): Promise<any> {
    return axiosInstance.get(`${API.GET_PROJECTS}`).then((d: AxiosResponse) => d.data);
}

export function getProjectsDetailsByIDApiRequest(id: string, query?: string): Promise<any> {
    return axiosInstance.get(`${API.GET_PROJECT_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}

export function updateProjectsDetailsByIDApiRequest(id: string, data: any): Promise<any> {
    return axiosInstance.put(`${API.UPDATE_PROJECT_DETAILS}/${id}`, { ...data }).then((d: AxiosResponse) => d.data);
}

export function getProjectCalculationApiRequest(data: any): Promise<any> {
    return axiosInstance.post(`${API.GET_PROJECT_CALCULATIONS}`, { ...data }).then((d: AxiosResponse) => d.data);
}


export function approveProjectByEvaluatorApiRequest(data: IEvaluateProjectRequestBody): Promise<any> {
    return axiosInstance.put(`${API.APPROVE_PROJECT_EVALUATOR}`, { ...data }).then((d: AxiosResponse) => d.data);
}


export function approveProjectByAdminApiRequest(data: any, id: string): Promise<any> {
    return axiosInstance.put(`${API.APPROVE_PROJECT_ADMIN}/${id}`, { ...data }).then((d: AxiosResponse) => d.data);
}


export function approveProjectByVoterApiRequest(data: IVoteProjectRequestBody): Promise<any> {
    return axiosInstance.post(`${API.APPROVE_PROJECT_STAKEHOLDER}`, { ...data }).then((d: AxiosResponse) => d.data);
}

export function generateAgreementApiRequest(id: any): Promise<any> {
    return axiosInstance.get(`${API.GENERATE_AGREEMENT}/${id}`).then((d: AxiosResponse) => d.data);
}