

export const MAIN_ROUTES = {
    welcome: "welcome",
    auth: "auth",
    login: "login",
    signup: "signup",
    forgot_password: "forgot-password",
    verify_otp: "verify-otp",
    reset_password: "reset-password",
    auth_login: "/auth/login",
    auth_signup: "/auth/signup",
    auth_forgot_password: "/auth/forgot-password",
    auth_verify_otp: "/auth/verify-otp",
    auth_reset_password: "/auth/reset-password",
}

export const APP_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    epc_contractors: {
        route: "epc-contractors",
        routeType: "static",
        fullRoute: "epc-contractors",
        breadcrumbTitle: "EPC-Contractors",
        breadcrumbPath: "EPC-Contractors /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "epc-contractors/create",
                breadcrumbTitle: "Create EPC Contractor",
                breadcrumbPath: "EPC-contractors / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "epc-contractors/edit",
                breadcrumbTitle: "Edit EPC Contractor",
                breadcrumbPath: "EPC-contractors / edit",
            },
            view: {
                route: "/view-details",
                routeType: "dynamic",
                fullRoute: "epc-contractors/view-details",
                breadcrumbTitle: "View EPC Contractor Details",
                breadcrumbPath: "EPC-contractors / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "epc-contractors/",
                breadcrumbTitle: "EPC Contractors",
                breadcrumbPath: "EPC-contractors /",
            }
        }
    },
    manufacturers: {
        route: "manufacturers",
        routeType: "static",
        fullRoute: "manufacturers",
        breadcrumbTitle: "Manufacturers",
        breadcrumbPath: "Manufacturers /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "manufacturers/create",
                breadcrumbTitle: "Create Manufacturer",
                breadcrumbPath: "Manufacturer / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "manufacturers/edit",
                breadcrumbTitle: "Manufacturer Project",
                breadcrumbPath: "Manufacturer / edit",
            },
            view: {
                route: "/view-details",
                routeType: "dynamic",
                fullRoute: "manufacturers/view-details",
                breadcrumbTitle: "View Manufacturer Details",
                breadcrumbPath: "Manufacturer / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "manufacturers/",
                breadcrumbTitle: "Manufacturers",
                breadcrumbPath: "Manufacturer /",
            }
        }
    },
    projects: {
        route: "projects",
        routeType: "static",
        fullRoute: "projects",
        breadcrumbTitle: "Projects",
        breadcrumbPath: "Projects /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "projects/create",
                breadcrumbTitle: "Create Project",
                breadcrumbPath: "Projects / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Projects / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "projects/view",
                breadcrumbTitle: "View Project Details",
                breadcrumbPath: "Projects / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "projects/",
                breadcrumbTitle: "Projects",
                breadcrumbPath: "Projects /",
            }
        }
    },
    sales_executive: {
        route: "sales-executive",
        routeType: "static",
        fullRoute: "sales-executive",
        breadcrumbTitle: "Sales Executive",
        breadcrumbPath: "Sales Executive /",
        subRoutes: {
            users: {
                route: "user",
                routeType: "static",
                fullRoute: "sales-executive/user",
                breadcrumbTitle: "Sales Executives Users",
                breadcrumbPath: "Sales Executive / Users",
                subRoutes: {
                    create: {
                        route: "create",
                        routeType: "static",
                        fullRoute: "sales-executive/user/create",
                        breadcrumbTitle: "Create Sales Executive",
                        breadcrumbPath: "Sales Executive / Create",
                    },
                    edit: {
                        route: "/edit",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/user/edit",
                        breadcrumbTitle: "Edit Sales Executive",
                        breadcrumbPath: "Sales Executive / Edit",
                    },
                    view: {
                        route: "/view",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/user/view",
                        breadcrumbTitle: "View Sales Executive",
                        breadcrumbPath: "Sales Executive / View",
                    },
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/user",
                        breadcrumbTitle: "Sales Executives",
                        breadcrumbPath: "Sales Executive / ",
                    },
                }
            },
            projects: {
                route: "projects",
                routeType: "static",
                fullRoute: "sales-executive/projects",
                breadcrumbTitle: "Sales Executives Projects",
                breadcrumbPath: "Sales Executive / Projects",
                subRoutes: {
                    create: {
                        route: "/create",
                        routeType: "static",
                        fullRoute: "sales-executive/projects/create",
                        breadcrumbTitle: "Create Project",
                        breadcrumbPath: "Sales Executive / Projects / Create",
                    },
                    edit: {
                        route: "/edit",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/projects/edit",
                        breadcrumbTitle: "Edit Project",
                        breadcrumbPath: "Sales Executive / Projects / Edit",
                    },
                    view: {
                        route: "/view",
                        routeType: "dynamic",
                        fullRoute: "sales-executive/projects/view",
                        breadcrumbTitle: "View Project Details",
                        breadcrumbPath: "Sales Executive / Projects / View",
                    },
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/projects",
                        breadcrumbTitle: "Surveyors Projects",
                        breadcrumbPath: "Sales Executive / Projects /",
                    }
                }
            },
            reports: {
                route: "reports",
                routeType: "static",
                fullRoute: "sales-executive/reports",
                breadcrumbTitle: "Sales Executives Reports",
                breadcrumbPath: "Sales Executive / Reports",
                subRoutes: {
                    all: {
                        route: "/",
                        routeType: "static",
                        fullRoute: "sales-executive/reports",
                        breadcrumbTitle: "Surveyors reports",
                        breadcrumbPath: "Sales Executive / Reports /",
                    }
                }
            }
        }
    },
    investors: {
        route: "investors",
        routeType: "static",
        fullRoute: "investors",
        breadcrumbTitle: "Investor",
        breadcrumbPath: "Investor /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "investors/view",
                breadcrumbTitle: "View Investor",
                breadcrumbPath: "Investor / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "investors/",
                breadcrumbTitle: "Investors",
                breadcrumbPath: "Investor / ",
            },
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: undefined
    },
    orders: {
        route: "order",
        routeType: "static",
        fullRoute: "order",
        breadcrumbTitle: "Order",
        breadcrumbPath: "Order /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "order/view",
                breadcrumbTitle: "View Order Details",
                breadcrumbPath: "Order / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "order/",
                breadcrumbTitle: "Order",
                breadcrumbPath: "Order / ",
            },
        }
    },
    landOnboard: {
        route: "land-onbording",
        routeType: "static",
        fullRoute: "land-onbording",
        breadcrumbTitle: "Land Onbording Requests",
        breadcrumbPath: "Land Onbording Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "land-onbording/create",
                breadcrumbTitle: "Create Land Onbording Request",
                breadcrumbPath: "Land Onbording Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "land-onbording/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Land Onbording Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "land-onbording/view",
                breadcrumbTitle: "View Land Onbording Request Details",
                breadcrumbPath: "Land Onbording Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "land-onbording/",
                breadcrumbTitle: "Land Onbording Request ",
                breadcrumbPath: "Land Onbording Request  / ",
            },
        }
    },
    rooftop: {
        route: "rooftop",
        routeType: "static",
        fullRoute: "rooftop",
        breadcrumbTitle: "Roof-Top Requests",
        breadcrumbPath: "Roof-Top Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "rooftop/create",
                breadcrumbTitle: "Create Roof-Top Request",
                breadcrumbPath: "Roof-Top Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "rooftop/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Roof-Top Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "rooftop/view",
                breadcrumbTitle: "View Roof-Top Request Details",
                breadcrumbPath: "Roof-Top Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "rooftop/",
                breadcrumbTitle: "Roof-Top Request ",
                breadcrumbPath: "Roof-Top Request  / ",
            },
        }
    },
    newConstructedProjects: {
        route: "new-constructed-projects",
        routeType: "static",
        fullRoute: "new-constructed-projects",
        breadcrumbTitle: "New Constructed Projects Requests",
        breadcrumbPath: "New Constructed Projects Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "new-constructed-projects/create",
                breadcrumbTitle: "Create New Constructed Projects Request",
                breadcrumbPath: "New Constructed Projects Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "new-constructed-projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "New Constructed Projects Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "new-constructed-projects/view",
                breadcrumbTitle: "View New Constructed Projects Request Details",
                breadcrumbPath: "New Constructed Projects Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "new-constructed-projects/",
                breadcrumbTitle: "New Constructed Projects Request ",
                breadcrumbPath: "New Constructed Projects Request  / ",
            },
        }
    },
    projectRights: {
        route: "project-rights",
        routeType: "static",
        fullRoute: "project-rights",
        breadcrumbTitle: "Project Rights Requests",
        breadcrumbPath: "Project Rights Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "project-rights/create",
                breadcrumbTitle: "Create Project Rights Request",
                breadcrumbPath: "Project Rights Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "project-rights/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Project Rights Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "project-rights/view",
                breadcrumbTitle: "View Project Rights Request Details",
                breadcrumbPath: "Project Rights Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "project-rights/",
                breadcrumbTitle: "Project Rights Request ",
                breadcrumbPath: "Project Rights Request  / ",
            },
        }
    },
    sellExistingProjects: {
        route: "sell-existing-projects",
        routeType: "static",
        fullRoute: "sell-existing-projects",
        breadcrumbTitle: "Sell Exiting Projects Requests",
        breadcrumbPath: "Sell Exiting Projects Requests /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "sell-existing-projects/create",
                breadcrumbTitle: "Create Sell Exiting Projects Request",
                breadcrumbPath: "Sell Exiting Projects Request / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "sell-existing-projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Sell Exiting Projects Request / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "sell-existing-projects/view",
                breadcrumbTitle: "View Sell Exiting Projects Request Details",
                breadcrumbPath: "Sell Exiting Projects Request  / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "sell-existing-projects/",
                breadcrumbTitle: "Sell Exiting Projects Request ",
                breadcrumbPath: "Sell Exiting Projects Request  / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
    quotationRequest: {
        route: "quotation",
        routeType: "static",
        fullRoute: "quotation",
        breadcrumbTitle: "Quotation",
        breadcrumbPath: "Quotation /",
        subRoutes: {
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "quotation/view",
                breadcrumbTitle: "View Quotation Details",
                breadcrumbPath: "Quotation / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "quotation/",
                breadcrumbTitle: "Quotation",
                breadcrumbPath: "Quotation / ",
            },
        }
    },

}

export const EPC_CONTRACTORS_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    projects: {
        route: "projects",
        routeType: "static",
        fullRoute: "projects",
        breadcrumbTitle: "Projects",
        breadcrumbPath: "Projects /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "projects/create",
                breadcrumbTitle: "Create Project",
                breadcrumbPath: "Projects / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "projects/edit",
                breadcrumbTitle: "Edit Project",
                breadcrumbPath: "Projects / edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "projects/view",
                breadcrumbTitle: "View Project Details",
                breadcrumbPath: "Projects / view",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "projects/",
                breadcrumbTitle: "Projects",
                breadcrumbPath: "Projects /",
            }
        }
    },
    sales_executive: {
        route: "sales-executive",
        routeType: "static",
        fullRoute: "sales-executive",
        breadcrumbTitle: "Sales Executive",
        breadcrumbPath: "Sales Executive /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "sales-executive/create",
                breadcrumbTitle: "Create Sales Executive",
                breadcrumbPath: "Sales Executive / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "sales-executive/edit",
                breadcrumbTitle: "Edit Sales Executive",
                breadcrumbPath: "Sales Executive / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "sales-executive/view",
                breadcrumbTitle: "View Sales Executive",
                breadcrumbPath: "Sales Executive / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "sales-executive",
                breadcrumbTitle: "Sales Executives",
                breadcrumbPath: "Sales Executive / ",
            },
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: {
            create: {
                route: "create",
                routeType: "static",
                fullRoute: "profile/create",
                breadcrumbTitle: "Complete Profile",
                breadcrumbPath: "EPC Contractor / Complete Profile",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "profile/edit",
                breadcrumbTitle: "Edit EPC Contractor",
                breadcrumbPath: "EPC Contractor / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "profile/view",
                breadcrumbTitle: "View EPC Contractor",
                breadcrumbPath: "EPC Contractor / View",
            },

            all: {
                route: "/",
                routeType: "static",
                fullRoute: "profile",
                breadcrumbTitle: "EPC Contractors",
                breadcrumbPath: "EPC Contractor / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
}

export const MANUFACTURER_ROUTES = {
    dashboard: {
        route: "dashboard",
        routeType: "static",
        fullRoute: "dashboard",
        breadcrumbTitle: "Dashboard",
        breadcrumbPath: "Dashboard /",
        subRoutes: undefined
    },
    products: {
        route: "products",
        routeType: "static",
        fullRoute: "products",
        breadcrumbTitle: "Products",
        breadcrumbPath: "Products /",
        subRoutes: {
            create: {
                route: "/create",
                routeType: "static",
                fullRoute: "products/create",
                breadcrumbTitle: "Create Product",
                breadcrumbPath: "Product / Create",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "products/edit",
                breadcrumbTitle: "Edit Product",
                breadcrumbPath: "Product / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "products/view",
                breadcrumbTitle: "View Product Details",
                breadcrumbPath: "Product / View",
            },
            all: {
                route: "/",
                routeType: "static",
                fullRoute: "products",
                breadcrumbTitle: "Products",
                breadcrumbPath: "Products /",
            }
        }
    },
    profile: {
        route: "profile",
        routeType: "static",
        fullRoute: "profile",
        breadcrumbTitle: "Profile",
        breadcrumbPath: "Profile /",
        subRoutes: {
            create: {
                route: "create",
                routeType: "static",
                fullRoute: "profile/create",
                breadcrumbTitle: "Complete Profile",
                breadcrumbPath: "Manufacturer / Complete Profile",
            },
            edit: {
                route: "/edit",
                routeType: "dynamic",
                fullRoute: "profile/edit",
                breadcrumbTitle: "Edit Manufacturer",
                breadcrumbPath: "Manufacturer / Edit",
            },
            view: {
                route: "/view",
                routeType: "dynamic",
                fullRoute: "profile/view",
                breadcrumbTitle: "View Manufacturer",
                breadcrumbPath: "Manufacturer / View",
            },

            all: {
                route: "/",
                routeType: "static",
                fullRoute: "profile",
                breadcrumbTitle: "Manufacturers",
                breadcrumbPath: "Manufacturer / ",
            },
        }
    },
    changePassword: {
        route: "changePassword",
        routeType: "static",
        fullRoute: "changePassword",
        breadcrumbTitle: "Change Password",
        breadcrumbPath: "Change Password /",
        subRoutes: undefined
    },
}