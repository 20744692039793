import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { EPC_SERVICES_FORM_FIELDS, epcContractorServicesSchema, epcContractorSingleServicesSchema, INIT_EPC_SERVICES } from './modules'
import { Button, Label, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/shadcn/components/ui'
import { catchAsync, checkAnyFileToBeUpload, cn, convertToCamelCase, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { epcAddServicesApiRequest, epcRemoveServicesApiRequest, epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import { AlertTriangle, Trash } from 'lucide-react'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { useNavigate } from 'react-router-dom'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import ConfirmDialog from 'src/shadcn/components/custom/ConformDialog'


type Props = {
    id: string,
    epc_id: string
}


const ServicesFrm = ({ id, epc_id }: Props) => {

    const [formInit, setformInit] = useState(INIT_EPC_SERVICES)
    const [allService, setallService] = useState<any>()
    const [profileDetails, setprofileDetails] = useState<any>({})
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [openConfirmBox, setopenConfirmBox] = useState<boolean>(false)
    const navigate = useNavigate()

    console.log({ id, epc_id  });

    useEffect(() => {
        epc_id && handleFetchUserProfile()
        return () => { }
    }, [epc_id])


    const formik = useFormik({
        initialValues: { ...formInit },
        validationSchema: epcContractorSingleServicesSchema,
        onSubmit(values, formikHelpers) {
            // onSavedClick({ ...values, formId: formdata?.formId })
            handleSubmit()
        },
    })

    const handleSubmit = catchAsync(async () => {
        // setisFileUploading(true)
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            // formik.setValues(result[0])
            handleAddServicesProfile({ ...result[0] })
            console.log({ result: { ...result[0] } });
        }
        setisLoading(false)
        // setisFileUploading(false)

    })

    const handleFetchUserProfile = catchAsync(async () => {
        setisLoading(true)
        return await epcViewProfileByIDApiRequest(epc_id)
    }, (result: any) => {
        console.log({ result,services:result?.data?.services })
        setallService(result?.data?.services);
        setprofileDetails(result?.data)
        setisLoading(false)

    }, () => { setisLoading(false) })

    const handleAddServicesProfile = catchAsync(async (data: any) => {
        setisLoading(true)
        return await epcAddServicesApiRequest({
            _id: profileDetails?._id,
            ...data
        })
    }, (result: any) => {
        console.log({ result });
        handleFetchUserProfile()
        formik.resetForm()
        setisLoading(false)
    }, () => { setisLoading(false) })


    const handleRemoveService = catchAsync(async (serviceId: string) => {
        setisLoading(true)
        return await epcRemoveServicesApiRequest({
            _id: profileDetails?._id,
            serviceId: serviceId
        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            handleFetchUserProfile()
            setisLoading(false)
        }
    }, () => { setisLoading(false) })


    const handleCheckServices = () => {
        if(allService && allService?.length == 0){
            setopenConfirmBox(true)
        }
        else {
            navigate(-1)
        }
    }

    console.log({allService});
    

    return (
        <div className='relative'>
            {
                !id && !epc_id ? <div className='absolute left-0 top-0 right-0 w-full h-full flex justify-center items-center  '>
                    <div className='bg-slate-200 rounded-lg p-4 flex items-center space-x-2'>
                        <AlertTriangle size={24} className='text-yellow-600 font-bold' />  <span className='text-lg'>Please complete profile</span>
                    </div>
                </div> : null
            }
            <div className={cn('grid grid-cols-6 gap-4 p-4 ', { "bg-slate-100 ": !id && !epc_id })}>
                <div className={cn('col-span-4 ', { "blur-sm": !id && !epc_id })}>
                    <Table className='border mt-2 mb-4'>
                        <TableHeader>
                            <TableRow>
                                {/* {Object.keys(INIT_EPC_SERVICES).filter((k: any) => k != "formId").map((m: any) => <TableHead key={`service-list-${m}`} >{convertToCamelCase(m)}</TableHead>)} */}
                                <TableHead>Service Image</TableHead>
                                <TableHead>Service Name</TableHead>
                                <TableHead>Service Description</TableHead>
                                <TableHead>Actions</TableHead>

                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {
                                allService && Array.isArray(allService) && allService.length > 0 ? allService.map((d: any) => {
                                    return <TableRow>
                                        {Object.keys(INIT_EPC_SERVICES).filter((k: any) => k != "formId")
                                            .map((m: any) => <TableCell key={`service-list-${m}`} >
                                                {d[m] && d[m].includes("http") ? <>
                                                    <DocumentViwer url={d[m] || ""} contentType='image' title='View' />
                                                </> : <>{d[m] || ""}</>}

                                            </TableCell>)}
                                        <TableCell >
                                            <Button variant={"outlineDestructive"} size={"xs"} className='space-x-2' onClick={() => d?._id && handleRemoveService(d?._id)}><span>Remove </span> <span ><Trash size={14} /></span></Button>
                                        </TableCell>
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan={3}> No Content</TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </div>
                <div className={cn(' col-span-2 p-4 bg-gray-50 rounded-xl border ', { "blur-sm": !id && !epc_id })}>
                    <div className='text-sm uppercase text-primary font-semibold '>Create Services</div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='flex flex-col space-y-4'>
                            {
                                EPC_SERVICES_FORM_FIELDS.map((agnetFormFiled: FormField) => {
                                    return <RenderFormComponents formik={formik} {...agnetFormFiled} value={formik.values[agnetFormFiled.dataKey as keyof object]} componentProps={{ ...agnetFormFiled.componentProps }} onChange={(e: any) => {
                                        formik.setFieldValue(agnetFormFiled.dataKey, e)
                                        console.log({ dataKey: agnetFormFiled.dataKey, e });
                                    }} />

                                })
                            }
                            <Button className='disabled:text-white border-0' disabled={(!epc_id && !id) || isLoading} >Add</Button>
                            <Button variant={'secondary'} type='button' disabled={(!epc_id && !id) || isLoading} onClick={() => formik.resetForm()} >Reset</Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex justify-center my-4'>
                <RoundedButton type='button' onClick={handleCheckServices} >Continue</RoundedButton>
            </div>

            <LoadingDialog isOpen={isLoading} message='Loading...' />
            <ConfirmDialog isOpen={openConfirmBox}
                title='Please confirm'
                description='Continue without saving services ?'
                onCancelClick={() => setopenConfirmBox(false)}
                onSubmit={() => {
                    setopenConfirmBox(false)
                    navigate(-1)
                }}
            />
            {/* 
            <div className='col-span-4'>
                <div className='flex justify-between items-center'>
                    <Label className='text-xs mb-2'>Services</Label>
                </div>


            </div> */}
        </div>
    )
}

export default ServicesFrm