import { icons, LucideIcon } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'
import { cn } from 'src/helpers'
import { Button, buttonVariants, Collapsible, CollapsibleContent, CollapsibleTrigger, Tooltip, TooltipContent, TooltipTrigger } from 'src/shadcn/components/ui'
import { useLayoutMainContext } from '../LayoutContext/MainContext'
import { CaretSortIcon } from '@radix-ui/react-icons'

export type link = {
    title: string
    label?: string
    icon?: LucideIcon | undefined
    variant: "default" | "ghost",
    navigationLink: string,
}

interface links extends link {
    subRoutes?: link[]
}

type Props = {
    isCollapsed: boolean
    links: links[]
}

const Nav = ({ links, isCollapsed }: Props) => {

    const { pathname } = useLocation()

    const { isCollapsedSidebar } = useLayoutMainContext()
    // console.log({isCollapsedSidebar});

    // console.log({pathname});



    return (
        <div
            data-collapsed={isCollapsedSidebar}
            className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
        >
            <div className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                {links.map((link, index) =>
                    isCollapsedSidebar ? (
                        <Tooltip key={index} delayDuration={0}>
                            <TooltipTrigger asChild>
                                <Link
                                    to={"/" + link.navigationLink}
                                    className={cn(
                                        buttonVariants({ variant: pathname == "/" + link.navigationLink ? "default" : "ghost", size: "icon" }),
                                        "h-9 w-9",
                                        "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white"
                                    )}
                                >
                                    {link.icon ? <link.icon className="h-4 w-4" /> : null}
                                    <span className="sr-only">{link.title}</span>
                                </Link>
                            </TooltipTrigger>
                            <TooltipContent side="right" className="flex items-center gap-4">
                                {link.title}
                                {link.label && (
                                    <span className="ml-auto text-muted-foreground">
                                        {link.label}
                                    </span>
                                )}
                            </TooltipContent>
                        </Tooltip>
                    ) :
                        <>
                            {
                                link?.subRoutes && link.subRoutes.length > 0 ?
                                    <Collapsible className='w-full'>
                                        <CollapsibleTrigger className='w-full'>
                                            <div className='flex justify-between items-center'>
                                                <div className='text-xs uppercase text-gray-500 font-semibold my-2'>{link.title}</div>
                                                <CaretSortIcon />
                                            </div>
                                        </CollapsibleTrigger>
                                        <CollapsibleContent>
                                            <div className='flex flex-col border border-primary border-b-0 border-r-0 border-t-0 border-2 ml-4 '>
                                                {
                                                    link.subRoutes.map((route: link, indx: number) => {
                                                        return <Link
                                                            key={`subroute-${indx}`}
                                                            to={route.navigationLink}
                                                            className={cn(
                                                                buttonVariants({ variant: pathname == "/" + route.navigationLink ? "default" : "ghost", size: "sm" }),
                                                                "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white  ",
                                                                "justify-start",
                                                                "border-0 rounded-l-none"
                                                            )}
                                                        >
                                                            { link.icon ? <link.icon className=" mr-2 h-4 w-4" /> : null}
                                                            {route.title}
                                                            {
                                                                route.label && (
                                                                    <span
                                                                        className={cn(
                                                                            "",
                                                                            pathname == "/" + route.navigationLink &&
                                                                            "text-background dark:text-white"
                                                                        )}
                                                                    >
                                                                        {route.label}
                                                                    </span>
                                                                )
                                                            }
                                                        </Link>
                                                    })
                                                }
                                            </div>
                                        </CollapsibleContent>
                                    </Collapsible>
                                    :
                                    <Link
                                        key={`route-${index}`}
                                        to={link.navigationLink}
                                        className={cn(
                                            buttonVariants({ variant: pathname == "/" + link.navigationLink ? "outline" : "ghost", size: "sm" }),
                                            "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                                            "justify-start",
                                            "border-0 rounded-l-none"
                                        )}
                                    >
                                        {link.icon ? <link.icon className="mr-2 h-4 w-4" /> : null}
                                        {link.title}
                                        {link.label && (
                                            <span
                                                className={cn(
                                                    "ml-auto",
                                                    pathname == "/" + link.navigationLink &&
                                                    "text-background dark:text-white"
                                                )}
                                            >
                                                {link.label}
                                            </span>
                                        )}
                                    </Link>

                            }
                        </>

                )}
            </div>
        </div>
    )
}

export default Nav