import { Plus } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import { Button } from 'src/shadcn/components/ui'
import AllSalesExecutive from './AllSalesExecutive'


type Props = {}

const AllSalesExecutivesMain = (props: Props) => {
 
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  return (
    <div className='m-4 space-y-2'>
      {
        currentUser?.role == ROLES.ADMIN_USER && <div className='flex justify-end'>
          <Button onClick={() => navigate("/" + APP_ROUTES.sales_executive.subRoutes.users.subRoutes.create.fullRoute)} size={"sm"} className='space-x-2'><span><Plus size={16} /></span><span>Create Executive</span> </Button>
        </div>
      }
      <AllSalesExecutive />
    </div >
  )
}

export default AllSalesExecutivesMain