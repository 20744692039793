
import React from 'react'
import { Link } from 'react-router-dom'
import { componentType, convertToCamelCase, FormField } from 'src/helpers'
import { Label, Separator, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/shadcn/components/ui'
import { MANUFACTURER_PROFILE_FORM_FIELDS, EPC_SERVICES_FORM_FIELDS, INIT_MANUFACTURER_PROFILE, INIT_MANUFACTURER_PRODUCTS, IMANUFACTURER_PROFILE } from './modules'
import { ICreateProjectResponse } from 'src/services/requests/Projects/module'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {
    data: IMANUFACTURER_PROFILE
}

const RenderViewComponent = ({ label, value, type }: { label: string, type: componentType, value: string | number | undefined }) => {
    return <div className='flex flex-col border-b'>
        <Label className='mb-1 uppercase text-xs  text-gray-500'>{label}</Label>
        <div className='font-semibold mb-1  '>
            {
                value ? <>
                    {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{value}</div>}
                    {type == "file_picker" && <DocumentViwer url={value as any} contentType='image' title='View' />}
                </> : "-"
            }
        </div>
    </div>
}

const ManufacturerProfileReview = ({ data }: Props) => {
    return (

        <ScrollArea className='h-[80vh] px-4 bg-gray-50'>
            {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>Manufacturer Details</div>
            <Separator className='my-2' /> */}
            <div className='grid grid-cols-4 gap-x-4 gap-y-2 my-2 '>
                {
                    data && Object.keys(INIT_MANUFACTURER_PROFILE).map((k: any) => {
                        if (k == "products") {
                            return <div className='col-span-4'>
                              
                            </div>
                        }
                        else {
                            const formfield: FormField | undefined = MANUFACTURER_PROFILE_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={data[formfield?.dataKey as keyof object]} type={formfield?.componentType} />
                        }
                    })
                }
            </div>
        </ScrollArea>

    )
}

export default ManufacturerProfileReview