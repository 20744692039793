import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllProjectRightsRequestsMainPage from './AllRequests/AllProjectRightsRequestsMainPage'
import ProjectRightsCreatePage from './ProjectRightsCreatePage'
import ProjectRightsUpdatePage from './ProjectRightsUpdatePage'
import ProjectRightsViewPage from './ProjectRightsViewPage'

type Props = {}

const ProjectRightsAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.projectRights.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllProjectRightsRequestsMainPage />} />
                <Route path={ROUTES.all.route} element={<AllProjectRightsRequestsMainPage />} />
                <Route path={ROUTES.create.route} element={<ProjectRightsCreatePage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<ProjectRightsUpdatePage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<ProjectRightsViewPage />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.projectRights.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default ProjectRightsAdminRoutes