import React, { useMemo } from 'react'
import { DataTable } from 'src/modules/Orders/AllOrders/Table/components/data-table'
import { columns } from 'src/modules/Orders/AllOrders/Table/components/columns'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'

type Props = {}

const Main = () => {

  const apiResp = useQueryResponseData()
  const tableData = useMemo(() => {
    if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
     return apiResp.map((d: any, indx: number) => ({ ...d, srNo: (indx + 1) }))
    }
    return []
  }, [apiResp])

  const isLoading = useQueryResponseLoading()

  return <DataTable isLoading={isLoading} data={tableData || []} columns={columns} />
}

const AllOrdersMainPage = (props: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default AllOrdersMainPage