import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllSalesExecutivesMain from './SalesExecutives/AllSalesExecutivesMain'
import ProjectsRoute from './Projects/ProjectsRoute'
import SalesExecutiveReportsRoute from './SalesExecutiveReport/SalesExecutiveReportsRoute'
import SalesExecutivesRoute from './SalesExecutives/SalesExecutivesRoute'


type Props = {}

const SalesExecutiveRoutes = (props: Props) => {

    return (
        <Routes>
            <Route element={<Outlet />}>
                {/* <Route index element={<Navigate to={"/" + ROUTES.all_sales_executive.fullRoute} />} /> */}
                <Route path={"/user/*"} element={<SalesExecutivesRoute />} />
                <Route path={"/projects/*"} element={<ProjectsRoute />} />
                <Route path={"/reports/*"} element={<SalesExecutiveReportsRoute />} />
                {/* <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.sales_executive.fullRoute} />} /> */}
            </Route>
        </Routes>
    )
}

export default SalesExecutiveRoutes