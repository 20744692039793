import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES, EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import AllProjectsMain from './AllProjectsMain'
import CreateProjectMain from './CreateProjectMain'
import EditProjectMain from './EditProjectMain'
import ViewProjectMain from './ViewProjectMain'

type Props = {}

const EpcProjectsRoute = (props: Props) => {

    const ROUTES = EPC_CONTRACTORS_ROUTES.projects.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllProjectsMain />} />
                <Route path={ROUTES.all.route} element={<AllProjectsMain />} />
                <Route path={ROUTES.create.route} element={<CreateProjectMain />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<EditProjectMain />} />
                <Route path={ROUTES.view.route + "/:id"} element={<ViewProjectMain />} />
                {/* <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.sales_executive.subRoutes.all_projects.fullRoute} />} /> */}
            </Route>
        </Routes>
    )
}

export default EpcProjectsRoute