import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, FormField } from 'src/helpers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { initSalesExecutive, ISalesExecutive, SalesExecutiveFormFields, validationSchema } from './modules'

type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
}

const SalesExecutiveForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm }: Props) => {


    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingProjectCalculation, setisLoadingProjectCalculation] = useState(false)

    const formik = useFormik<ISalesExecutive>({
        initialValues: { ...initial_values },
        validationSchema: validationSchema,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    useEffect(() => {
        if (initial_values) {
            Object.keys(initSalesExecutive).map((key: any) => {
                formik.setFieldValue(key, initial_values[key] || null)
            })
            // formik.setValues(initial_values)
        }
        return () => { }
    }, [initial_values])


    const handleSubmit = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            formik.setValues(result[0])
            onSubmit(result[0])
        }
        setisLoading(false)

    })


    console.log({ error: formik.errors, values: formik.values, touched: formik.touched });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <ScrollArea className='h-[80vh] px-4 bg-gray-50/10'>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Sales Executive Datails</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initSalesExecutive).map((k: any) => {
                                const formfield: FormField | undefined = SalesExecutiveFormFields.find((filed: FormField) => filed.dataKey == k) || undefined
                                return formfield && <RenderFormComponents key={`sales-executive-site-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                </ScrollArea>
                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='submit' disabled={isLoading || isLoadingForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isLoadingProjectCalculation}  />
        </div>
    )
}

export default SalesExecutiveForm