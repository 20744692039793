import { Plus } from 'lucide-react'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { columns } from 'src/modules/ManufacturerProducts/AllManufacturerPropducts/Table/components/columns'
import { DataTable } from 'src/modules/ManufacturerProducts/AllManufacturerPropducts/Table/components/data-table'
import { APP_ROUTES, MANUFACTURER_ROUTES } from 'src/navigations/modules'

import { Button } from 'src/shadcn/components/ui'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {
    id: string
}



const Main = () => {

    const navigate = useNavigate()
    const isLoading = useQueryResponseLoading()
    const apiResp = useQueryResponseData()

    const tableData = useMemo(() => {
        return apiResp && Array.isArray(apiResp) && apiResp.map((d: any, indx: Number) => {
            return {
                ...d,
                srNo: Number(indx) + 1
            }
        })
    }, [apiResp])

    return (
        <div className='p-4 space-y-4'>

            <DataTable data={tableData || []} columns={columns} isLoading={isLoading} />
            <div className='flex justify-center space-x-2 py-4'>
                <RoundedButton onClick={() => navigate("/" + APP_ROUTES.manufacturers.subRoutes.all.fullRoute)}>Continue</RoundedButton>
            </div>
        </div>
    )
}

const ManufacturerAllProducts = ({ id }: Props) => {

    console.log({ manufacturer: id });


    return <QueryRequestProvider>
        <QueryResponseProvider props={{ id }} >
            <Main />
        </QueryResponseProvider>
    </QueryRequestProvider>

}

export default ManufacturerAllProducts