import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, successTheme } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import EPCProfileForm from 'src/modules/EpcContractors/EpcProfile/EPCProfileForm'
import { INIT_EPC_CONTRACTOR_PROFILE } from 'src/modules/EpcContractors/EpcProfile/modules'
import ServicesFrm from 'src/modules/Manufacturer/ManufacturerProfile/ServicesFrm'
import ManufacturerProfileForm from 'src/modules/Manufacturer/ManufacturerProfile/ManufacturerProfileForm'
import { EPC_CONTRACTORS_ROUTES } from 'src/navigations/modules'
import { epcCreateProfileApiRequest, epcUpdateProfileApiRequest, epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import { getDetailsManufacturerProfileByIDApiRequest, updateManufacturerProfileByIDApiRequest } from 'src/services/requests/Manufacturers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { toast } from 'sonner'
import CreateManufactureProduct from './CreateManufactureProduct'
import ManufacturerAllProducts from './ManufacturerAllProducts/ManufacturerAllProducts'

type Props = {}

const ManufacturerUpdateMain = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [initFormDate, setinitFormDate] = useState(INIT_EPC_CONTRACTOR_PROFILE)
    const [resultData, setresultData] = useState<any>()
    const [profileData, setprofileData] = useState<any>()
    const [ServicesData, setServicesData] = useState<any>()
    const [currentActiveTab, setcurrentActiveTab] = useState<string>("complete_profile")
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    const { id } = useParams()

    useEffect(() => {
        id && handleFetchUserProfile()
        return () => {
        }
    }, [id])


    // useEffect(() => {
    //     if (currentUser?.email) {
    //         setinitFormDate({
    //             ...initFormDate,
    //             contractorEmail: currentUser?.email
    //         })
    //     }
    //     return () => { }
    // }, [])


    const handleFetchUserProfile = catchAsync(async () => {
        setisLoading(true)
        return id && await getDetailsManufacturerProfileByIDApiRequest(id)
    }, (result: any) => {
        console.log({ result })
        setprofileData(result?.data);
        setServicesData(result?.data?.services);
        setresultData({
            _id: result?.data?._id,
            epc_id: result?.data?.epc_contractor_id,
        })
        setcurrentActiveTab("complete_profile")
        setisLoading(false)

    }, () => { setisLoading(false) })

    const handleUpdateProfile = catchAsync(async (formdata: any) => {
        setisLoading(true)
        // console.log({ formdata });
        return formdata && await updateManufacturerProfileByIDApiRequest({
            ...formdata,
            services: profileData?.services,
            _id: profileData?._id
        })
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            toast.success("User Details Updated.", { style: successTheme })
        }
        setisLoading(false)
    }, () => {
        setisLoading(false)
    })

    console.log({ initFormDate });

    return (
        <div className='p-4'>
            <ScrollArea className='h-[90vh]'>
                <Tabs value={currentActiveTab} onValueChange={(e: any) => setcurrentActiveTab(e)} className="">
                    <TabsList>
                        <TabsTrigger disabled={resultData?._id && resultData?.epc_id} value="complete_profile">Profile</TabsTrigger>
                        <TabsTrigger disabled={!resultData?._id && !resultData?.epc_id} value="add_services">Products</TabsTrigger>
                        <TabsTrigger disabled={!resultData?._id && !resultData?.epc_id} value="all_products">All Products</TabsTrigger>
                    </TabsList>
                    <TabsContent value="complete_profile">
                        <ManufacturerProfileForm disableForm={resultData?._id} initial_values={initFormDate} submitBtnTitle='Save' onSubmit={(formdata: any) => handleUpdateProfile(formdata)} />
                    </TabsContent>
                    <TabsContent value="add_services">
                        <CreateManufactureProduct id={resultData?._id} onSuccess={() => setcurrentActiveTab("all_products")} />
                    </TabsContent>
                    <TabsContent value="all_products">
                        <ManufacturerAllProducts id={resultData?._id}  />
                    </TabsContent>

                </Tabs>

                <Separator />
            </ScrollArea>
            <LoadingDialog isOpen={isLoading} message="Profile updating..." />
        </div>
    )
}

export default ManufacturerUpdateMain