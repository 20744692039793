import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import { APP_ROUTES } from 'src/navigations/modules'
import AllQuotationRequestsMainPage from './AllRequests/AllQuotationRequestsMainPage'
import QuotationDetailPage from './QuotationDetailPage'


type Props = {}

const AdminQuatioRequestsRoutes = (props: Props) => {
    return (
        <Routes>
            <Route element={<div className='p-4'><Outlet /></div>} >
                <Route index element={<AllQuotationRequestsMainPage />} />
                <Route path={APP_ROUTES.orders.subRoutes.all.route} element={<AllQuotationRequestsMainPage />} />
                <Route path={APP_ROUTES.orders.subRoutes.view.route + "/:id"} element={<QuotationDetailPage />} />
            </Route>
        </Routes>
    )
}

export default AdminQuatioRequestsRoutes