import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const tableSchema = z.object({
  srNo: z.string(),
  _id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  plantLocation: z.string(),
  plantType: z.string(),
  installedCapacity: z.string(),
  annualRevenueFromThePlant: z.string(),
  annualEnergyProduction: z.string(),
  warrantyRemainingForModules: z.string(),
  currentAskingPrice: z.string(),
});

export type Task = z.infer<typeof tableSchema>
