import React, { useState } from 'react'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { INIT_PROJECT_RIGHTS } from 'src/modules/Services/ProjectRights/modules'
import ProjectRIghtsForm from 'src/modules/Services/ProjectRights/ProjectRIghtsForm'
import { rooftop_init } from 'src/modules/Services/Rooftop/modules'
import RooftopForm from 'src/modules/Services/Rooftop/RooftopForm'

type Props = {}

const ProjectRightsCreatePage = (props: Props) => {

  const [isLoading, setisLoading] = useState<boolean>(false)

  // const handleCreateRooftop = catchAsync((formData:any)=>{
  //   setisLoading(true)
  //   return await 
  // },(result:any)=>{
  //   if(IS_SUCCESS_RESPONSE(result)){
  //     setisLoading(false)
  //   }
  // },()=>{  setisLoading(false) })

  return (
    <ProjectRIghtsForm isLoadingForm={false} submitBtnTitle='Save' initial_values={INIT_PROJECT_RIGHTS} onSubmit={()=>{}} />
  )
}

export default ProjectRightsCreatePage