import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const tableSchema = z.object({
  srNo: z.string(),
  _id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(), // Validates email format
  phone: z.string(),
  projectLocation: z.string(),
  projectCity: z.string(),
  projectState: z.string(),
  projectCountry: z.string().optional(),
  annualEnergyProduction: z.string(),
  totalInstalledCapacity: z.string(),
  typeOfSolarPlant: z.string(),
  yearOfInstallation: z.string(),
  totalInvestment: z.string(),
  expectedSellingPrice: z.string(),
  currentOwner: z.string(),
  percentageOwnershipToBeSold: z.string(),
  operationalSinceYears: z.string(),

});

export type Task = z.infer<typeof tableSchema>
