import { useState } from 'react'
import { INIT_SELL_EXISTING_PROJECT } from 'src/modules/Services/SellExistingProjects/modules'
import SellExistingProjectForm from 'src/modules/Services/SellExistingProjects/SellExistingProjectForm'

type Props = {}

const SellExistingProjectsCreatePage = (props: Props) => {

  const [isLoading, setisLoading] = useState<boolean>(false)

  // const handleCreateRooftop = catchAsync((formData:any)=>{
  //   setisLoading(true)
  //   return await 
  // },(result:any)=>{
  //   if(IS_SUCCESS_RESPONSE(result)){
  //     setisLoading(false)
  //   }
  // },()=>{  setisLoading(false) })

  return (
    <SellExistingProjectForm isLoadingForm={false} submitBtnTitle='Save' initial_values={INIT_SELL_EXISTING_PROJECT} onSubmit={()=>{}} />
  )
}

export default SellExistingProjectsCreatePage