import { IndianRupee, Locate } from "lucide-react";
import { ADDRESS_REGEX, APPROVAL_REJECT_OPTIONS, AVAILABLE_SPACE_UNITS_OPTIONS, BUILDING_TYPES, CONNECTION_TYPE_OPTIONS, DAY_TIME_UNITS_OPTIONS, DC_COMMISIONING_TYPE_OPTIONS, EMAIL_REGEX, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, LAND_ACQUISITION_STATUS_OPTIONS, LAND_OWNERSHIP_STATUS_OPTIONS, LAT_LONG_REGEX, NAME_REGEX, NUMBER_REGEX, optionTypes, PROJECT_CATEGORY_OPTIONS, PROJECT_OWNERSHIP_TYPE_OPTIONS, PROPERTY_TYPE_OPTIONS, ROOF_MATERIAL_OPTIONS, SYSTEM_TYPE_OPTIONS, TERM_PLAN_OPTIONS, TRANSMISSION_CURRENT_STATUS_OPTIONS, TYPE_OF_SPACE_ENUM, TYPE_OF_SPACE_OPTIONS } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as Yup from 'yup';

export interface IPersonalDetails {
    whoYouAre: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    description: string;
}

export interface ILandLocation {
    locationName: string;
    address: string;
    nearestCity: string;
    distanceFromNearestCity: string;
    directionFromNearestCity: string;
    nearestAirport: string;
    nearestBusStand: string;
    nearestRailwayStation: string;
}

export interface ICoordinates {
    latitude: string;
    longitude: string;
}

export interface ILandOwnershipStatus {
    ownershipStatus: string;
    acquisitionStatus: string;
    expectedCompletionDate: string;
}

export interface ISubstationInformation {
    substationName: string;
    distanceFromSubstation: string;
    currentStatus: string;
}

export interface ITransmissionLine {
    distanceFromProjectSite: string;
    completionTimeline: string;
    voltageLevel: string;
}

export interface ILandInformation {
    totalRequiredLand: string;
    acquiredLand: string;
    balanceLand: string;
}

export interface ILandDocuments {
    _712Report: string;
    saleDeedLeaseDeed: string;
    landUseConversionCertificate: string;
    imgUrl: string
}

export interface IRemark {
    remark: string;
}

export interface IApprovalStatus {
    approvalReject: string;
}

export type ILandData = IPersonalDetails
    & ILandLocation
    & ICoordinates
    & ILandOwnershipStatus
    & ISubstationInformation
    & ITransmissionLine
    & ILandInformation
    & ILandDocuments
    & IRemark
    & IApprovalStatus

export const INITIAL_LAND_ONBOARD_FORM_DATA: ILandData = {
    whoYouAre: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    description: "",
    locationName: '',
    address: '',
    nearestCity: '',
    distanceFromNearestCity: "",
    directionFromNearestCity: '',
    nearestAirport: '',
    nearestBusStand: '',
    nearestRailwayStation: '',
    latitude: "",
    longitude: "",
    ownershipStatus: '',
    acquisitionStatus: '',
    expectedCompletionDate: "",
    substationName: '',
    distanceFromSubstation: "",
    currentStatus: '',
    distanceFromProjectSite: "",
    completionTimeline: '',
    voltageLevel: "",
    totalRequiredLand: "",
    acquiredLand: "",
    balanceLand: "",
    _712Report: "",
    saleDeedLeaseDeed: "",
    landUseConversionCertificate: "",
    imgUrl: "",
    remark: "",
    approvalReject: "",
};


export const personalDetailsInit: IPersonalDetails = {
    whoYouAre: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    description: '',
};

export const landLocationInit: ILandLocation = {
    locationName: '',
    address: '',
    nearestCity: '',
    distanceFromNearestCity: '',
    directionFromNearestCity: '',
    nearestAirport: '',
    nearestBusStand: '',
    nearestRailwayStation: '',
};

export const coordinatesInit: ICoordinates = {
    latitude: '',
    longitude: '',
};

export const landOwnershipStatusInit: ILandOwnershipStatus = {
    ownershipStatus: '',
    acquisitionStatus: '',
    expectedCompletionDate: '',
};

export const substationInformationInit: ISubstationInformation = {
    substationName: '',
    distanceFromSubstation: '',
    currentStatus: '',
};

export const transmissionLineInit: ITransmissionLine = {
    distanceFromProjectSite: '',
    completionTimeline: '',
    voltageLevel: '',
};

export const landInformationInit: ILandInformation = {
    totalRequiredLand: '',
    acquiredLand: '',
    balanceLand: '',
};

export const landDocumentsInit: ILandDocuments = {
    _712Report: '',
    saleDeedLeaseDeed: '',
    landUseConversionCertificate: '',
    imgUrl: ""
};

export const remarkInit: IRemark = {
    remark: '',
};

export const approvalStatusInit: IApprovalStatus = {
    approvalReject: '',
};


export const landOnboardFormStructure = {
    "Personal Details": personalDetailsInit,
    "Land Location": landLocationInit,
    "Coordinates": coordinatesInit,
    "Land Ownership Status": landOwnershipStatusInit,
    "Substation Information": substationInformationInit,
    "Transmission Line": transmissionLineInit,
    "Land Information": landInformationInit,
    "Land Documents": landDocumentsInit,
    "Remark": remarkInit,
    "Approval Status": approvalStatusInit,
};

export const LAND_ONBOARD_FORM_DATA_VALUES = {
    whoYouAre: "Project Manager",
    firstName: "John",
    lastName: "Doe",
    phone: "1234567890",
    email: "john.doe@example.com",
    description: "Land for solar plant installation",
    locationName: "Sunnyvale",
    address: "123 Solar St",
    nearestCity: "Greenfield",
    distanceFromNearestCity: "10",
    directionFromNearestCity: "North",
    nearestAirport: "Greenfield Airport",
    nearestBusStand: "Greenfield Bus Stand",
    nearestRailwayStation: "Greenfield Railway Station",
    latitude: "34.0522",
    longitude: "-118.2437",
    ownershipStatus: "Owned",
    acquisitionStatus: "Acquired",
    expectedCompletionDate: "2024-12-31",
    substationName: "Sunnyvale Substation",
    distanceFromSubstation: "5",
    currentStatus: "Under Development",
    distanceFromProjectSite: "2",
    completionTimeline: "12 months",
    voltageLevel: "33",
    totalRequiredLand: "50",
    acquiredLand: "30",
    balanceLand: "20",
    _712Report: "Completed",
    saleDeedLeaseDeed: "Available",
    landUseConversionCertificate: "Obtained",
    remark: "All approvals in place",
    approvalReject: "Approved",
}

export const LAND_ONBOARD_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'First Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Last Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Phone',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone',
            maxLength: 10,
            required: true,
            regex: NUMBER_REGEX
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Description',
            required: true
        },
    },
    {
        label: 'Location Name',
        dataKey: 'locationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Location Name',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Address',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'Nearest City',
        dataKey: 'nearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest City',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Distance From Nearest City',
        dataKey: 'distanceFromNearestCity',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Landmark From Nearest City ',
        dataKey: 'directionFromNearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: '',
            required: true,
        },
    },
    {
        label: 'Nearest Airport',
        dataKey: 'nearestAirport',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Airport',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Nearest Bus Stand',
        dataKey: 'nearestBusStand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Bus Stand',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Nearest Railway Station',
        dataKey: 'nearestRailwayStation',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Railway Station',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Latitude',
        dataKey: 'latitude',
        componentType: 'input',
        componentProps: {
            type: "number",
            regex: NUMBER_REGEX,
            placeholder: 'Latitude',
            required: true
        },
    },
    {
        label: 'Longitude',
        dataKey: 'longitude',
        componentType: 'input',
        componentProps: {
            type: "number",
            regex: NUMBER_REGEX,
            placeholder: 'Longitude',
            required: true
        },
    },
    {
        label: 'Ownership Status',
        dataKey: 'ownershipStatus',
        componentType: 'options',
        componentProps: {
            options: LAND_OWNERSHIP_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Acquisition Status',
        dataKey: 'acquisitionStatus',
        componentType: 'options',
        componentProps: {
            options: LAND_ACQUISITION_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Expected Completion Date',
        dataKey: 'expectedCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Expected Completion Date',
            required: true
        },
    },
    {
        label: 'Substation Name',
        dataKey: 'substationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Substation Name',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Distance From Substation',
        dataKey: 'distanceFromSubstation',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Current Status',
        dataKey: 'currentStatus',
        componentType: 'options',
        componentProps: {
            options: TRANSMISSION_CURRENT_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Distance of transmission line from Project Site',
        dataKey: 'distanceFromProjectSite',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Completion Timeline',
        dataKey: 'completionTimeline',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Days/Months/Years',
            options: DAY_TIME_UNITS_OPTIONS,
            required: true,
        },
    },
    {
        label: 'Voltage Level',
        dataKey: 'voltageLevel',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="kW" />
        },
    },
    {
        label: 'Total Required Land',
        dataKey: 'totalRequiredLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: AVAILABLE_SPACE_UNITS_OPTIONS,
        },
    },
    {
        label: 'Acquired Land',
        dataKey: 'acquiredLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: AVAILABLE_SPACE_UNITS_OPTIONS,
        },
    },
    {
        label: 'Balance Land',
        dataKey: 'balanceLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: AVAILABLE_SPACE_UNITS_OPTIONS,
        },
    },
    {
        label: '7/12 Report',
        dataKey: '_712Report',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Sale Deed/Lease Deed',
        dataKey: 'saleDeedLeaseDeed',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Land Use Conversion Certificate',
        dataKey: 'landUseConversionCertificate',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Remark',
        dataKey: 'remark',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Remark',
            required: true
        },
    },
    {
        label: 'Approval/Reject',
        dataKey: 'approvalReject',
        componentType: 'options',
        componentProps: {
            options: APPROVAL_REJECT_OPTIONS,
            required: true
        },
    },
    {
        label: 'Remark',
        dataKey: 'remark',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Remark',
            maxLength: 150,
            required: true
        },
    },
];


export const landOnboardDataValidationSchema = Yup.object().shape({
    whoYouAre: Yup.string().required('Who you are is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    description: Yup.string(),
    locationName: Yup.string().required('Location name is required'),
    address: Yup.string().required('Address is required'),
    nearestCity: Yup.string().required('Nearest city is required'),
    distanceFromNearestCity: Yup.string().required('Distance from nearest city is required'),
    directionFromNearestCity: Yup.string().required('Direction from nearest city is required'),
    nearestAirport: Yup.string(),
    nearestBusStand: Yup.string(),
    nearestRailwayStation: Yup.string(),
    latitude: Yup.string().required('Latitude is required'),
    longitude: Yup.string().required('Longitude is required'),
    ownershipStatus: Yup.string().required('Ownership status is required'),
    acquisitionStatus: Yup.string().required('Acquisition status is required'),
    expectedCompletionDate: Yup.string().required('Expected completion date is required'),
    substationName: Yup.string().required('Substation name is required'),
    distanceFromSubstation: Yup.string().required('Distance from substation is required'),
    currentStatus: Yup.string().required('Current status is required'),
    distanceFromProjectSite: Yup.string().required('Distance from project site is required'),
    completionTimeline: Yup.string().test('completionTimelineUnits', 'Select Units (e.g. Days,Months,Years)',
        (value: any) => {
            if (value) {
                const units = DAY_TIME_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                return units.some((u: any) => value.includes(u))
            }
        }).required('Completion timeline is required'),
    voltageLevel: Yup.string().required('Voltage level is required'),
    totalRequiredLand: Yup.string().test('totalRequiredLandUnits', 'Select Units (e.g. sq.ft, ft, m)',
        (value: any) => {
            if (value) {
                const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                return units.some((u: any) => value.includes(u))
            }
        }).required('Total required land is required'),
    acquiredLand: Yup.string().test('acquiredLandUnits', 'Select Units (e.g. sq.ft, ft, m)',
        (value: any) => {
            if (value) {
                const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                return units.some((u: any) => value.includes(u))
            }
        }).required('Acquired land is required'),
    balanceLand: Yup.string().test('balanceLandUnits', 'Select Units (e.g. sq.ft, ft, m)',
        (value: any) => {
            if (value) {
                const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                return units.some((u: any) => value.includes(u))
            }
        }).required('Balance land is required'),
    _712Report: Yup.string().required('7/12 report is required'),
    saleDeedLeaseDeed: Yup.string().required('Sale deed/lease deed is required'),
    imgUrl: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required(),
    landUseConversionCertificate: Yup.string().required('Land use conversion certificate is required'),
    remark: Yup.string(),
    approvalReject: Yup.string().required('Approval/reject is required'),
});

