import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllNewConstructedProjectsRequestsMainPage from './AllRequests/AllNewConstructedProjectsRequestsMainPage'
import NewConstructedProjectsCreatePage from './NewConstructedProjectsCreatePage'
import NewConstructedProjectsUpdatePage from './NewConstructedProjectsUpdatePage'
import NewConstructedProjectsViewPage from './NewConstructedProjectsViewPage'

type Props = {}

const NewConstructedProjectsAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.newConstructedProjects.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllNewConstructedProjectsRequestsMainPage />} />
                <Route path={ROUTES.all.route} element={<AllNewConstructedProjectsRequestsMainPage />} />
                <Route path={ROUTES.create.route} element={<NewConstructedProjectsCreatePage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<NewConstructedProjectsUpdatePage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<NewConstructedProjectsViewPage />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.newConstructedProjects.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default NewConstructedProjectsAdminRoutes