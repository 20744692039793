import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllLandOnbordingRequestsMainPage from './AllRequests/AllRooftopRequestsMainPage'
import RooftopCreatePage from './RooftopCreatePage'
import RooftopUpdatePage from './RooftopUpdatePage'
import RooftopViewPage from './RooftopViewPage'
import AllRooftopRequestsMainPage from './AllRequests/AllRooftopRequestsMainPage'

type Props = {}

const RooftopAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.rooftop.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<AllRooftopRequestsMainPage />} />
                <Route path={ROUTES.all.route} element={<AllRooftopRequestsMainPage />} />
                <Route path={ROUTES.create.route} element={<RooftopCreatePage />} />
                <Route path={ROUTES.edit.route + "/:id"} element={<RooftopUpdatePage />} />
                <Route path={ROUTES.view.route + "/:id"} element={<RooftopViewPage />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.rooftop.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default RooftopAdminRoutes