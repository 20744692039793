

export type ILayoutSize =  20 | 32 | 48

export interface LayoutContextProps {
    isCollapsedSidebar: boolean;
    defaultLayout: ILayoutSize
    setIsCollapsed: (e: boolean) => void,
    setLayout:(e: ILayoutSize) => void,
}

export const initialLayoutContext = {
    isCollapsedSidebar: false,
    defaultLayout:20 as ILayoutSize, 
    setIsCollapsed: () => { },
    setLayout: () => { }
}
