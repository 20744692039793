import React from 'react'
import { ICON_PNG_OCT_LOGO } from 'src/helpers'
import { login } from 'src/modules/Auth/request'
import Login from 'src/modules/Auth/Login'
import { Tabs, TabsList } from 'src/shadcn/components/ui'

type Props = {}

const LoginMain = (props: Props) => {
    return (
        <Login />
    )
}

export default LoginMain