import { useFormik } from 'formik'
import React from 'react'
import { INIT_investor, INVESTOR_FORM_FIELDS, investorValidationSchema } from '../modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { checkDecimal, componentType, EXTRACT_NUMBERS_REGEX, FormField } from 'src/helpers'
import { Label } from 'src/shadcn/components/ui'
import { Link } from 'react-router-dom'

type Props = {
  data: any
}

const RenderViewComponent = ({ value, type, formField }: { type: componentType, value: string | number | undefined, formField: FormField }) => {


  return <div className='flex flex-col border-b'>
    <Label className='mb-1 text-xs  text-gray-500'>{formField.label}</Label>
    {formField.componentProps?.description ? <div className='text-xs text-gray-500'>{formField.componentProps.description}</div> : null}
    <div className='text-sm flex space-x-1 items-center'>
      {formField.componentProps?.inputPrefixIcon ? formField.componentProps?.inputPrefixIcon : null}
      <div className='font-semibold mb-1  '>
        {
          value ? <>
            {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>{formField?.componentProps?.numberFormatted ? value && checkDecimal((value).toString().replace(EXTRACT_NUMBERS_REGEX, ""), true) : value}</div>}
            {type == "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
          </> : "-"
        }
      </div>
      {/* {formField.componentProps?.inputSuffixIcon ? formField.componentProps?.inputSuffixIcon : null} */}
    </div>
  </div>
}

const InvestorDetails = ({ data }: Props) => {

  return (
    <div className='grid grid-cols-3 gap-4 p-2 bg-orange-100'>
      {
        Object.entries(INIT_investor).map(([key, value]) => {
          const formfield: FormField | undefined = INVESTOR_FORM_FIELDS.find((filed: FormField) => filed.dataKey == key) || undefined
          return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey] || ""} type={formfield?.componentType} />
        })
      }
    </div>
  )
}

export default InvestorDetails