import { AtSign, KeyRound } from 'lucide-react';
import { convertToCamelCase, EMAIL_REGEX, FormField, NUMBER_REGEX, PASSWORD_REGEX, ROLES, ROLES_OPTIONS, USER_ROLES } from 'src/helpers';
import * as Yup from 'yup';

export type ILoginForm = {
  email: string,
  password: string
}

export type IRegistrationForm = {
  email: string,
  password: string
  confirm_password: string,
  role: string
}

export type IForgotPasswordForm = {
  email: string,
}


export type IResetPassword = {
  email: string,
  password: string
  confirm_password: string,
}

export const FORGOT_PASSWORD_FORM_FILED = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />
    }
  },
]

export const forgotPasswordFormValidation = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .matches(PASSWORD_REGEX, "Password including capital letters and small letters, numbers, and special characters and min 8 characters")
    .min(8, 'Password must be at least min 8 characters')
    .required('Password is required'),
});

export const registrationFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  role: Yup.string().required("Select role"),
  password: Yup.string()
    .matches(PASSWORD_REGEX, "Password including capital letters and small letters, numbers, and special characters and min 8 characters")
    .min(8, 'Password must be at least min 8 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .matches(PASSWORD_REGEX, "Password including capital letters and small letters, numbers, and special characters and min 8 characters")
    .min(8, 'Password must be at least min 8 characters')

    .required('Password is required')
    .test('passwords-match', 'Passwords not match', function (value) {
      return this.parent.password === value;
    }),
});

export const ResetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/, "Password should be at least 1 capital letter , 1 special character, 1 small letter")
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 characters")
    .required('Password is required'),
  confirm_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    .test('passwords-match', 'Passwords not match', function (value) {
      return this.parent.password === value;
    }),
});



export const login_initialValues: ILoginForm = {
  email: '',
  password: '',
};

export const registration_initialValues: IRegistrationForm = {
  email: '',
  password: '',
  confirm_password: "",
  role: "" //ROLES.ADMIN_USER
};


export const resetpassword_initialValues: IResetPassword = {
  email: '',
  password: '',
  confirm_password: "",
};

export const LOGIN_FORM_FIELDS: FormField[] = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />,
    }
  },
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  }
];


export const REGISTRATION_FORM_FIELDS: FormField[] = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />,
    }
  },
  {
    label: 'Role',
    dataKey: 'role',
    componentType: 'options',
    componentProps: {
      options: Object.entries(ROLES_OPTIONS).map(([key, value]) => ({ label: convertToCamelCase(value), value: key }))
    }
  },
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Confirm Password',
    dataKey: 'confirm_password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  }
];


export const RESET_PASSWORD_FORM_FIELDS: FormField[] = [
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Confirm Password',
    dataKey: 'confirm_password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  }
];
