import { BASE_URL } from "../../api_const"

export const EPC_BASE_URL =BASE_URL +  "/epc-contractor/profile"
export const URL_CREATE_PROFILE = EPC_BASE_URL + "/create"
export const URL_UPDATE_PROFILE = EPC_BASE_URL + "/update"
export const URL_ADD_SERVICE = EPC_BASE_URL + "/service/update"
export const URL_REMOVE_SERVICE = EPC_BASE_URL + "/service/remove"
// export const URL_UPDATE_PROFILE = EPC_BASE_URL
export const URL_VIEW_PROFILE = EPC_BASE_URL
export const URL_ALL_EPC_CONTRACTORS = EPC_BASE_URL + "/all"
