import { format, formatDate } from "date-fns";
import { InfoIcon } from "lucide-react";
import { cn } from "src/helpers";
import { Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui";
import DateInput from "./DateInput";
import DatePicker from 'react-date-picker'
import { ClassNameValue } from "tailwind-merge";
import { useMemo } from "react";

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  onchange?: (e: any) => void;
  disabled?: boolean,
  showInfoIcon?: boolean
  infoContent?: string,
  toYear?: number
  fromYear?: number,
  labelStyle?: ClassNameValue,
  minDate?: string ,
  maxDate?: string
};


const formatDateInDate = (date: string | Date | undefined) => {
  if (date) {
    if (typeof date == 'string' && date?.includes("/")) {
      return new Date(date?.split("/").reverse().join("-"))
    }
    else if (typeof date == 'string' && date?.includes("-")) {
      return new Date(date)
    }
    return new Date(date)
  }
}

const FormikDateField = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  onchange,
  disabled,
  showInfoIcon,
  infoContent,
  toYear,
  fromYear,
  labelStyle,
  maxDate, 
  minDate
}: Props) => {


  // console.log({minDate});

  const newMinDate = useMemo(() => formatDateInDate(minDate), [minDate])
  const newMaxDate = useMemo(() => formatDateInDate(maxDate), [maxDate])

  // console.log({newMinDate});
  
  

  return (
    <>
      <div className="flex justify-between mt-2">
        <Label className={cn(" text-xs", labelStyle, `${required ? "required" : ""}`)}>
          {label} {required ? <span className="text-red-600 font-semibold">*</span> : null} 
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event: any) => event.preventDefault()} >
                <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div >
      <div className="mt-2">
        <DatePicker value={value} minDate={newMinDate} maxDate={newMaxDate} onChange={(e: any) => { onchange && onchange(e ? format(new Date(e), "yyyy-MM-dd") : null); }} calendarIcon={false} />
      </div>
      {
        formik.touched[nameKey] && formik.errors[nameKey] && (
          <p className={cn("text-xs font-medium text-destructive")}>
            {formik.errors[nameKey]}
          </p>
        )
      }
    </>
  );
};

export default FormikDateField;
