import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import SalesExecutiveReportMain from './SalesExecutiveReportMain'
import { APP_ROUTES } from 'src/navigations/modules'



type Props = {}

const SalesExecutiveReportsRoute = (props: Props) => {

    const ROUTES = APP_ROUTES.sales_executive.subRoutes.reports.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<SalesExecutiveReportMain />} />
                <Route path={ROUTES.all.route} element={<SalesExecutiveReportMain />} />
                {/* <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.sales_executive.subRoutes.all_projects.fullRoute} />} /> */}
            </Route>
        </Routes>
    )
}

export default SalesExecutiveReportsRoute