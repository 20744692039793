import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/SalesExecutive";
import { CreateSalesExecutiveRequestBody } from "./modules";


export function createSalesExecutiveApiRequest(formData: CreateSalesExecutiveRequestBody): Promise<AxiosResponse> {
    return axiosInstance.post(API.URL_CREATE_SALES_EXECUTIVE, formData).then((d: AxiosResponse) => d.data);
}

export function updateSalesExecutiveApiRequest(formData: CreateSalesExecutiveRequestBody): Promise<AxiosResponse> {
    return axiosInstance.put(API.URL_UPDATE_SALES_EXECUTIVE, formData).then((d: AxiosResponse) => d.data);
}


export function allSalesExecutiveApiRequest(query?: string): Promise<any> {
    return axiosInstance.get(`${API.URL_GET_ALL_SALES_EXECUTIVE}`).then((d: AxiosResponse) => d.data);
}

export function getSalesExecutiveDetailsByIDApiRequest(id: string): Promise<any> {
    return axiosInstance.get(`${API.URL_GET_SALES_EXECUTIVE_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}

export function allSalesExecutiveProjectsApiRequest(query?: string): Promise<any> {
    return axiosInstance.get(`${API.URL_GET_SALES_EXECUTIVE_PROJECTS}`).then((d: AxiosResponse) => d.data);
}

export function getSalesExecutiveProjectDetailsByIDApiRequest(): Promise<any> {
    return axiosInstance.get(`${API.URL_GET_SALES_EXECUTIVE_REPORT}`).then((d: AxiosResponse) => d.data);
}

export function allSalesExecutiveReportsApiRequest(query?: string): Promise<any> {
    return axiosInstance.get(`${API.URL_GET_SALES_EXECUTIVE_PROJECTS}`).then((d: AxiosResponse) => d.data);
}