import axios, { AxiosError } from 'axios';
import { BASE_URL } from './consts/api_const';
import { getAuth } from 'src/modules/Auth/Core';
import { toast } from 'src/shadcn/components/ui/use-toast';

const createAxiosInstance = (withoutAuth: boolean) => {
    const instance = axios.create({
        baseURL: BASE_URL,
        timeout: 100000,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.request.use(
        (config) => {
            const token = getAuth();
            console.log({ token });
            if (token && token.api_token) {
                config.headers['Authorization'] = `Bearer ${token.api_token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // instance.interceptors.response.use(
    //     (response) => response,
    //     (error) => {
    //         handleError(error);
    //         return Promise.reject(error);
    //     }
    // );

    return instance;
};

const axiosInstance = createAxiosInstance(true);
const axiosInstanceWithoutAuth = createAxiosInstance(false);



function handleError(error: any) {
    let err = error as Error | AxiosError
    if (axios.isAxiosError(err)) {
        if (err) {
            const status = err.response?.status;
            // if (status === 401) {
            //     toast({
            //         variant: "destructive",
            //         title: "Unauthorized",
            //         description: "TOken Expired"
            //     })
            // }
            // else {
                toast({
                    variant: "destructive",
                    title: "Uh oh! Something went wrong.",
                    description: error.response?.data?.message,
                })
            // }
        }
    } else {
        toast({
            variant: "destructive",
            title: "Unexpected error",
            description: error?.message,
        })
    }
}


export { axiosInstance,axiosInstanceWithoutAuth };