import ResetPassword from 'src/modules/Auth/ResetPassword'

type Props = {}

const ResetPasswordMain = (props: Props) => {
  return (
    <div>
      <ResetPassword />
    </div>
  )
}

export default ResetPasswordMain