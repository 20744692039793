import React from 'react'
import VerifyOtp from 'src/modules/Auth/VerifyOtp'

type Props = {}

const VerifyOtpMain = (props: Props) => {
  return (
    <div>
        <VerifyOtp />
    </div>
  )
}

export default VerifyOtpMain