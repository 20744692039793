import { Link } from 'react-router-dom'
import { checkDecimal, componentType, FormField } from 'src/helpers'
import { Label } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { ROOFTOP_FORM_FIELDS, rooftop_init } from './modules'
import { ImageOff, ImageUp } from 'lucide-react'
import dayjs from 'dayjs'


type Props = {
    data: any
}



const RenderViewComponent = ({ label, value, type, componentProps }: { label: string, type: componentType, value: string | number | undefined, componentProps?: any }) => {
    return <div className='flex flex-col '>
        <Label className='mb-1 uppercase text-xs  text-gray-500'>{label}</Label>
        <div className='font-semibold mb-1  '>
            {
                value ? <>
                    {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) ?
                        <div className=' '>{componentProps?.inputPrefixIcon} { type == "date_picker" && !(value).toString().includes("/") ? dayjs(value).format("DD/MM/YYYY") : value} {componentProps?.inputSuffixIcon}</div>
                        : null}
                    {type == "file_picker" && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                </> : "-"
            }
        </div>
    </div>
}

const RooftopReviewForm = ({ data }: Props) => {

    return (
        <div className='p-4 '>
            <div className='grid grid-cols-6'>
                <ScrollArea className='col-span-4 h-[80vh] px-4 bg-gray-50'>
                    <div className='grid grid-cols-2 gap-4'>
                        {
                            Object.keys(rooftop_init).map((k: any) => {
                                const formfield: FormField | undefined = ROOFTOP_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                                let value = data[formfield?.dataKey as keyof object]
                                if (value && formfield?.componentProps?.numberFormatted) {
                                    value = checkDecimal(value, true)
                                }
                                if (formfield?.componentProps?.fileSelectionType == "multi") {
                                    return <div className='col-span-3'>
                                        {
                                            value && Array.isArray(value) && value.length > 0 ?
                                                value.map((d: any) => {
                                                    return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={d} type={formfield?.componentType} componentProps={formfield?.componentProps} />
                                                })
                                                : null
                                        }
                                    </div>
                                }
                                return formfield && <RenderViewComponent key={formfield?.dataKey} label={formfield?.label} value={value} type={formfield?.componentType} componentProps={formfield?.componentProps} />
                            })
                        }
                    </div>
                </ScrollArea>
                <div className='col-span-2 p-4'>
                    {
                        data.imgUrl ?
                            <img src={data.imgUrl} className='mx-auto cursor-pointer rounded-xl ' style={{ width: "300px", height: "300px" }} />
                            : <div className='space-y-2'>
                                <div className='w-[300px] h-[300px] bg-gray-100 mx-auto rounded-xl'>
                                    <div className='w-full h-full flex justify-center items-center'>
                                        <div className='flex flex-col items-center space-y-2'>
                                            <ImageOff size={32} className='text-gray-400' />
                                            <span className='text-sm text-gray-600'> No Content</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default RooftopReviewForm