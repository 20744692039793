import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navigation from "./navigations"
import { TooltipProvider } from './shadcn/components/ui';
import { AuthInit } from './modules/Auth/Core';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <AuthInit>
      <TooltipProvider>
        <Outlet />
      </TooltipProvider>
    </AuthInit>
  );
}

export default App;
