import { CONNECTION_TYPE, DC_COMMISIONING_TYPE_OPTIONS, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA, PLANT_STATUS, PROJECT_OWNERSHIP_TYPE, SOLAR_TECHNOLOGY_USED, TYPE_OF_SOLAR_MODULES_USED, TYPE_OF_SOLAR_PLANT, YES_NO_OPTIONS } from 'src/helpers';
import CustomTextIcon from 'src/shadcn/components/custom/CustomeSymbols/CustomTextIcon';
import * as Yup from 'yup';

// Interface for Personal Details
export interface PersonalDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alternatePhone?: string; // Optional
  address: string;
  city: string;
  state: string;
  pinCode: string;
}

// Interface for Project Information
export interface ProjectInformation {
  projectLocation: string;
  projectCity: string;
  projectState: string;
  projectCountry?: string; // Default value can be handled in the application logic
  totalInstalledCapacity: string; // in MW
  typeOfSolarPlant: string;
  connectionType: string;
  yearOfInstallation: string; // Assuming it's a year (e.g., 2""23)
  plantStatus: string;
  solarTechnologyUsed: string;
  projectOwnershipType: string;
}

// Interface for Financial and Ownership
export interface FinancialAndOwnership {
  totalInvestment: string;
  expectedSellingPrice: string;
  currentOwner: string;
  percentageOwnershipToBeSold: string; // Assuming this is a percentage
}

// Interface for Additional Project Information
export interface AdditionalProjectInformation {
  operationalSinceYears: string;
  annualEnergyProduction: string; // Assuming in kWh or similar unit
  outstandingLiabilities: string;
  powerPurchaseAgreement: string;
  subsidiesReceived: string;
  subsidyAmount: string,
  typeOfSolarModulesUsed: string;
  inverterModel: string;
  inverterBrand: string;
  inverterCapacity: string; // Assuming in kW
  batteryModel: string;
  batteryBrand: string;
  batteryCapacity: string; // Assuming in kWh
  availabilityMonitoringSystem: string;
  expectedTimeframeForSale: string;
  imgUrl: string
}

export interface Documents {
  powerPurchaseDoc: string,
  ownershipDoc: string,
  legalClearanceDoc: string,
  anyOtherRelevantDoc: string[]
}



export type INewConstructedProjectsForm = PersonalDetails & ProjectInformation & FinancialAndOwnership & AdditionalProjectInformation & Documents

// Main interface combining all sections
export interface INewConstructedProjects {
  personalDetails: PersonalDetails;
  projectInformation: ProjectInformation;
  financialAndOwnership: FinancialAndOwnership;
  additionalProjectInformation: AdditionalProjectInformation;
  documents: Documents
}

// Initial constant for Personal Details
export const initialPersonalDetails: PersonalDetails = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  alternatePhone: "", // Optional
  address: "",
  city: "",
  state: "",
  pinCode: "",
};

// Initial constant for Project Information
export const initialProjectInformation: ProjectInformation = {
  projectLocation: "",
  projectCity: "",
  projectState: "",
  projectCountry: "", // Default value can be handled in the application logic
  totalInstalledCapacity: "", // in MW
  typeOfSolarPlant: "",
  connectionType: "",
  yearOfInstallation: "", // Assuming it's a year (e.g., 2""23)
  plantStatus: "",
  solarTechnologyUsed: "",
  projectOwnershipType: "",
};

// Initial constant for Financial and Ownership
export const initialFinancialAndOwnership: FinancialAndOwnership = {
  totalInvestment: "", // Example value in currency
  expectedSellingPrice: "", // Example value in currency
  currentOwner: "",
  percentageOwnershipToBeSold: "", // Assuming this is a percentage
};

// Initial constant for Additional Project Information
export const initialAdditionalProjectInformation: AdditionalProjectInformation = {
  operationalSinceYears: "",
  annualEnergyProduction: "", // Assuming in kWh or similar unit
  outstandingLiabilities: "",
  powerPurchaseAgreement: "",
  subsidiesReceived: "",
  subsidyAmount: "",
  typeOfSolarModulesUsed: "",
  inverterModel: "",
  inverterBrand: "",
  inverterCapacity: "", // Assuming in kW
  batteryModel: "",
  batteryBrand: "",
  batteryCapacity: "", // Assuming in kWh
  availabilityMonitoringSystem: "",
  expectedTimeframeForSale: "",
  imgUrl: ""
};


export const initialDocuments = {
  powerPurchaseDoc: "",
  ownershipDoc: "",
  legalClearanceDoc: "",
  anyOtherRelevantDoc: [""]
}


export const INIT_NEW_CONSTRUCTED_PROJECTS = {
  ...initialPersonalDetails,
  ...initialProjectInformation,
  ...initialFinancialAndOwnership,
  ...initialAdditionalProjectInformation,
  ...initialDocuments
}


//  export const newConstructedProjectsInit: INewConstructedProjects = {
//     personalDetails: initialPersonalDetails,
//     projectInformation: initialProjectInformation,
//     financialAndOwnership: initialFinancialAndOwnership,
//     additionalProjectInformation: initialAdditionalProjectInformation,
//   };


export const PROJECT_FORM_STRUCTURE = {
  "Personal Details": initialPersonalDetails,
  "Project Details": initialProjectInformation,
  "Financial And Ownership Details": initialFinancialAndOwnership,
  "Additional Project Information": initialAdditionalProjectInformation,
  "Documents": initialDocuments
}


export const newConstructedProjectsFormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email address is required'),
  phone: Yup.string().trim().required('Phone string is required'),
  alternatePhone: Yup.string().trim().optional(),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pinCode: Yup.string().trim().required('Pin code is required').length(6, 'Pin code must be 6 characters'),

  projectLocation: Yup.string().required('Project location is required'),
  projectCity: Yup.string().required('Project city is required'),
  projectState: Yup.string().required('Project state is required'),
  projectCountry: Yup.string().optional(),
  totalInstalledCapacity: Yup.string().required('Installed Capcity required'),
  typeOfSolarPlant: Yup.string().required('Type of solar plant is required'),
  connectionType: Yup.string().required('Connection type is required'),
  yearOfInstallation: Yup.string().required('Year of installation is required'),
  plantStatus: Yup.string().required('Plant status is required'),
  solarTechnologyUsed: Yup.string().required('Solar technology used is required'),
  projectOwnershipType: Yup.string().required('Project ownership type is required'),

  totalInvestment: Yup.string().required('Total investment is required'),
  expectedSellingPrice: Yup.string().required('Expected selling price is required'),
  currentOwner: Yup.string().required('Current owner is required'),
  percentageOwnershipToBeSold: Yup.string().required('Percentage of ownership to be sold is required').min(0, 'Must be at least 0%').max(100, 'Cannot exceed 100%'),
  operationalSinceYears: Yup.string().required('Operational since years is required').min(0, 'Must be at least 0 years'),
  annualEnergyProduction: Yup.string().required('Annual energy production is required'),
  outstandingLiabilities: Yup.string().required('Outstanding liabilities are required'),
  powerPurchaseAgreement: Yup.string().required('Power purchase agreement is required'),
  subsidiesReceived: Yup.string().optional(),
  typeOfSolarModulesUsed: Yup.string().required('Type of solar modules used is required'),
  inverterModel: Yup.string().required('Inverter model is required'),
  inverterBrand: Yup.string().required('Inverter brand is required'),
  inverterCapacity: Yup.string().required('Inverter capacity is required'),
  batteryModel: Yup.string().required('Battery model is required'),
  batteryBrand: Yup.string().required('Battery brand is required'),
  batteryCapacity: Yup.string().required('Battery capacity is required'),
  availabilityMonitoringSystem: Yup.string().required('Availability monitoring system is required'),
  expectedTimeframeForSale: Yup.string().required('Expected timeframe for sale is required'),
  imgUrl: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required(),
  powerPurchaseDoc: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Power Purchase doc required"),
  ownershipDoc: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Ownership doc required"),
  legalClearanceDoc: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Legal Clearance doc required"),
  anyOtherRelevantDoc: Yup.array().of(PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA).notRequired(),
});

export const NEW_CONSTRUCTED_PROJECTS_FORM_FIELDS: FormField[] = [
  {
    label: 'Full Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your first name',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your last name',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Email Address',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Enter your email address',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Phone Number',
    dataKey: 'phoneNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your phone number',
      maxLength: 10,
      regex: /^[0-9]*$/,
      required: true,
    },
  },
  {
    label: 'Alternate Phone Number',
    dataKey: 'alternatePhone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter alternate phone number',
      maxLength: 10,
      required: false,
    },
  },
  {
    label: 'Address',
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your address',
      maxLength: 250,
      required: true,
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your city',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your state',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Enter your pin code',
      maxLength: 6,
      required: true,
    },
  },
  {
    label: 'Project Location',
    dataKey: 'projectLocation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project location',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project City',
    dataKey: 'projectCity',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project city',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project State',
    dataKey: 'projectState',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project state',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project Country',
    dataKey: 'projectCountry',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project country',
      maxLength: 150,
      required: false,
    },
  },
  {
    label: 'Total Installed Capacity (MW)',
    dataKey: 'totalInstalledCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      placeholder: 'Enter total installed capacity',
      required: true,
    },
  },
  {
    label: 'Type of Solar Plant',
    dataKey: 'typeOfSolarPlant',
    componentType: 'options',
    componentProps: {
      options: TYPE_OF_SOLAR_PLANT,
      required: true,
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
      options: CONNECTION_TYPE,
      required: true,
    },
  },
  {
    label: 'Year of Installation',
    dataKey: 'yearOfInstallation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter year of installation',
      required: true,
    },
  },
  {
    label: 'Plant Status',
    dataKey: 'plantStatus',
    componentType: 'options',
    componentProps: {
      options: PLANT_STATUS,
      required: true,
    },
  },
  {
    label: 'Solar Technology Used',
    dataKey: 'solarTechnologyUsed',
    componentType: 'options',
    componentProps: {
      options: SOLAR_TECHNOLOGY_USED,
      required: true,
    },
  },
  {
    label: 'Project Ownership Type',
    dataKey: 'projectOwnershipType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_OWNERSHIP_TYPE,
      required: true,
    },
  },
  {
    label: 'Total Investment in Project',
    dataKey: 'totalInvestment',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      inputPrefixIcon:<CustomTextIcon  value='₹' />,
      placeholder: 'Enter total investment amount',
      required: true,
    },
  },
  {
    label: 'Expected Selling Price for Project Rights',
    dataKey: 'expectedSellingPrice',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      inputPrefixIcon:<CustomTextIcon  value='₹' />,
      placeholder: 'Enter expected selling price',
      required: true,
    },
  },
  {
    label: 'Current Owner of the Project',
    dataKey: 'currentOwner',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter current owner name',
      required: true,
    },
  },
  {
    label: 'Percentage of Ownership to be Sold',
    dataKey: 'percentageOwnershipToBeSold',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      inputSuffixIcon:<CustomTextIcon  value='%' />,
      placeholder: 'Enter percentage of ownership',
      required: true,
    },
  },
  {
    label: 'Operational Since (Years)',
    dataKey: 'operationalSinceYears',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter operational years',
      required: true,
    },
  },
  {
    label: 'Annual Energy Production',
    dataKey: 'annualEnergyProduction',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      inputSuffixIcon:<CustomTextIcon  value='mW' />,
      placeholder: 'Enter annual energy production',
      required: true,
    },
  },
  {
    label: 'Outstanding Liabilities',
    dataKey: 'outstandingLiabilities',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      placeholder: 'Amt',
      required: true,
    },
  },
  {
    label: 'Power Purchase Agreement',
    dataKey: 'powerPurchaseAgreement',
    componentType: 'options',
    componentProps: {
      options: YES_NO_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Subsidies Received',
    dataKey: 'subsidiesReceived',
    componentType: 'options',
    componentProps: {
      options: YES_NO_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Subsidies Amount',
    dataKey: 'subsidyAmount',
    componentType: 'input',
    componentProps: {
      type: 'number',
      numberFormatted: true,
      required: true,
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "subsidiesReceived"
      }
    },
  },
  {
    label: 'Type of Solar Modules Used',
    dataKey: 'typeOfSolarModulesUsed',
    componentType: 'options',
    componentProps: {
      options: TYPE_OF_SOLAR_MODULES_USED,
      required: true,
    },
  },
  {
    label: 'Inverter Model',
    dataKey: 'inverterModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter inverter model',
      required: true,
    },
  },
  {
    label: 'Inverter Brand',
    dataKey: 'inverterBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter inverter brand',
      required: true,
    },
  },
  {
    label: 'Inverter Capacity (kW)',
    dataKey: 'inverterCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted:true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Battery Model',
    dataKey: 'batteryModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter battery model',
      required: true,
    },
  },
  {
    label: 'Battery Brand',
    dataKey: 'batteryBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter battery brand',
      required: true,
    },
  },
  {
    label: 'Battery Capacity (kW)',
    dataKey: 'batteryCapacity',
    componentType: 'input',
    componentProps: {
      type:'number',
      numberFormatted:true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Availability Monitoring System',
    dataKey: 'availabilityMonitoringSystem',
    componentType: 'options',
    componentProps: {
      options: DC_COMMISIONING_TYPE_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Expected Timeframe for Sale',
    dataKey: 'expectedTimeframeForSale',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter expected timeframe for sale',
      required: true,
    },
  },
  {
    label: 'Additional Documents',
    dataKey: 'documentsUpload',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      required: true,
    },
  },
  {
    label: 'Power Purchase Document',
    dataKey: 'powerPurchaseDoc',
    componentType: 'file_picker',
    componentProps: {
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "powerPurchaseAgreement"
      },
      required: true
    },
  },
  {
    label: 'OwnerShip Document',
    dataKey: 'ownershipDoc',
    componentType: 'file_picker',
    componentProps: {
      required: true
    },
  },
  {
    label: 'Legal Clearance Document',
    dataKey: 'legalClearanceDoc',
    componentType: 'file_picker',
    componentProps: {
      required: true
    },
  },
  {
    label: 'Any Other Relevant Document',
    dataKey: 'anyOtherRelevantDoc',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      required: true
    },
  },
];