import { IndianRupee, Locate } from "lucide-react";
import { EMAIL_REGEX, FormField, LAT_LONG_REGEX, NAME_REGEX, NUMBER_REGEX } from "src/helpers";

export interface IQuotationRequest {
    name: string;
    monthlyBillAmount: string;
    email: string;
    phoneNumber: string;
    city: string;
    state: string;
    pincode: string;
    latlong: string;
    isInterested: "yes" | "no";
}


export const QuotationFormFields: FormField[] = [
    {
      label: 'Customer Name',
      dataKey: 'name',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Name',
        maxLength: 150,
        required: true,
        regex: NAME_REGEX
      },
    },
    {
      label: 'Monthly Bill Amount',
      dataKey: 'monthlyBillAmount',
      componentType: 'input',
      componentProps: {
        type: "text",
        regex: /[^0-9]+/g,
        numberFormatted: true,
        placeholder: 'Amount',
        inputPrefixIcon: <IndianRupee size={16} />,
        required: true
      },
    },
    {
      label: 'Email',
      dataKey: 'email',
      componentType: 'input',
      componentProps: {
        type: "email",
        placeholder: 'Email',
        maxLength: 150,
        required: true,
        regex: EMAIL_REGEX
      },
    },
    {
      label: 'Phone Number',
      dataKey: 'phoneNumber',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Number',
        regex: NUMBER_REGEX,
        maxLength: 10,
        required: true
      },
    },
    {
      label: 'City',
      dataKey: 'city',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'City',
        maxLength: 150,
        required: true,
        regex: NAME_REGEX
      },
    },
    {
      label: 'State',
      dataKey: 'state',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'State',
        maxLength: 150,
        required: true,
        regex: NAME_REGEX
      },
    },
    {
      label: 'Pin Code',
      dataKey: 'pincode',
      componentType: 'input',
      componentProps: {
        type: "text",
        regex: NUMBER_REGEX,
        placeholder: 'Pin code',
        maxLength: 6,
        required: true
      },
    },
    {
      label: 'Lat Long',
      dataKey: 'latlong',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'lat,long',
        inputSuffixIcon: <Locate size={16} />,
        maxLength: 150,
        required: true,
        regex: LAT_LONG_REGEX
      },
    },
    {
      label: 'Is Interested',
      dataKey: 'isInterested',
      componentType: 'options',
      componentProps: {
        options: [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' }
        ],
        required: true
      },
    },
  ];

 export const initQuotationRequest: IQuotationRequest = {
    name: '',
    monthlyBillAmount: '',
    email: '',
    phoneNumber: '',
    city: '',
    state: '',
    pincode: '',
    latlong: '',
    isInterested: 'yes',
  };