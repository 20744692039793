
import { ADDRESS_REGEX, AVAILABLE_SPACE_UNITS_OPTIONS, EMAIL_REGEX, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, NAME_REGEX, NUMBER_REGEX, OWNERSHIP_STATUS_OPTIONS, PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA, PLANT_STATUS_OPTIONS_2, PROJECT_CONNECTION_TYPE_OPTIONS, PROJECT_OWNERSHIP_TYPE_OPTIONS, PROJECT_PLANT_STATUS_OPTIONS, PROJECT_SOLAR_MODULES_TYPE_OPTIONS, TYPE_OF_SOLAR_PLANT_OPTIONS, YEARS_RANGE_OPTIONS, YES_NO_OPTIONS } from 'src/helpers';
import CustomTextIcon from 'src/shadcn/components/custom/CustomeSymbols/CustomTextIcon';
import * as Yup from 'yup';

// Personal Details Interface
export interface PersonalDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alternatePhone?: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
}

// Plant Information Interface
export interface PlantInformation {
  plantLocation: string;
  plantType: string;
  installedCapacity: string;
  connectionType: string;
  commissioningDate: "";
  plantStatus: string;
  annualEnergyProduction: string;
  powerPurchaseAgreement: string;
  solarModuleType: string;
  invertorModel: string;
  invertorBrand: string;
  invertorCapacity: string;
  batteryModel?: string;
  batteryBrand?: string;
  batteryCapacity?: string;
  availabilityMonitoringSystem: string;
  warrantyRemainingForModules: string;
  warrantyRemainingForInverters: string;
  initialProjectInvestmentCost: string;
  currentAskingPrice: string;
  annualRevenueFromThePlant: string;
  anyExistingDebtOrLoanOnProject: string;
  debtOrLoanAmount: string
  ownershipStatus: string;
  landOrRoofArea: string;
  imgUrl: string | File
}

// Documents to Upload Interface
export interface DocumentsToUpload {
  landOwnershipLeaseDocuments: string | File;
  powerPurchaseDoc: string | File;
  governmentApprovalsAndCertifications: string | File;
  electricityBoardApproval: string | File;
  performanceDataReports: string | File;
  anyOtherRelevantDocuments?: string[] | File[];
}

export type ISellExistingProject = PersonalDetails & PlantInformation & DocumentsToUpload

export const initialPersonalDetails: PersonalDetails = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alternatePhone: '',
  address: '',
  city: '',
  state: '',
  pinCode: ''
};

export const initialPlantInformation: PlantInformation = {
  plantLocation: '',
  plantType: '',
  installedCapacity: "",
  connectionType: '',
  commissioningDate: "",
  plantStatus: '',
  annualEnergyProduction: "",
  powerPurchaseAgreement: '',
  solarModuleType: '',
  invertorModel: '',
  invertorBrand: '',
  invertorCapacity: "",
  batteryModel: '',
  batteryBrand: '',
  batteryCapacity: "",
  availabilityMonitoringSystem: "",
  warrantyRemainingForModules: "",
  warrantyRemainingForInverters: "",
  initialProjectInvestmentCost: "",
  currentAskingPrice: "",
  annualRevenueFromThePlant: "",
  anyExistingDebtOrLoanOnProject: "",
  debtOrLoanAmount: "",
  ownershipStatus: '',
  landOrRoofArea: "",
  imgUrl: ""
};

export const initialDocumentsToUpload: DocumentsToUpload = {
  landOwnershipLeaseDocuments: '',

  powerPurchaseDoc: "",
  governmentApprovalsAndCertifications: '',
  electricityBoardApproval: '',
  performanceDataReports: '',
  anyOtherRelevantDocuments: [""]
};


export const INIT_SELL_EXISTING_PROJECT = {
  ...initialPersonalDetails,
  ...initialPlantInformation,
  ...initialDocumentsToUpload
}

export const FORM_STRUCTURE = {
  "Personal Details": initialPersonalDetails,
  "Plant Information": initialPlantInformation,
  "Documents": initialDocumentsToUpload
}

// Personal Details Validation Schema
export const SellExistingProjectValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().trim().email('Invalid email format').required('Email is required'),
  phone: Yup.string().trim().required('Phone number is required'),
  alternatePhone: Yup.string().trim().optional(),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pinCode: Yup.string().trim().required('Pin code is required'),
  plantLocation: Yup.string().required('Plant location is required'),
  plantType: Yup.string().required('Plant type is required'),
  installedCapacity: Yup.string().required('Installed capacity is required'),
  connectionType: Yup.string().required('Connection type is required'),
  commissioningDate: Yup.date().required('Commissioning date is required'),
  plantStatus: Yup.string().required('Plant status is required'),
  annualEnergyProduction: Yup.string().required('Annual energy production is required'),
  solarModuleType: Yup.string().required('Solar module type is required'),
  invertorModel: Yup.string().required('Invertor model is required'),
  invertorBrand: Yup.string().required('Invertor brand is required'),
  invertorCapacity: Yup.string().required('Invertor capacity is required'),
  batteryModel: Yup.string().optional(),
  batteryBrand: Yup.string().optional(),
  batteryCapacity: Yup.string().optional(),
  availabilityMonitoringSystem: Yup.string().required('Availability monitoring system is required'),
  warrantyRemainingForModules: Yup.string().required('Warranty remaining for modules is required'),
  warrantyRemainingForInverters: Yup.string().required('Warranty remaining for inverters is required'),
  initialProjectInvestmentCost: Yup.string().required('Initial project investment cost is required'),
  currentAskingPrice: Yup.string().required('Current asking price is required'),
  annualRevenueFromThePlant: Yup.string().required('Annual revenue from the plant is required'),
  anyExistingDebtOrLoanOnProject: Yup.string().required('Any existing debt or loan on project is required'),
  ownershipStatus: Yup.string().required('Ownership status is required'),
  landOrRoofArea: Yup.string().required('Land or roof area is required'),
  landOwnershipLeaseDocuments: Yup.string().required('Land ownership/lease documents are required'),
  powerPurchaseAgreement: Yup.string().required('Power purchase agreement is required'),
  governmentApprovalsAndCertifications: Yup.string().required('Government approvals and certifications are required'),
  electricityBoardApproval: Yup.string().required('Electricity board approval is required'),
  performanceDataReports: Yup.string().required('Performance data reports are required'),
  anyOtherRelevantDocuments: Yup.array().of(PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA).notRequired(),
  imgUrl: IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Plant Image Required")
});

export const EXISTING_PROJECTS_FORM_FIELDS: FormField[] = [
  {
    label: 'First Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'First Name',
      maxLength: 50,
      regex: NAME_REGEX,
      required: true,
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Last Name',
      maxLength: 50,
      regex: NAME_REGEX,
      required: true,
    },
  },
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      regex: EMAIL_REGEX,
      placeholder: 'Email',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Phone',
    dataKey: 'phone',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Phone Number',
      maxLength: 10,
      required: true,
    },
  },
  {
    label: 'Alternate Phone',
    dataKey: 'alternatePhone',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Alternate Phone Number',
      maxLength: 10,
      required: false,
    },
  },
  {
    label: 'Address',
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: ADDRESS_REGEX,
      placeholder: 'Address',
      maxLength: 250,
      required: true,
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: NAME_REGEX,
      placeholder: 'City',
      maxLength: 100,
      required: true,
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'State',
      regex: NAME_REGEX,
      maxLength: 100,
      required: true,
    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Pin Code',
      maxLength: 6,
      required: true,
    },
  },
  {
    label: 'Plant Location',
    dataKey: 'plantLocation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Plant Location',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Plant Type',
    dataKey: 'plantType',
    componentType: 'options',
    componentProps: {
      options: TYPE_OF_SOLAR_PLANT_OPTIONS, // Example options
      required: true,
    },
  },
  {
    label: 'Installed Capacity',
    dataKey: 'installedCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Installed Capacity',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_CONNECTION_TYPE_OPTIONS, // Example options
      required: true,
    },
  },
  {
    label: 'Commissioning Date',
    dataKey: 'commissioningDate',
    componentType: 'date_picker',
    componentProps: {
      placeholder: 'Select Date',
      required: true,
    },
  },
  {
    label: 'Plant Status',
    dataKey: 'plantStatus',
    componentType: 'options',
    componentProps: {
      options: PLANT_STATUS_OPTIONS_2, // Example options
      required: true,
    },
  },
  {
    label: 'Annual Energy Production',
    dataKey: 'annualEnergyProduction',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Annual Energy Production',
      inputSuffixIcon: <CustomTextIcon value='mW' />,
      required: true,
    },
  },
  {
    label: 'Solar Module Type',
    dataKey: 'solarModuleType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_SOLAR_MODULES_TYPE_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Inverter Model',
    dataKey: 'invertorModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Inverter Model',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Inverter Brand',
    dataKey: 'invertorBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Inverter Brand',
      maxLength: 50,
      required: true,
    },
  },
  {
    label: 'Inverter Capacity',
    dataKey: 'invertorCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Inverter Capacity ',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Battery Model',
    dataKey: 'batteryModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Battery Model',
      maxLength: 50,
      required: false,
    },
  },
  {
    label: 'Battery Brand',
    dataKey: 'batteryBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Battery Brand',
      maxLength: 50,
      required: false,
    },
  },
  {
    label: 'Battery Capacity',
    dataKey: 'batteryCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Battery Capacity',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: false,
    },
  },
  {
    label: 'Availability Monitoring System',
    dataKey: 'availabilityMonitoringSystem',
    componentType: 'options',
    componentProps: {
      options: YES_NO_OPTIONS, // Example options
      required: true,
    },
  },
  {
    label: 'Warranty Remaining for Modules',
    dataKey: 'warrantyRemainingForModules',
    componentType: 'options',
    componentProps: {
      options: YEARS_RANGE_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Warranty Remaining for Inverters',
    dataKey: 'warrantyRemainingForInverters',
    componentType: 'options',
    componentProps: {
      options: YEARS_RANGE_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Initial Project Investment Cost',
    dataKey: 'initialProjectInvestmentCost',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      required: true,
    },
  },
  {
    label: 'Current Asking Price',
    dataKey: 'currentAskingPrice',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      required: true,
    },
  },
  {
    label: 'Annual Revenue from the Plant',
    dataKey: 'annualRevenueFromThePlant',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      required: true,
    },
  },
  {
    label: 'Any Existing Debt or Loan on Project',
    dataKey: 'anyExistingDebtOrLoanOnProject',
    componentType: 'options',
    componentProps: {
      options: YES_NO_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Debt Or Loan Amount',
    dataKey: 'debtOrLoanAmount',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      required: true,
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "anyExistingDebtOrLoanOnProject"
      }
    },
  },
  {
    label: 'Ownership Status',
    dataKey: 'ownershipStatus',
    componentType: 'options',
    componentProps: {
      options: OWNERSHIP_STATUS_OPTIONS, // Example options
      required: true,
    },
  },
  {
    label: 'Land or Roof Area',
    dataKey: 'landOrRoofArea',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Land or Roof Area',
      options: AVAILABLE_SPACE_UNITS_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Land Ownership/Lease Documents',
    dataKey: 'landOwnershipLeaseDocuments',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select File',
      required: true,
    },
  },
  {
    label: 'Power Purchase Agreement',
    dataKey: 'powerPurchaseAgreement',
    componentType: 'options',
    componentProps: {
      options: YES_NO_OPTIONS,
      required: true,
    },
  },
  {
    label: 'Power Purchase Document',
    dataKey: 'powerPurchaseDoc',
    componentType: 'file_picker',
    componentProps: {
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "powerPurchaseAgreement"
      },
      required: true
    },
  },
  {
    label: 'Government Approvals and Certifications',
    dataKey: 'governmentApprovalsAndCertifications',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select File',
      required: true,
    },
  },
  {
    label: 'Electricity Board Approval',
    dataKey: 'electricityBoardApproval',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select File',
      required: true,
    },
  },
  {
    label: 'Performance Data Reports',
    dataKey: 'performanceDataReports',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select File',
      required: true,
    },
  },
  {
    label: 'Any Other Relevant Documents',
    dataKey: 'anyOtherRelevantDocuments',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: 'multi',
      placeholder: 'Select Files',
      required: false,
    },
  },
];