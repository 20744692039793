
import { Bell, User } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { convertToCamelCase } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { APP_ROUTES } from 'src/navigations/modules'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'src/shadcn/components/ui'

type Props = {}

const ProfileComp = (props: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()

    console.log({currentUser});
    

    return (
        <div className='flex space-x-2 items-center'>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger className='w-full'>
                        {/*  */}
                        <DropdownMenu >
                            <DropdownMenuTrigger asChild>
                                <div className='flex space-x-2 items-center cursor-pointer'>
                                    <Button variant={"outline"} className='rounded-full p-2  '><Bell size={16} /></Button>
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56" align="end" forceMount>
                                <DropdownMenuLabel className="font-normal">
                                    <div className='flex flex-col'>
                                        <div className='font-bold text-lg '>Notifications</div>
                                        <div>you received 5+ events. </div>
                                    </div>
                                </DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuGroup>
                                    <DropdownMenuItem >

                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Notifications</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger className='w-full'>
                        <div className='flex space-x-4 '>
                            {/* <Button variant={"outline"} onClick={() => navigate("/" + APP_ROUTES.profile.fullRoute)} className='rounded-full p-2  '></Button> */
                                <DropdownMenu>
                                    <DropdownMenuTrigger>
                                        <div className=' flex space-x-2 items-center'>
                                            <div className='p-2 border border-primary bg-orange-50 rounded-full text-primary '>  <User size={16} /></div>
                                          
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuLabel>{convertToCamelCase(currentUser?.profileDetails?.contractorName) }</DropdownMenuLabel>
                                        <DropdownMenuLabel className='text-xs text-gray-500'>{convertToCamelCase(currentUser?.role) }</DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem onClick={()=>navigate("/" + APP_ROUTES.profile.fullRoute)} >Profile</DropdownMenuItem>
                                        {/* <DropdownMenuItem onClick={()=>navigate("/" + APP_ROUTES.changePassword.fullRoute)}  >Change Password</DropdownMenuItem> */}
                                    </DropdownMenuContent>
                                </DropdownMenu>}

                        </div>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Profile</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    )
}

export default ProfileComp