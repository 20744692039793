import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { initSalesExecutive } from 'src/modules/SalesExecutive/SalesExecutives/modules'
import SalesExecutiveForm from 'src/modules/SalesExecutive/SalesExecutives/SalesExecutiveForm'
import { APP_ROUTES } from 'src/navigations/modules'
import { ApiResponse } from 'src/services/consts/api_const'
import { getSalesExecutiveDetailsByIDApiRequest, updateSalesExecutiveApiRequest } from 'src/services/requests/SalesExecutve'

type Props = {}

const EditSalesExecutive = (props: Props) => {

  const [formData, setformData] = useState(initSalesExecutive)
  const [isLoading, setisLoading] = useState(false)
  const { id } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) navigate(-1)
    else handleFetchSalesExecutiveById()
    return () => { }
  }, [id])


  const handleUpdateSalesExecutive = catchAsync(async (frmData: any) => {
    setisLoading(true)
    return await formData && currentUser && updateSalesExecutiveApiRequest({ ...frmData, _id: formData._id })
  }, (result: ApiResponse) => {
    console.log({ result })
    if (IS_SUCCESS_RESPONSE(result)) {
      navigate("/" + APP_ROUTES.sales_executive.subRoutes.users.subRoutes.all.fullRoute)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  const handleFetchSalesExecutiveById = catchAsync(async () => {
    if (id) {
      setisLoading(true)
      return await getSalesExecutiveDetailsByIDApiRequest(id)
    }
  }, (result: ApiResponse) => {
    console.log({ result })
    if (result.code == 200) {
      setformData(result.data)
    }
    setisLoading(false)
  }, () => {
    setisLoading(false)
  })

  return (
    <div>
      <SalesExecutiveForm initial_values={formData} submitBtnTitle='Submit' isLoadingForm={isLoading} onSubmit={(e: any) => handleUpdateSalesExecutive(e)} />
    </div>
  )
}

export default EditSalesExecutive