import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { catchAsync } from 'src/helpers'
import { columns } from 'src/modules/SalesExecutive/SalesExecutiveReport/Table/components/columns'
import { DataTable } from 'src/modules/SalesExecutive/SalesExecutiveReport/Table/components/data-table'
import { getSalesExecutiveProjectDetailsByIDApiRequest } from 'src/services/requests/SalesExecutve'

type Props = {}


const Main = () => {
    const [tableData, settableData] = useState([])

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return getSalesExecutiveProjectDetailsByIDApiRequest()
        },
        (result: AxiosResponse) => {
            settableData(result.data?.map((d: any, indx: number) => ({ ...d, srNo: indx + 1 })))
        }
    );


    return <DataTable data={tableData || []} columns={columns} />
}

const SalesExecutiveReportMain = (props: Props) => {
    return (
        <div>
            <Main />
        </div>
    )
}

export default SalesExecutiveReportMain