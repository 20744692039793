import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/NewConstructedProjects";


export function createNewConstructedProjectsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`${API.NEW_CONSTRUCTED_PROJECTS_BASE_URL}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function allNewConstructedProjectsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_NEW_CONSTRUCTED_PROJECTS_REQUESTS}`).then((d: AxiosResponse) => d.data)
}


export function updateNewConstructedProjectsApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.UPDATE_NEW_CONSTRUCTED_PROJECTS_REQUEST}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function getNewConstructedProjectsDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.NEW_CONSTRUCTED_PROJECTS_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


