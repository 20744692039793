import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { PROFILE_FORM_FIELDS, profileFormValidationSchema, ProfileInit } from './modules'
import { catchAsync, FormField, IS_SUCCESS_RESPONSE, successTheme } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { useAuth } from '../Auth/Core'
import { AxiosResponse } from 'axios'
import { getUserProfileRequestApi, updateUserProfileRequestApi } from 'src/services/requests/Profile'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { toast } from 'sonner'
import { RocketIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

type Props = {}

const ProfileComponent = (props: Props) => {


    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        handleFetchProfileDetails()
        return () => { }
    }, [])

    const formik = useFormik({
        initialValues: ProfileInit,
        validationSchema: profileFormValidationSchema,
        onSubmit(values, formikHelpers) {
            handleUpdateProfileDetails()
        },
    })

    const handleFetchProfileDetails = catchAsync(async () => {
        return await getUserProfileRequestApi()
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            formik.setFieldValue("email", result.data.email)
            formik.setFieldValue("role", result.data.role)
        }
    }, () => { })

    const handleUpdateProfileDetails = catchAsync(async () => {
        return await updateUserProfileRequestApi(formik.values)
    }, (result: AxiosResponse) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            toast.success("Prfile updated successfully", { style: successTheme })
        }
    }, () => { })

    // useEffect(() => {
    //     if (currentUser) {
    //         formik.setFieldValue("email", currentUser.email)
    //         formik.setFieldValue("role", currentUser.role)
    //     }
    //     return () => { }
    // }, [currentUser])


    console.log({ currentUser });


    return (
        <div className='space-y-4 py-4'>
            {/* {
                Object.entries(formik.values).some(([key, value]) => value !== null) ?
                    <div className='border border-yellow-500 bg-yellow-50 text-yellow-600 p-4 rounded-lg'>
                        <div className='flex space-x-2 items-center '>
                            <span><RocketIcon className="h-4 w-4" /></span>
                            <span> Please complete your profile</span>
                        </div>
                    </div> : null
            } */}
            <div className='flex justify-between items-center'>
                <div className='flex items-start space-x-2'>
                    <div className='w-[60px] h-[60px]  '>
                        <img src='https://static.vecteezy.com/system/resources/previews/001/840/618/original/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg' className='rounded-full' style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className='flex flex-col'>
                        <div className='text-xl font-bold'>{currentUser ? currentUser.email?.split("@")[0] : ""}</div>
                    </div>
                </div>
            </div>
            <div>
                <form onSubmit={formik.handleSubmit} className='space-y-4'>
                    <div className='grid grid-cols-2 gap-4'>
                        {PROFILE_FORM_FIELDS.map((field: FormField) => {
                            return <RenderFormComponents {...field} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })}
                    </div>
                    <div className='flex justify-center'><RoundedButton variant={'secondary'} onClick={() => navigate(-1)}>Back</RoundedButton></div>
                </form>
            </div>
        </div>
    )
}

export default ProfileComponent