import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { columns } from 'src/modules/Manufacturer/AllManufacturer/Table/components/columns'
import { DataTable } from 'src/modules/Manufacturer/AllManufacturer/Table/components/data-table'
import { epcAllContractorsApiRequest } from 'src/services/requests/EPC/profile'
import { epcAllProjectsApiRequest } from 'src/services/requests/EPC/projects'
import { allManufacturersRequest } from 'src/services/requests/Manufacturers'
import { allSalesExecutiveProjectsApiRequest } from 'src/services/requests/SalesExecutve'


type Props = {}


const Main = () => {


    const [tableData, settableData] = useState<any[]>([])
    const { currentUser } = useAuth()

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return await allManufacturersRequest("")
        },
        (result: AxiosResponse) => {
            if (IS_SUCCESS_RESPONSE(result)) {
                settableData(result?.data?.map((d: any, indx: number) => {
                    return {
                        srNo: indx + 1,
                        ...d,
                    }
                }))
            }
        }
    );

    console.log({epccontractors : tableData});
    


    return <DataTable data={tableData || []} columns={columns} />
}

const AllEpcContractorsTable = (props: Props) => {
    return (
        <Main />
    )
}

export default AllEpcContractorsTable