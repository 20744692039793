import React from 'react'
import { checkDecimal, formatIndianNumber } from 'src/helpers'
import { Card, CardContent, CardHeader, CardTitle } from 'src/shadcn/components/ui'

type Props = {
    title: string,
    value: string,
    icon?: any,
    prefix: any
    description?: string
}

const AnalysisTiles = ({ title, value, icon, description,prefix }: Props) => {
    return (

        <Card className='shadow-none'>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium text-gray-500">
                    {title}
                </CardTitle>
                <div>{icon}</div>
            </CardHeader>
            <CardContent>
                <div className="text-2xl space-x-2 "><span className='text-gray-400'>{prefix}</span> <span className='font-bold'>{value ? value && checkDecimal(value,true)  : 0}</span></div>
                <p className="text-xs text-muted-foreground">
                    {description}
                </p>
            </CardContent>
        </Card>

    )
}

export default AnalysisTiles