import React, { useEffect, useState } from 'react'
import { Label, Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import EPCProfileReview from './EPCProfileReview'
import { INIT_EPC_CONTRACTOR_PROFILE } from './modules'
import AllSalesExecutives from './AllSalesExecutives'
import AllProjects from './AllProjects'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { epcViewProfileByIDApiRequest } from 'src/services/requests/EPC/profile'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { APP_ROUTES } from 'src/navigations/modules'


type Props = {}

const EpcContractorDetail = (props: Props) => {

    const { id } = useParams()
    const navigate = useNavigate()

    const [profileDetail, setprofileDetail] = useState<any>(INIT_EPC_CONTRACTOR_PROFILE)

    useEffect(() => {
        if (id) {
            handleFetchProfileDetails()
        }
        return () => { }
    }, [id])

    const handleFetchProfileDetails = catchAsync(async () => {
        return id && await epcViewProfileByIDApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setprofileDetail(result?.data)
        }
    }, () => { })

    return (
        <div>
            <ScrollArea className='h-[80vh]'>
                <EPCProfileReview data={profileDetail || INIT_EPC_CONTRACTOR_PROFILE} />
                <div className='p-4'>
                    <Tabs defaultValue="sales-executive" className='w-[85vw]' >
                        <TabsList>
                            <TabsTrigger value="sales-executive">Sales Executives</TabsTrigger>
                            <TabsTrigger value="projects">Projects</TabsTrigger>
                        </TabsList>
                        <TabsContent value="sales-executive">
                            <div>
                                <AllSalesExecutives />
                            </div>
                        </TabsContent>
                        <TabsContent value="projects">
                            <div className=''>  <AllProjects /></div>
                        </TabsContent>
                    </Tabs>
                </div>
            </ScrollArea>
            <div className='w-100 flex justify-center my-4'>
                <RoundedButton variant={"secondary"} onClick={() => navigate(-1)} >Back</RoundedButton>
                <RoundedButton onClick={() => navigate("/" + APP_ROUTES.epc_contractors.subRoutes.edit.fullRoute + "/" + id)} >Update</RoundedButton>
            </div>
        </div>

    )
}

export default EpcContractorDetail