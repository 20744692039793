import React, { useEffect, useState } from 'react'
import { DataTable } from '../projects/AllProjects/Table/components/data-table'
import { columns } from '../projects/AllProjects/Table/components/columns'
import { useAuth } from 'src/modules/Auth/Core'
import { catchAsync } from 'src/helpers'
import { allEpcSalesRepresentativeByIDApiRequest } from 'src/services/requests/EPC/sales_representative'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { epcAllProjectsApiRequest } from 'src/services/requests/EPC/projects'

type Props = {}

const AllProjects = (props: Props) => {


    const [tableData, settableData] = useState<any[]>([])
    const { id } = useParams()

    useEffect(() => {
        handle_get_all_projects()
        return () => { }
    }, [])


    const handle_get_all_projects = catchAsync(
        async () => {
            return await epcAllProjectsApiRequest(id)
        },
        (result: AxiosResponse) => {
            console.log({ allProjects : result?.data});
            
            if (result.data && Array.isArray(result.data)) {
                settableData(result.data)
            }
        }

    );

    return (
        <div>
            <DataTable data={tableData || []} columns={columns} />
        </div>
    )
}

export default AllProjects