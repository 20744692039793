import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers';
import { PROJECT_FORM_INITIAL_VALUES } from 'src/modules/Projects/modules';
import ProjectForm from 'src/modules/Projects/ProjectForm';
import { APP_ROUTES } from 'src/navigations/modules';
import { createProjectApiRequest } from 'src/services/requests/Projects';

type Props = {}

const CreateProjectMain = (props: Props) => {

  const navigate = useNavigate()

  const [projectData, setprojectData] = useState(PROJECT_FORM_INITIAL_VALUES)


  useEffect(() => {
    getMyLocation()
    return () => { }
  }, [])



  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        // this.setState()

        console.log({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setprojectData({
          ...PROJECT_FORM_INITIAL_VALUES,
          geoGraphicalLocation: `${position.coords.latitude},${position.coords.longitude}`
        })
      }, (error) => {
        // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }

  }


  const handle_create_project = catchAsync(
    async (formData: any) => {
      // console.log({ formData });
      return formData && createProjectApiRequest(formData)
    },
    (result: AxiosResponse) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setprojectData(PROJECT_FORM_INITIAL_VALUES)
        navigate("/" + APP_ROUTES.projects.subRoutes.all.fullRoute)
      }
    }
  );


  return (
    <div>
      <div className='space-y-4'>
        <ProjectForm initial_values={projectData} isLoadingForm={false} onSubmit={(formData) => handle_create_project(formData)} submitBtnTitle={"Create Project"} />
      </div>
    </div>
  )
}

export default CreateProjectMain