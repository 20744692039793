import { ChartArea } from "lucide-react";
import { AiFillProduct } from "react-icons/ai";

export type IRoutes = {
    "path": string,
    "icon"?: any,
    "roles": string,
    "componentPath": string,
    "title": string,
    "fullPath": string,
    subRoutes?: IRoutes[],
    "indexRoute"?: string,
    routePath?: string
}

export const ROUTES = {
    dashboard: {
        "path": "dashboard",
        "routePath":"dashboard",
        "icon": <ChartArea />,
        "roles": ["admin", "user"],
        "componentPath": "./pages/DashboardMain",
        "title": "Dashboard",
        "fullPath": "dashboard"
    },
    projects: {
        "path": "projects",
        "icon": <AiFillProduct />,
        "roles": ["admin"],
        "componentPath": "./pages/Users",
        "title": "Projects",
        "fullPath": "projects",
        "indexRoute": "/",
        "routePath": "projects/*",
        "subRoutes": [
            {
                "path": "/",
                "roles": ["admin"],
                "title": "Create Project",
                "componentPath": "./components/UserList",
                "fullPath": "projects/",
            },
            {
                "path": "/create",
                "roles": ["admin"],
                "title": "Create Project",
                "componentPath": "./components/UserList",
                "fullPath": "projects/create",
            },
            {
                "path": "/edit",
                "roles": ["admin"],
                "title": "Edit Project",
                "componentPath": "./components/UserList",
                "fullPath": "projects/edit",
            },
            {
                "path": "/view/:id",
                "roles": ["admin"],
                "title": "View Project",
                "componentPath": "./components/UserList",
                "fullPath": "projects/view",
            }
        ]
    }

}

