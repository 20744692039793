import { AtSign } from 'lucide-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, FormField, IS_SUCCESS_RESPONSE, SESSIONS } from 'src/helpers'
import { MAIN_ROUTES } from 'src/navigations/modules'
import InputWithIcon from 'src/shadcn/components/custom/InputWithIcon'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Label } from 'src/shadcn/components/ui'
import { FORGOT_PASSWORD_FORM_FILED, forgotPasswordFormValidation } from './modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { useFormik } from 'formik'
import { forgotPasswordRequestApi } from 'src/services/requests/Auth'
import { AxiosResponse } from 'axios'
import { setEmailForValidatOtp, setSession } from 'src/redux/reducers/tempDataSlice'
import { useDispatch } from 'react-redux'

type Props = {}

const MANUFACTURER = {}

const ForgotPassword = (props: Props) => {

    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: forgotPasswordFormValidation,
        onSubmit(values, formikHelpers) {
            handleForgotPasswordRequest()
        },
    })

    const handleForgotPasswordRequest = catchAsync(async () => {
        setisLoading(true)
        return await forgotPasswordRequestApi(formik.values)

    }, (result: AxiosResponse) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            dispatch(setSession(SESSIONS.FORGOT_PASSWORD))
            dispatch(setEmailForValidatOtp(formik.values.email))
            navigate( MAIN_ROUTES.auth_verify_otp)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='mx-16 space-y-4'>
            <div className='space-y-2'>
                <div className='uppercase text-xl font-bold text-center text-primary'>Forgot Password</div>
                <div className=' text-center text-sm'>Enter your registered email to get the OTP </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                {
                    FORGOT_PASSWORD_FORM_FILED.map((field: any) => {
                        return <RenderFormComponents key={`login-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                    })
                }
                <RoundedButton className='w-full '  >Send </RoundedButton>
            </form>

        </div>
    )
}

export default ForgotPassword