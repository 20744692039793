import { ADDRESS_REGEX, AVAILABLE_SPACE_UNITS_OPTIONS, BUILDING_TYPES, COMMERCIAL_AND_INDUSTRIAL_OPTIONS, CONNECTION_TYPE_OPTIONS, CUSTOMER_TYPE_OPTIONS, DC_COMMISIONING_TYPE_OPTIONS, DUMMY_OPTIONS, EMAIL_REGEX, FormField, IMAGE_DOCUMENT_VALIDATION_SCHEMA, LAT_LONG_REGEX, MULTI_IMAGE_ATTACHMENT_VALIDATION_SCHEMA, NAME_REGEX, NUMBER_REGEX, optionTypes, PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA, PROJECT_CATEGORY_OPTIONS, PROJECT_OWNERSHIP_TYPE_OPTIONS, PROPERTY_TYPE_OPTIONS, RESIDENTIAL_AND_SOCIETY_OPTIONS, ROOF_MATERIAL_OPTIONS, SYSTEM_TYPE_OPTIONS, TERM_PLAN_OPTIONS, TYPE_OF_SPACE_ENUM, TYPE_OF_SPACE_OPTIONS, VIDEO_DOCUMENT_VALIDATION_SCHEMA } from "src/helpers";
import { IndianRupee, Locate } from "lucide-react";
import KWIcon from "src/shadcn/components/custom/CustomeSymbols/KWIcon";
import SqMIcon from "src/shadcn/components/custom/CustomeSymbols/SqMIcon";
import YearsIcon from "src/shadcn/components/custom/CustomeSymbols/YearsIcon";
import * as Yup from "yup";
import MonthsIcon from "src/shadcn/components/custom/CustomeSymbols/MonthsIcon";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import { log } from "console";

import days from 'dayjs'


export interface ProjectData {
  projectName: string;
  projectType: string;
  projectSize: string;
  projectCategory: string;
  projectDescription: string;
  expectedDateOfInstallation: string,
  dcCommissioning: string;
  systemType: string;
}

export interface SiteData {
  propertyType: string;
  buildingType: string;
  typeOfSpace: string,
  freeAreaAvailableOnRoofTop: string,
  freeAreaAvailableOnGround: string,
  tilt: string,
  shadingAnalysis: string,
  roofMaterial: string,
  roofAgeAndCondition: string
  temperatureRange: string,
  solarIrradiation: string;
  heightOfPremises: string;
}

export interface PowerConsumptionData {
  customerType: string,
  projectCategoryType: string,
  annualConsumption: string,
  unitRequiredPerMonth: string;
  unitConsumptionPerDay: string;
  tariffRate: string;
  monthlyBillAmount: string;
  projectOwnershipType: string;
  pricePerKwh: string;
  recentElectricityBill: string,
  averageLoadPerHour: string
  minimumLoadPerHour: string,
  maximumLoadPerMonth: string,
  connectionType: string,
  connectionLoad: string
}

export interface ContactDetails {
  contactPersonName: string;
  contactPersonNumber: string;
  contactPersonEmail: string,
}

export interface LocationDetails {
  address: string;
  city: string,
  state: string;
  country: string;
  pinCode: string;
  connectionToGrid: string,
  geoGraphicalLocation: string,
}

export interface ProjectFinanceData {
  projectCost: string;
  structureCost: string,
  electicalCost: string,
  otherCost: string;
  interestRate: string;
  interestAmount: string;
  totalProjectCost: string;
  returnPerYearRate: string,
  returnPerYearAmount: string;
  returnPerYearInterest: string;
  payBack: string;
  termPlan: string;
  downpaymentByOfftaker: string;
  requiredInvestment: string;
  offtakerOwnershipPercentage: string;
  tokenValidity: string;
  totalTokenSupply: string
  tokenIssued: string
}

export interface Attachments {
  imagesAttachments: File[] | string[] | undefined,
  videoAttachments: File[] | string[] | undefined,
  headerImage: File | string | undefined,
  agreement: File | string | undefined,
}

export const initialProjectData: ProjectData = {
  projectName: '',
  projectType: '',
  projectSize: '',
  projectCategory: "",
  projectDescription: '',
  expectedDateOfInstallation: '',
  dcCommissioning: '',
  systemType: '',
};

export const initialSiteData: SiteData = {
  propertyType: '',
  buildingType: '',
  typeOfSpace: '',

  heightOfPremises: "",
  freeAreaAvailableOnRoofTop: '',
  freeAreaAvailableOnGround: '',
  tilt: '',
  shadingAnalysis: '',
  roofMaterial: '',
  roofAgeAndCondition: '',
  temperatureRange: '',
  solarIrradiation: '',
};

export const initialPowerConsumptionData: PowerConsumptionData = {
  customerType: "",
  projectCategoryType: "",
  annualConsumption: "",
  unitRequiredPerMonth: '',
  unitConsumptionPerDay: '',
  tariffRate: '',
  monthlyBillAmount: '',

  projectOwnershipType: '',
  pricePerKwh: '',
  recentElectricityBill: '',
  averageLoadPerHour: '',
  minimumLoadPerHour: '',
  maximumLoadPerMonth: '',
  connectionLoad: "",
  connectionType: ""
};

export const initialContactDetails: ContactDetails = {
  contactPersonName: '',
  contactPersonNumber: '',
  contactPersonEmail: '',
};

export const initialLocationDetails: LocationDetails = {
  address: '',
  city: '',
  state: '',
  country: '',
  pinCode: '',
  connectionToGrid: '',
  geoGraphicalLocation: '',
};

export const initialProjectFinanceData: ProjectFinanceData = {
  projectCost: '',
  structureCost: "",
  electicalCost: "",
  otherCost: '',
  interestRate: '',
  interestAmount: '',
  totalProjectCost: '',
  returnPerYearRate: '',
  returnPerYearAmount: '',
  returnPerYearInterest: '',
  payBack: '',
  termPlan: '',
  downpaymentByOfftaker: '',
  requiredInvestment: '',
  offtakerOwnershipPercentage: '',
  totalTokenSupply: '',
  tokenIssued: '',
  tokenValidity: '',
};



export const initialAttachments: Attachments = {
  imagesAttachments: [],
  videoAttachments: [],
  headerImage: '',
  agreement: '',
};

export const PROJECT_FORM_INITIAL_VALUES = {
  ...initialProjectData,
  ...initialSiteData,
  ...initialPowerConsumptionData,
  ...initialContactDetails,
  ...initialLocationDetails,
  ...initialProjectFinanceData,
  ...initialAttachments,
  country: "India"
}


export const ProjectFormValues: any = {
  projectName: 'Sample Project',
  projectType: 'Residential',
  projectSize: '1000',
  projectDescription: 'This is a sample project description',
  expectedDateOfInstallation: '2023-03-15',
  dcCommissioning: 'Yes',
  propertyType: 'Apartment',
  buildingType: 'High-rise',
  typeOfSpace: 'Roof top',
  freeAreaAvailableOnRoofTop: '500',
  freeAreaAvailableOnGround: '1000',
  tilt: '30',
  shadingAnalysis: 'Yes',
  roofMaterial: 'Concrete',
  roofAgeAndCondition: '5 years old, good condition',
  temperatureRange: '20-30',
  solarIrradiation: '500 kWh/m²',
  unitRequiredPerMonth: '100',
  unitConsumptionPerDay: '5',
  tariffRate: '5',
  monthlyBillAmount: '500',
  payBack: '5',
  termPlan: '10',
  projectOwnershipType: 'Individual',
  pricePerKwh: '50000',
  recentElectricityBill: '10000',
  averageLoadPerHour: '5',
  minimumLoadPerHour: '2',
  maximumLoadPerMonth: '10',
  contactPersonName: 'John Doe',
  contactPersonNumber: '9876543210',
  contactPersonEmail: 'johndoe@example.com',
  address: '123, Main Street',
  city: 'New York',
  state: 'NY',
  country: 'USA',
  pinCode: '10001',
  connectionToGrid: 'Yes',
  geoGraphicalLocation: '40.7128° N, 74.0060° W',
  projectCost: '100000',
  otherCost: '50000',
  interestRate: '10',
  interestAmount: '10000',
  totalProjectCost: '150000',
  returnPerYearRate: '15',
  returnPerYearAmount: '22500',
  returnPerYearInterest: '3750',
  downpaymentByOfftaker: '5000',
  requiredInvestment: '100000',
  offtakerOwnershipPercentage: '50',
  tokenValidity: '1',
  totalTokenSupply: '1000',
  tokenIssued: '500',
  imagesAttachments: null,
  videoAttachments: null,
  headerImage: null,
  agreement: null,
  aadharFrontDocument: null,
  aadharBackDocument: null,
  panDocument: null,
  additionalInformation: 'This is additional information',
};

const PROJECT_TYPES = [
  // { label: 'Matchmaking', value: 'Matchmaking' },
  // { label: 'BOOT', value: 'BOOT' },
  { label: 'GROUND MOUNTED', value: 'GROUND MOUNTED' },
  { label: 'ROOF TOP', value: 'ROOF TOP' },
  // { label: 'EaaS', value: 'EaaS' },
  // { label: 'VPP', value: 'VPP' },
];



const ROOF_AGE: optionTypes[] = [
  {
    label: "0-5",
    value: "0-5"
  },
  {
    label: "5-10",
    value: "5-10"
  },
  {
    label: "10-15",
    value: "10-15"
  },
  {
    label: "15-20",
    value: "15-20"
  }
]


const TEMPERATURE: optionTypes[] = [
  {
    label: "Below 20",
    value: "Below 20"
  },
  {
    label: "20-30",
    value: "20-30"
  },
  {
    label: "30-40",
    value: "30-40"
  },
  {
    label: "40-50",
    value: "40-50"
  },
  {
    label: "Above 50",
    value: "Above 50"
  }
]



export const PROJECT_FORM_FIELDS: FormField[] = [
  {
    label: 'Project Name',
    dataKey: 'projectName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Project Name',
      maxLength: 150,
      required: true
    },
  },
  {
    label: 'Project Type',
    dataKey: 'projectType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_TYPES,
      required: true
    },
  },
  {
    label: 'Customer Type',
    dataKey: 'customerType',
    componentType: 'options',
    componentProps: {
      options: CUSTOMER_TYPE_OPTIONS,
      required: true
    },
  },

  {
    label: 'Project Category Type',
    dataKey: 'projectCategoryType',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
      
    },
  },
  {
    label: 'Project Size',
    dataKey: 'projectSize',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value="kWp" />,
      required: true
    },
  },
  {
    label: 'Project Description',
    dataKey: 'projectDescription',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true
    },
  },
  {
    label: 'Expected Date of installation',
    dataKey: 'expectedDateOfInstallation',
    componentType: 'date_picker',
    componentProps: {
      placeholder: 'Description',
      disablePreviousDates: new Date().toString(),
      required: true
    },
  },
  {
    label: 'DC Commissioning',
    dataKey: 'dcCommissioning',
    componentType: 'options',
    componentProps: {
      options: DC_COMMISIONING_TYPE_OPTIONS,
      required: true
    },
  },
  {
    label: 'Property Type',
    dataKey: 'propertyType',
    componentType: 'options',
    componentProps: {
      options: PROPERTY_TYPE_OPTIONS,
      required: true
    },
  },
  {
    label: 'Building Type',
    dataKey: 'buildingType',
    componentType: 'options',
    componentProps: {
      options: BUILDING_TYPES,
      required: true
    },
  },

  {
    label: 'Type of Space',
    dataKey: 'typeOfSpace',
    componentType: 'options',
    componentProps: {
      options: TYPE_OF_SPACE_OPTIONS,
      required: true

    },
  },
  {
    label: 'Free available space on Roof',
    dataKey: 'freeAreaAvailableOnRoofTop',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // inputSuffixIcon: <SqMIcon />,
      options: AVAILABLE_SPACE_UNITS_OPTIONS,
      conditionalHide: {
        expectedValue: TYPE_OF_SPACE_ENUM.Rooftop,
        formKey: "typeOfSpace"
      },
      optionsPlaceholder: "Units",
      required: true
    },
  },
  {
    label: 'Free available Ground',
    dataKey: 'freeAreaAvailableOnGround',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // inputSuffixIcon: <SqMIcon />,
      options: AVAILABLE_SPACE_UNITS_OPTIONS,
      conditionalHide: {
        expectedValue: TYPE_OF_SPACE_ENUM.Land,
        formKey: "typeOfSpace"
      },
      optionsPlaceholder: "Units",
      required: true
    },
  },
  {
    label: 'Tilt (Max-60°)',
    dataKey: 'tilt',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      required: true
    },
  },

  {
    label: 'Shading Analysis',
    dataKey: 'shadingAnalysis',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50,
      required: true
    },
  },

  {
    label: 'Roof Type',
    dataKey: 'roofMaterial',
    componentType: 'options',
    componentProps: {
      options: ROOF_MATERIAL_OPTIONS,
      required: true
    },
  },
  {
    label: 'Roof Age and Condition',
    dataKey: 'roofAgeAndCondition',
    componentType: 'options',
    componentProps: {
      options: ROOF_AGE,
      required: true
    },
  },
  {
    label: 'Temperature Range (°C)',
    dataKey: 'temperatureRange',
    componentType: 'options',
    componentProps: {
      options: TEMPERATURE,
      required: true
    },
  },

  {
    label: 'Solar Irradiation',
    dataKey: 'solarIrradiation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50,
      required: true
    },
  },

  {
    label: 'Monthly Consumption',
    dataKey: 'unitRequiredPerMonth',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '0',
      inputSuffixIcon: <CustomTextIcon value="units" />,
      maxLength: 8,
      required: true,
      readonly: true
    },
  },
  {
    label: 'Daily Consumption',
    dataKey: 'unitConsumptionPerDay',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Units',
      // readonly: true,
      inputSuffixIcon: <CustomTextIcon value="units/day" />,
      maxLength: 8,
      required: true,
      readonly: true
    },
  },

  {
    label: 'Tariff Rate (Per Unit)',
    dataKey: 'tariffRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Per Unit Rate (₹)',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },

  {
    label: 'Monthly Bill Amount',
    dataKey: 'monthlyBillAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Payback Duration',
    dataKey: 'payBack',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Payback Duration',
      numberFormatted: true,
      readonly: true,
      maxLength: 50,
      required: true,
      inputSuffixIcon: <CustomTextIcon value="Months" />,
    },
  },
  {
    label: 'Term Plan',
    dataKey: 'termPlan',
    componentType: 'options',
    componentProps: {
      options: TERM_PLAN_OPTIONS,
      required: true
    },
  },
  {
    label: 'Project Ownership Type',
    dataKey: 'projectOwnershipType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_OWNERSHIP_TYPE_OPTIONS,
      required: true
    },
  },
  {
    label: 'Price Per kW',
    dataKey: 'pricePerKwh',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      // inputSuffixIcon: <CustomTextIcon value="/kW" />,
      required: true
    },
  },

  {
    label: 'Recent Electricity Bill',
    dataKey: 'recentElectricityBill',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },

  {
    label: 'Average Load',
    dataKey: 'averageLoadPerHour',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      numberFormatted: true,
      required: true
    },
  },
  {
    label: 'Minimum Load',
    dataKey: 'minimumLoadPerHour',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      numberFormatted: true,
      required: true
    },
  },

  {
    label: 'Maximum Load',
    dataKey: 'maximumLoadPerMonth',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      numberFormatted: true,
      required: true
    },
  },


  {
    label: 'Contact Person Name',
    dataKey: 'contactPersonName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Name',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },
  {
    label: 'Contact Person Number',
    dataKey: 'contactPersonNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Number',
      regex: NUMBER_REGEX,
      maxLength: 10,
      required: true
    },
  },

  {
    label: 'Contact Person Email',
    dataKey: 'contactPersonEmail',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email',
      maxLength: 150,
      required: true,
      regex: EMAIL_REGEX
    },
  },
  {
    label: 'Country',
    dataKey: 'country',
    componentType: 'input',
    value: "India",
    componentProps: {
      type: "text",
      placeholder: 'country',
      readonly: true,
      maxLength: 150,
      required: true
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'State',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'City',
      maxLength: 150,
      required: true,
      regex: NAME_REGEX
    },
  },
  {
    label: `Address`,
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Address',
      maxLength: 250,
      required: true,
      regex: ADDRESS_REGEX

    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: NUMBER_REGEX,
      placeholder: 'Pin code',
      maxLength: 6,
      required: true
    },
  },

  {
    label: 'Connection To Grid',
    dataKey: 'connectionToGrid',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50,
      required: true
    },
  },

  {
    label: 'GeoGraphical Location (lat/long)',
    dataKey: 'geoGraphicalLocation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'lat,long',
      inputSuffixIcon: <Locate size={16} />,
      maxLength: 150,
      required: true,
      regex: LAT_LONG_REGEX
    },
  },

  {
    label: 'Project Cost (INR)',
    dataKey: 'projectCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: "",
      // readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      // description: "(As per price per kW and plant size)",
      required: true
    },
  },

  {
    label: 'Electical Cost',
    dataKey: 'electicalCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Structure Cost',
    dataKey: 'structureCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },

  {
    label: 'Other Cost ',
    dataKey: 'otherCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      description: "(inc. Stamp Duty as applicable + 2% processing fee)",
      required: true
    },
  },
  {
    label: 'Interest (%)',
    dataKey: 'interestRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Rate (%)',
      // readonly: true,
      // inputSuffixIcon: <CustomTextIcon value="/year" />,
      description: "(per year)",
      required: true
    },
  },
  {
    label: 'Interest Amount ',
    dataKey: 'interestAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      // readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true,
      description: "(per year)",
      // inputSuffixIcon: <CustomTextIcon value="/year" />
    },
  },
  {
    label: 'Total Project Cost',
    dataKey: 'totalProjectCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Returns Per Year (%)',
    dataKey: 'returnPerYearRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // readonly: true,
      required: true
      // inputSuffixIcon: <CustomTextIcon value="/year" />
    },
  },

  // {
  //   label: 'Return Per Year Interest (%)',
  //   dataKey: 'returnPerYearInterest',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     regex: /[^0-9]+/g,
  //     numberFormatted: true,
  //     placeholder: '',
  //     maxLength: 50,
  //     // inputSuffixIcon: <CustomTextIcon value="/year" />
  //   },
  // },
  {
    label: 'Return Amount Per Year',
    dataKey: 'returnPerYearAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      // inputSuffixIcon: <CustomTextIcon value="/year" />,
      required: true
    },
  },

  {
    label: 'Downpayment By Offtaker',
    dataKey: 'downpaymentByOfftaker',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Required Investment',
    dataKey: 'requiredInvestment',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      required: true
    },
  },
  {
    label: 'Offtaker Ownership (%)',
    dataKey: 'offtakerOwnershipPercentage',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      required: true
    },
  },

  {
    label: 'Height Of Premises',
    dataKey: 'heightOfPremises',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // options: AVAILABLE_SPACE_UNITS_OPTIONS.filter((o: optionTypes) => o.value == "ft")
      inputSuffixIcon: <CustomTextIcon value="ft." />,
      required: true
    },
  },
  {
    label: 'Annual Consumption',
    dataKey: 'annualConsumption',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      placeholder: '0',
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value="units" />,
      required: true
    },
  },
  {
    label: 'Total Token Supply',
    dataKey: 'totalTokenSupply',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 150,
      required: true
    },
  },
  {
    label: 'Token Issued',
    dataKey: 'tokenIssued',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 150,
      required: true
    },
  },

  {
    label: 'Token Validity',
    dataKey: 'tokenValidity',
    componentType: 'input',
    componentProps: {
      type: "text",
      inputSuffixIcon: <CustomTextIcon value="Months" />,
      required: true,
      readonly: true
    },
  },

  {
    label: 'Project Description',
    dataKey: 'projectDescription',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true
    },
  },

  {
    label: 'DC Commissioning',
    dataKey: 'dcCommissioning',
    componentType: 'options',
    componentProps: {
      options: DC_COMMISIONING_TYPE_OPTIONS,
      required: true
    },
  },

  // {
  //   label: 'Project Title Image',
  //   dataKey: 'headerImage',
  //   componentType: 'file_picker',
  //   componentProps: {
  //     placeholder: 'Select image',
  //   },
  // },
  {
    label: ' Site Images',
    dataKey: 'imagesAttachments',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: 'multi',
      placeholder: 'Select images',
      required: true
    },
  },
  {
    label: 'Site video',
    dataKey: 'videoAttachments',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },
  // {
  //   label: 'Upload Agreement',
  //   dataKey: 'agreement',
  //   componentType: 'file_picker',
  //   componentProps: {
  //     placeholder: 'Select FILE',
  //   },
  // },
  {
    label: 'Aadhar Front Document',
    dataKey: 'aadharFrontDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },

  {
    label: 'Aadhar Back Document',
    dataKey: 'aadharBackDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },

  {
    label: 'Pan Document',
    dataKey: 'panDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },
  {
    label: 'Bill Front Image',
    dataKey: 'billFrontImage',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },
  {
    label: 'Bill Back Image',
    dataKey: 'billBackImage',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
      required: true
    },
  },
  {
    label: 'Project Category',
    dataKey: 'projectCategory',
    componentType: 'options',
    componentProps: {
      options: PROJECT_CATEGORY_OPTIONS,
      required: true
    },
  },
  {
    label: 'System Type',
    dataKey: 'systemType',
    componentType: 'options',
    componentProps: {
      options: SYSTEM_TYPE_OPTIONS,
      required: true
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
      options: CONNECTION_TYPE_OPTIONS,
      required: true
    },
  },
  {
    label: 'Connection Load',
    dataKey: 'connectionLoad',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      placeholder: '0',
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      required: true
    },
  },
];




export const createNewProjectvalidationSchema = Yup.object({
  // ProjectData schema
  projectName: Yup.string().required('Project name is required'),
  projectType: Yup.string().required('Project type is required'),
  customerType:  Yup.string().required('Customer type is required'),
  projectCategoryType:  Yup.string().required('Project Category type is required'),
  projectSize: Yup.string().required('Project size is required'),
  projectDescription: Yup.string().max(250).required('Project description is required'),
  expectedDateOfInstallation: Yup.string().required('Expected date of installation is required'),
  dcCommissioning: Yup.string().required('DC Commissioning is required'),
  propertyType: Yup.string().required('Property type is required'),
  buildingType: Yup.string().required('Building type is required'),
  typeOfSpace: Yup.string().required('Type of space is required'),
  freeAreaAvailableOnRoofTop: Yup.string().nullable()
    .when('typeOfSpace', (typeOfSpace: any, schema: any) => {
      if (typeOfSpace == TYPE_OF_SPACE_ENUM.Rooftop) {
        return schema.required('Free area available on rooftop is required')
          .test('freeAreaAvailableOnRoofTopunit', 'Select Units (e.g. sq.ft, ft, m)',
            (value: any) => {
              if (value) {
                const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                // console.log({ value, units })
                return units.some((u: any) => value.includes(u))
              }
            })
      } else {
        return schema.optional();
      }
    }),
  freeAreaAvailableOnGround: Yup.string().nullable()
    .when('typeOfSpace', (typeOfSpace: any, schema: any) => {
      if (typeOfSpace == TYPE_OF_SPACE_ENUM.Land) {
        return schema.required('Free area available on ground is required')
          .test('freeAreaAvailableOnGroundunit', 'Select Units (e.g. sq.ft, ft, m)',
            (value: any) => {
              if (value) {
                const units = AVAILABLE_SPACE_UNITS_OPTIONS.map((d: optionTypes) => d.value)
                // console.log({ value, units })
                return units.some((u: any) => value.includes(u))
              }
            })
      } else {
        return schema.optional();
      }
    }),
  tilt: Yup.number().max(60, "Not more than 60 ").required('Tilt is required'),
  shadingAnalysis: Yup.string().required('Shading analysis is required'),
  roofMaterial: Yup.string().required('Roof type is required'),
  roofAgeAndCondition: Yup.string().required('Roof age and condition is required'),
  temperatureRange: Yup.string().required('Temperature range is required'),
  solarIrradiation: Yup.string().required('Solar irradiation is required'),
  unitRequiredPerMonth: Yup.string().required('Unit required per month is required'),
  unitConsumptionPerDay: Yup.string().required('Unit consumption per day is required'),
  tariffRate: Yup.number().max(20).required('Tariff rate is required'),
  monthlyBillAmount: Yup.string().required('Monthly bill amount is required'),
  payBack: Yup.string().required('Payback is required'),
  termPlan: Yup.string().required('Term plan is required'),
  projectOwnershipType: Yup.string().required('Project ownership type is required'),
  pricePerKwh: Yup.string().required('Price per KWP is required'),
  recentElectricityBill: Yup.string().required('Recent electricity bill is required'),
  averageLoadPerHour: Yup.string().required('Average load is required'),
  minimumLoadPerHour: Yup.string().required('Minimum load is required'),
  maximumLoadPerMonth: Yup.string().required('Maximum load is required'),
  contactPersonName: Yup.string().required('Contact person name is required'),
  contactPersonNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Contact person number must be digits')
    .required('Contact person number is required'),
  contactPersonEmail: Yup.string()
    .email('Invalid email address')
    .required('Contact person email is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  pinCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pin code must be exactly 6 digits')
    .required('Pin code is required'),
  connectionToGrid: Yup.string().required('Connection to grid is required'),
  geoGraphicalLocation: Yup.string().required('Geographical location is required'),
  projectCost: Yup.string().required('Project cost is required'),
  otherCost: Yup.string().required('Other cost is required'),
  interestRate: Yup.string().required('Interest rate is required'),
  interestAmount: Yup.string().required('Interest amount is required'),
  totalProjectCost: Yup.string().required('Total project cost is required'),
  returnPerYearRate: Yup.string().required('Return per year rate is required'),
  returnPerYearAmount: Yup.string().required('Return per year amount is required'),
  // returnPerYearInterest: Yup.string().required('Return per year interest is required'),
  downpaymentByOfftaker: Yup.string().required('Downpayment by offtaker is required'),
  requiredInvestment: Yup.string().required('Required investment is required'),
  offtakerOwnershipPercentage: Yup.string().required('Offtaker ownership percentage is required'),
  tokenValidity: Yup.string().required('Token validity is required'),
  totalTokenSupply: Yup.string().required('Total token supply is required'),
  // tokenIssued: Yup.string().required('Token issued is required'),
  imagesAttachments: MULTI_IMAGE_ATTACHMENT_VALIDATION_SCHEMA.required("Upload at least one image"),
  videoAttachments: VIDEO_DOCUMENT_VALIDATION_SCHEMA.required(),
  // headerImage: IMAGE_DOCUMENT_VALIDATION_SCHEMA.nullable().optional(),
  // aadharFrontDocument: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Upload Aaddhar front image"),
  // aadharBackDocument: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Upload Aaddhar back image"),
  // panDocument: PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA.required("Upload Aaddhar back image"),
  connectionType: Yup.string().required('Connection type is required'),
  connectionLoad: Yup.string().required('Connection Load is required'),
  projectCategory: Yup.string().required('Project category is required'),
  systemType: Yup.string().required('System type is required'),
  heightOfPremises: Yup.number().required('Height of premises is required'),
  annualConsumption: Yup.number().required('Annual consumption is required'),
})


