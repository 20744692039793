import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { INITIAL_PRODUCT_VALUES } from 'src/modules/ManufacturerProducts/module'
import ViewProductDetails from 'src/modules/ManufacturerProducts/ViewProductDetails'
import { MANUFACTURER_ROUTES } from 'src/navigations/modules'
import { getProductDetailByIDApiRequest } from 'src/services/requests/Products'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ViewProductDetailsPage = (props: Props) => {


  const [productData, setproductData] = useState(INITIAL_PRODUCT_VALUES)
  const navigate = useNavigate()

  const { id } = useParams()
  const [isLoading, setisLoading] = useState<boolean>(false)

  useEffect(() => {
    if (id) {
      handleFetchPoductDeatilsById()
    }
    return () => { }
  }, [id])


  const handleFetchPoductDeatilsById = catchAsync(async () => {
    setisLoading(true)
    return id && await getProductDetailByIDApiRequest(id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setproductData(result.data)
    }
    setisLoading(false)
  }, () => { setisLoading(false) })


  return (
    <div className='p-4'>
      <ViewProductDetails data={productData} />
      <div className='flex justify-center p-8 space-x-2'>
        <RoundedButton onClick={()=>navigate(-1)} variant={'secondary'}>Back</RoundedButton>
        <RoundedButton onClick={() => navigate("/" + MANUFACTURER_ROUTES.products.subRoutes.edit.fullRoute + "/" + id)} >Update</RoundedButton>
      </div>
      <LoadingDialog isOpen={isLoading} message='Fetching Product details' />
    </div>
  )
}

export default ViewProductDetailsPage